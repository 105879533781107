import PostCard from './PostCard'

const PostsList = ({ posts }) => {
  return (
    <div className='w-full flex flex-col justify-center md:grid md:grid-cols-2 xl:grid-cols-3 pb-16 overflow-scroll gap-4'>
      {posts.results.map((post) => (
        <PostCard key={post.id} id={post.id} post={post} />
      ))}
    </div>
  )
}

export default PostsList
