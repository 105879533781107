import MultipleChoiceList from '../../../Common/Widgets/MultipleChoiceList'
const styles = {
  industries: {
    tag: 'bg-secondaryPink border-pink-400',
    text: 'text-secondaryMagenta',
  },
  states: {
    tag: 'bg-secondaryLilac border-violet-400',
    text: 'text-primaryBlue',
  },
  type: {
    tag: 'bg-secondaryBlue border-sky-300',
    text: 'text-primaryBlue',
  },
  offers: {
    tag: 'bg-primaryBlue border-primaryBlue',
    text: 'text-white',
  },
  audiences: {
    tag: 'bg-primaryBlue border-primaryBlue',
    text: 'text-white',
  },
}

const Filter = ({ filter, setFilter, field, lists }) => {
  //GET LIST OF DATA FORM THE DB

  const updateFilter = (value) => {
    //check if value is there and add, or remove if already there
    let updatedFilter
    if (filter.search.includes(value)) {
      updatedFilter = {
        ...filter,
        search: [...filter.search].filter((term) => term !== value),
      }
      setFilter((prev) => ({
        ...prev,
        [field]: { ...updatedFilter },
      }))
    } else {
      updatedFilter = { ...filter, search: [...filter.search, value] }
      setFilter((prev) => ({
        ...prev,
        [field]: { ...updatedFilter },
      }))
    }
  }

  const resetFilter = (field) => {
    const updatedFilter = { ...filter, search: [] }
    setFilter((prev) => ({
      ...prev,
      [field]: { ...updatedFilter },
    }))
  }
  const emptyList = lists[field].filter((item) => item !== '')

  return emptyList.length ? (
    <>
      <MultipleChoiceList
        location={'resources'}
        field={field}
        options={lists[field]}
        choices={filter.search}
        setChoices={updateFilter}
        resetFilter={resetFilter}
        color={styles[field]}
      />
    </>
  ) : (
    ''
  )
}

export default Filter
