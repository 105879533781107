import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/24/outline'
import { useField, useFormikContext } from 'formik'
import { Fragment } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

const OptionList = ({ label, dataList, name, notes, required }) => {
  const [field, meta] = useField({ label, name })
  const intl = useIntl()

  const {
    setFieldValue,
    touched: { [name]: touched },
    errors: { [name]: error },
  } = useFormikContext()

  const changeValue = (value) => {
    setFieldValue(name, value, true)
  }

  return (
    <>
      <label>
        <FormattedMessage id={'signup.' + name} defaultMessage={label} />{' '}
        {required ? '*' : ''}
      </label>
      {notes && (
        <p className='mt-2 text-xs lg:text-sm'>
          {notes && intl.formatMessage({ id: 'signup.' + name + '.' + notes })}
        </p>
      )}
      <Listbox name={name} onChange={changeValue}>
        <div className={'relative w-full bg-white'}>
          <Listbox.Button
            className={`${
              !touched
                ? 'border border-black text-lightgray'
                : error
                ? 'border border-invalid text-lightgray'
                : meta.value !== '' && meta.value
                ? 'border border-valid'
                : 'border border-black text-lightgray'
            } relative w-full h-12 text-left text-inter text-black border p-4 focus:ring-0 focus:outline-none placeholder:text-lightgray truncate`}
            {...field}
          >
            <span className='truncate'>
              {meta.value
                ? intl.formatMessage({
                    id: 'signup.' + name + '.' + meta.value,
                    defaultMessage: 'Woman of Color',
                  })
                : intl.formatMessage({
                    id: 'signup.' + name + '.placeholder',
                    defaultMessage:
                      name === 'identifyAs' ? 'Solopreneur' : 'Woman of Color',
                  })}
            </span>
            <span className='absolute inset-y-0 right-0 flex items-center justify-end pr-2 pointer-events-none w-full text-2xl appearance-none bg-grey-50 text-grey-500 border p-3'>
              <ChevronDownIcon
                className='w-5 h-5 text-gray-600 '
                aria-hidden='true'
              />
            </span>
          </Listbox.Button>
          <Transition
            as={Fragment}
            leave='transition ease-in duration-100'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <Listbox.Options className='absolute w-full z-40 mt-1 overflow-auto text-base lg:text-xl  text-left bg-white shadow-lg max-h-44 ring-1 ring-black ring-opacity-5 focus:outline-none'>
              {dataList.map((choice, index) => (
                <Listbox.Option
                  key={index + choice.value}
                  className={({ active }) =>
                    `relative ${
                      active ? 'font-bold bg-gray-200' : 'font-normal w-full'
                    }
                    pl-12 pr-8 py-4 select-none`
                  }
                  value={choice.value}
                >
                  {({ selected, active }) => (
                    <>
                      <div
                        className={`${
                          selected ? 'font-bold' : 'font-normal'
                        } flex justify-between truncate overflow-scroll`}
                      >
                        {intl.formatMessage({
                          id: 'signup.' + name + '.' + choice.value,
                          defaultMessage: choice.name,
                        })}
                      </div>
                      {selected ? (
                        <span className='text-black absolute inset-y-0 left-0 flex items-center ml-3'>
                          <CheckIcon className='w-5 h-5' aria-hidden='true' />
                        </span>
                      ) : null}
                    </>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
      {touched && error ? (
        <p className='absolute m-0 -bottom-8 text-sm lg:text-base text-invalid'>
          {meta.error && intl.formatMessage({ id: 'signup.' + meta.error })}
        </p>
      ) : null}
    </>
  )
}

export default OptionList
