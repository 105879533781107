import {
  MapIcon,
  PencilIcon,
  TrashIcon,
  XCircleIcon,
} from '@heroicons/react/24/outline'
import moment from 'moment'
import { useContext, useState } from 'react'
import AdminContext from '../../../contexts/Admin'
import MapDisplay from '../../Common/Widgets/MapDisplay'
import LinkPreview from '../Widgets/LinkPreview'
import ComponentModal from '../Widgets/ComponentModal'
import Loading from '../../Common/Widgets/Loading'
import CancelEvent from './CancelEvent'
const styles = {
  online: 'bg-green-100 text-black border-green-900 border p-1 px-2 text-sm',
  public: 'bg-blue-100 text-black border-blue-900 border p-1 px-2 text-sm',
  private: 'bg-red-100 text-black border-red-900 border p-1 px-2 text-sm',
}

const EventCard = ({ event, setEdit }) => {
  const { user, setShowModal } = useContext(AdminContext)
  const [action, setAction] = useState(null)

  return event ? (
    <>
      <ComponentModal setAction={setAction}>
        <CancelEvent action={action} setAction={setAction} event={event} />
      </ComponentModal>
      <div className='relative self-center flex flex-col w-full xl:w-5/6 2xl:w-4/6 pb-6 border-0'>
        <div className='flex flex-col md:flex-row px-4 pt-2  w-full justify-between'>
          <div className='w-full mb-2 mx-auto'>
            <p className='text-xl xl:text-2xl 2xl:text-3xl text-mono tracking-wider self-center font-bold uppercase'>
              {event.title}
            </p>
          </div>
        </div>
        <div className='w-full px-4 md:px-8 pt-2 flex space-x-2 flex-col md:flex-row justify-between items-center'>
          <div className='w-full md:w-1/2'>
            <div
              className='relative w-full aspect-square bg-top bg-cover'
              style={{
                backgroundImage: `url(${event?.eventCover?.url}`,
              }}
            >
              {event.isCanceled && (
                <div className='absolute w-full h-full'>
                  <div className='w-26 h10 md:w-52 md:h-20 border-2 bg-white opacity-80 -rotate-45 border-invalid rounded-lg absolute top-20 left-10 flex items-center justify-center space-x-2 '>
                    <span className='mx-auto w-full p-4 text-sm md:text-xl text-center font-bold text-invalid whitespace-nowrap'>
                      Cancelled
                    </span>
                  </div>
                </div>
              )}
              <div className='absolute mr-4 justify-end bottom-0 right-0 w-full mb-4 flex'>
                <p className={styles[event.type]}>{event.type}</p>
              </div>
            </div>
          </div>
          <div className='w-full md:w-1/2 flex md:flex-col justify-center items-center'>
            <div className='mb-2'>
              <p className='text-xs text-grotesk'>From</p>
              <div className='w-28 md:w-32 flex-none rounded-t lg:rounded-t-none lg:rounded-l text-center shadow-lg '>
                <div className='block rounded-t overflow-hidden  text-center '>
                  <div className='bg-primaryBlue text-white py-1'>
                    {moment(event.dateStart).format('MMMM')}
                  </div>
                  <div className='pt-1 border-l border-r border-white bg-white'>
                    <span className='text-5xl font-bold leading-tight'>
                      {moment(event.dateStart).format('DD')}
                    </span>
                  </div>
                  <div className='border-l border-r border-b rounded-b-lg text-center border-white bg-white -pt-2 -mb-1'>
                    <span className='text-sm'>
                      {moment(event.dateStart).format('dddd')}
                    </span>
                  </div>
                  <div className='pb-2 border-l border-r border-b rounded-b-lg text-center border-white bg-white mt-1'>
                    <span className='text-xs leading-normal'>
                      {moment(event.dateStart).format('HH:mm')}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className='mb-2'>
              <p className=' text-xs text-grotesk'>To</p>
              <div className='w-28 md:w-32 flex-none rounded-t lg:rounded-t-none lg:rounded-l text-center shadow-lg '>
                <div className='block rounded-t overflow-hidden text-center '>
                  <div className='bg-secondaryMagenta text-white py-1'>
                    {moment(event.dateEnd).format('MMMM')}
                  </div>
                  <div className='pt-1 border-l border-r border-white bg-white'>
                    <span className='text-5xl font-bold leading-tight'>
                      {moment(event.dateEnd).format('DD')}
                    </span>
                  </div>
                  <div className='border-l border-r border-b rounded-b-lg text-center border-white bg-white -pt-2 -mb-1'>
                    <span className='text-sm'>
                      {moment(event.dateEnd).format('dddd')}
                    </span>
                  </div>
                  <div className='pb-2 border-l border-r border-b rounded-b-lg text-center border-white bg-white mt-1'>
                    <span className='text-xs leading-normal'>
                      {moment(event.dateEnd).format('HH:mm')}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='flex justify-center items-center w-full p-6 h-40'>
          {event.type !== 'online' ? (
            <MapDisplay location={event.geoLocation} zoom={event.zoom} />
          ) : (
            <LinkPreview url={event.link} />
          )}
        </div>
        <hr className={`mt-6 border-b-1 border-gray-400`} />
        <div className='w-full flex flex-col px-4 py-2'>
          <h6 className='text-gray-400 text-sm mt-3 mb-4 font-bold uppercase'>
            About
            {event.isCanceled && (
              <p className='w-full text-lg text-red-600'>Event Cancelled</p>
            )}
          </h6>

          <div className='px-3 bg-white text-base md:text-lg text-mono w-full ease-linear transition-all duration-150 text-justify'>
            {event.description}
          </div>

          <h6 className='flex space-x-4 items-center text-gray-400 text-sm mt-3 mb-4 font-bold uppercase'>
            External Link
          </h6>
          <div className='px-3 bg-white text-mono text-base w-full ease-linear transition-all duration-150'>
            {event.extLink ? (
              <LinkPreview url={event.extLink} />
            ) : (
              'No Link to External service'
            )}
          </div>
          {event.type !== 'online' && (
            <div className='px-3 bg-white text-lg w-full border-t-2 border-b-2 border-gray-300 mt-2'>
              <p className='text-gray-400 text-xs mt-2 font-bold mb-1 uppercase'>
                Location
              </p>
              <p className='text-sm mt-2 font-bold mb-1 uppercase text-mono'>
                {event.location}
              </p>
              {event.address && (
                <>
                  <p className='text-gray-400 text-xs mt-2 font-bold mb-1 uppercase'>
                    Address
                  </p>
                  <p className='text-sm mt-2 font-bold mb-1 uppercase text-mono'>
                    <a
                      href={`http://maps.google.com/?q=${
                        event.location + ',' + event.address + ',' + event.city
                      }`}
                      target='_blank'
                      rel='noreferrer'
                      className='hover:text-primaryBlue flex space-x-2'
                    >
                      <p>{event.address}</p>
                      <MapIcon className='w-3 h-3' />
                    </a>
                  </p>
                </>
              )}
              <p className='text-gray-400 text-xs mt-2 font-bold mb-1 uppercase'>
                City
              </p>
              <p className='text-sm mt-2 font-bold mb-1 uppercase text-mono'>
                {event.city}
              </p>
            </div>
          )}
          <div className=' mt-2 pt-2 px-3 bg-white text-mono text-base w-full ease-linear transition-all duration-150 items-center'>
            <div className='flex items-center py-1 space-x-2 overflow-x-scroll scrollbar scrollbar-thin scrollbar-thumb-secondaryPink scrollbar-track-green-100'>
              {event.tags.length ? (
                event.tags.map((tag) => (
                  <div
                    key={tag}
                    className={`bg-gray-200 text-gray-600 flex-none group py-1 px-2 text-center`}
                  >
                    <span className='text-xs'>{tag}</span>
                  </div>
                ))
              ) : (
                <p className='text-xs ml-2'>No tags</p>
              )}
            </div>
          </div>
        </div>
        <div className=' w-full px-4 my-4 flex flex-col space-y-4 sm:space-y-0 sm:flex-row items-center justify-around '>
          {!event.isCanceled && new Date(event.dateEnd) >= new Date() && (
            <button
              className='w-40 button-primary'
              onClick={() => {
                setAction('cancel')
                setShowModal(true)
              }}
            >
              Cancel Event
            </button>
          )}
          {user.role.includes('admin') && (
            <>
              {!event.isCanceled && (
                <button
                  className='w-40 flex space-x-4 justify-center items-center button-secondary'
                  onClick={() => {
                    setEdit(true)
                  }}
                >
                  <PencilIcon className='h-5 w-5' />
                  Edit
                </button>
              )}
              <button
                className='w-40 flex space-x-4 justify-center items-center button-primary'
                onClick={() => {
                  setAction('delete')
                  setShowModal(true)
                }}
              >
                <TrashIcon className='h-5 w-5' />
                Delete
              </button>
            </>
          )}
        </div>
      </div>
    </>
  ) : (
    <Loading />
  )
}

export default EventCard
