import { PlusCircleIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { useFormikContext } from 'formik'
import { useState } from 'react'

const styles = {
  industries: {
    tag: 'bg-secondaryPink border-pink-400',
    text: 'text-secondaryMagenta',
  },
  states: {
    tag: 'bg-secondaryLilac border-violet-400',
    text: 'text-primaryBlue',
  },
  type: {
    tag: 'bg-secondaryBlue border-sky-300',
    text: 'text-primaryBlue',
  },
  offers: {
    tag: 'bg-primaryBlue border-primaryBlue',
    text: 'text-white',
  },
  audiences: {
    tag: 'bg-primaryBlue border-primaryBlue',
    text: 'text-white',
  },
  languages: {
    tag: 'bg-primaryBlue border-primaryBlue',
    text: 'text-white',
  },
  skills: {
    tag: 'bg-secondaryLilac border-violet-400',
    text: 'text-primaryBlue',
  },
  services: {
    tag: 'bg-secondaryPink border-pink-400',
    text: 'text-secondaryMagenta',
  },
  tags: {
    tag: 'bg-secondaryLilac border-violet-400',
    text: 'text-primaryBlue',
  },
}

const Tags = ({ fieldName, defaultSuggestions }) => {
  const { values, setFieldValue } = useFormikContext()
  const [tag, setTag] = useState('')
  const [showSuggestions, setShowSuggestions] = useState(false)
  const [suggestions, setSuggestions] = useState(defaultSuggestions)

  const handleInput = (value) => {
    setTag(value)
    if (value) {
      setShowSuggestions(true)
      const regex = new RegExp('^' + value, 'gi')
      const list = defaultSuggestions.filter((tag) => tag.match(regex))
      if (!list.includes(value)) list.unshift(value)
      setSuggestions((prev) => [...list])
    } else {
      setSuggestions(defaultSuggestions)
      setShowSuggestions(false)
    }
  }

  const pushTag = (value) => {
    const convertedValue =
      fieldName === 'tags'
        ? '#' +
          value
            .replace(/[^\w,;\s]+/g, '')
            .replace(/\s+/g, '-')
            .replace(/[,;]{2,}/g, '')
            .toLowerCase()
        : value

    if (!values[fieldName].includes(convertedValue)) {
      setFieldValue(fieldName, [...values[fieldName], convertedValue])
    }
  }
  const popTag = (value) => {
    const newTags = [...values[fieldName]].filter((tag) => tag !== value)
    setFieldValue(fieldName, newTags)
  }

  const handleSelect = (value) => {
    pushTag(value)
    setTag('')
    setShowSuggestions(false)
  }
  return (
    <div className='flex flex-col w-full'>
      <div className='flex items-center relative w-full'>
        <input
          className='appearance-none outline-none block w-full bg-grey-lighter text-grey-darker focus:ring-2 ring-primaryBlue border border-grey-lighter py-3 px-4 mb-3'
          type='text'
          onChange={(e) => {
            handleInput(e.target.value)
          }}
          placeholder='Start to type'
          value={tag}
        />
        <div
          className={`absolute top-12 z-20 bg-white border px-1 ${
            showSuggestions ? '' : 'hidden'
          }`}
        >
          {suggestions.map((tag, i) => (
            <div
              key={tag + i}
              className='group w-full p-2 flex items-center space-x-2 hover:text-green-500 cursor-pointer'
              onClick={(e) => {
                handleSelect(tag)
              }}
            >
              <PlusCircleIcon className='h-4 group-hover:text-green-500' />

              <span className='text-sm'>{tag}</span>
            </div>
          ))}
        </div>
      </div>
      <div className='flex flex-wrap items-center py-1 space-x-2 overflow-x-scroll scrollbar scrollbar-thin scrollbar-thumb-secondaryPink scrollbar-track-green-100'>
        {values[fieldName]?.map((tag, i) => (
          <div
            key={tag + i + 1}
            className={`group flex space-x-2 w-fit ${styles[fieldName]?.tag} ${styles[fieldName]?.text} py-1 pl-2 pr-1 m-1 text-center cursor-pointer`}
            onClick={() => popTag(tag)}
          >
            <span className='text-xs'>{tag}</span>
            <XMarkIcon className='group-hover:text-red-500 text-black font-bold h-4' />
          </div>
        ))}
      </div>
    </div>
  )
}

export default Tags
