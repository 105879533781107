import {
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
} from '@heroicons/react/24/outline'
import { FormattedMessage } from 'react-intl'

const Pagination = ({ pageCount, currentPage, setPage }) => {
  const getPagination = () => {
    const pageLimit = pageCount > 5 ? 5 : pageCount
    let start = Math.floor((currentPage - 1) / pageLimit) * pageLimit + 1
    return new Array(pageLimit)
      .fill()
      .map((item, i) => (start + i <= pageCount ? start + i : null))
  }
  const previous = currentPage === 1 ? false : true
  const handlePrevious = () => {
    if (currentPage !== 1) setPage(currentPage - 1)
  }
  const next = currentPage === pageCount ? false : true
  const handleNext = () => {
    if (currentPage !== pageCount) setPage(currentPage + 1)
  }

  return (
    <div className='flex flex-col items-center'>
      <div className='flex text-gray-700'>
        <button
          onClick={() => handlePrevious()}
          className={`button small secondary`}
          disabled={!previous}
        >
          <ChevronDoubleLeftIcon className='w-4 h-4' />
          <span>
            <FormattedMessage id='app.previous' default='previous' />
          </span>
        </button>
        <div className='flex h-8 mx-8 font-medium '>
          {getPagination().map((item, index) => {
            return item === null ? (
              <span
                key={index}
                className='w-10 text-lg text-grotesk justify-center items-center '
              ></span>
            ) : (
              <button
                onClick={() => setPage(item)}
                key={index}
                className={`rounded-full w-8 text-sm text-grotesk justify-center items-center cursor-pointer transition duration-150 ease-in hover:bg-primaryLime  ${
                  currentPage === item ? 'font-bold' : 'md:flex hidden'
                }`}
              >
                {item}
              </button>
            )
          })}
        </div>

        <button
          onClick={() => handleNext()}
          className={`button small secondary`}
          disabled={!next}
        >
          <span>
            <FormattedMessage id='app.next' default='next' />
          </span>
          <ChevronDoubleRightIcon className='w-4 h-4 ' />
        </button>
      </div>
    </div>
  )
}

export default Pagination
