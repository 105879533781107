import { useContext, useEffect, useState } from 'react'
import AdminContext from '../../../contexts/Admin'
import { PlusIcon } from '@heroicons/react/24/outline'
import axios from 'axios'
import { getPostsUrl } from '../Config/APIurls'
import PostsList from './PostsList'
import Post from './Post'
import AddPost from './AddPost'
import TablePagination from '../Widgets/TablePagination'
import Loading from '../../Common/Widgets/Loading'

const Blog = () => {
  const {
    config,
    reload,
    checkAuthToken,
    navigate,
    currentParams,
    displayMainMessage,
  } = useContext(AdminContext)
  const [loading, setLoading] = useState(true)
  const [currentPage, setCurrentPage] = useState(1)
  const [pageCount, setPageCount] = useState(1)
  const [data, setData] = useState({ results: [], totalPages: 0 })
  const { id } = currentParams

  //Get all posts and set the result as data
  useEffect(() => {
    const fetchData = async () => {
      try {
        if (await !checkAuthToken()) return navigate('/login')
        const response = await axios.get(
          getPostsUrl + '?sortBy=createdAt:desc&limit=4&page=' + currentPage,
          config
        )
        setData(response.data)
        setCurrentPage(response.data.page)
        setPageCount(response.data.totalPages)
        setLoading(false)
      } catch (err) {
        displayMainMessage(
          {
            error: 1,
            message: err.status
              ? err.response.message
              : 'Something went wrong getting the data - please try again',
            show: true,
          },
          5000
        )
      }
    }
    setLoading(true)
    fetchData()
  }, [reload, currentPage])

  return loading ? (
    <Loading />
  ) : (
    <div className='relative h-full w-full flex px-4 md:px-8 items-center flex-col bg-white outline-none overflow-y-scroll'>
      {!id ? (
        <>
          <button
            className='button-primary mr-auto mb-4'
            onClick={() => navigate('/admin/blog/add')}
          >
            <PlusIcon className='h-5 w-5' />
            <span>New Post</span>
          </button>
          <PostsList posts={data} />
          {pageCount > 1 && (
            <div className='fixed bottom-0 bg-white border-t min-w-max w-full border-gray-200'>
              <div className='flex items-center justify-center'>
                <TablePagination
                  setPage={setCurrentPage}
                  currentPage={currentPage}
                  pageCount={pageCount}
                />
              </div>
            </div>
          )}
        </>
      ) : id === 'add' ? (
        <AddPost />
      ) : (
        <Post />
      )}
    </div>
  )
}

export default Blog
