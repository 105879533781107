import React, { useContext } from 'react'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import Dropzone from '../Widgets/DropZone'
import AdminContext from '../../../contexts/Admin'
import Tags from '../Widgets/Tags'
import axios from 'axios'
import {
  createServiceProviderUrl,
  updateServiceProviderUrl,
} from '../Config/APIurls'
import OptionList from './components/OptionList'

const defaultSuggestionsLanguages = [
  'EN',
  'FR',
  'SP',
  'PT',
  'AR',
  'RU',
  'DE',
  'IT',
  'TR',
  'PL',
  'UK',
  'RO',
  'HU',
]
const defaultSuggestionsSkills = [
  'Accounting',
  'Advertising',
  'Analytics',
  'Art',
  'Business',
  'Communication',
  'Computer',
  'Construction',
  'Customer Service',
  'Design',
  'Education',
  'Engineering',
  'Entertainment',
  'Finance',
  'Food Service',
  'Health Care',
  'Hospitality',
  'Human Resources',
  'Information Technology',
  'Legal',
  'Maintenance',
  'Management',
  'Manufacturing',
  'Marketing',
  'Media',
  'Military',
  'Operations',
  'Personal Care',
]
const defaultSuggestionsServices = [
  'Accounting & Finance',
  'Advertising & Marketing',
  'Analytics & Data Science',
  'Art Direction',
  'Business Development',
  'Business Strategy',
  'Career Coaching',
  'Career Transition',
  'Communication & PR',
  '1:1 Coaching',
  'Group Coaching',
]

const pronounsList = [
  { value: 'She/Her', label: 'She/Her' },
  { value: 'He/Him', label: 'He/Him' },
  { value: 'They/Them', label: 'They/Them' },
]
const bookingTypes = [
  { value: 'Link', label: 'Link' },
  { value: 'Email', label: 'Email' },
]
const availability = [
  { value: true, label: 'Available' },
  { value: false, label: 'Not Listed' },
]

const ServiceProviderFormSchema = Yup.object().shape({
  name: Yup.string().required('A name is required'),
  email: Yup.string()
    .email('A valid email is required')
    .required('An email is required'),
  pronouns: Yup.string().required('Pronouns are required'),
  companyLink: Yup.string().url('Link must be a valid URL').nullable(),
  instagram: Yup.string().url('Link must be a valid URL').nullable(),
  linkedin: Yup.string().url('Link must be a valid URL').nullable(),
  otherSocial: Yup.string().url('Link must be a valid URL').nullable(),
  title: Yup.string().required('A professional title is required'),
  company: Yup.string().nullable(),
  industry: Yup.string().required('Industry is required'),
  location: Yup.string().nullable(),
  bio: Yup.string().required('A bio is required'),
  disclaimer: Yup.string().nullable(),
  languages: Yup.array().of(Yup.string()).min(1, 'One language is required'),
  services: Yup.array().of(Yup.string()).min(1, 'One service is required'),
  skills: Yup.array().of(Yup.string()).nullable(),
  headshot: Yup.object()
    .shape(
      {
        public_id: Yup.string(),
        url: Yup.string(),
      },
      'Please reupload a valid image'
    )
    .nullable(),
  bookingType: Yup.string().required('Booking type is required'),
  bookingLink: Yup.string().required('Link or email for booking is required'),
  available: Yup.boolean().required('Availability is required'),
})

const AddServiceProvider = ({ serviceProvider, setEdit, edit }) => {
  const { navigate, config, displayMainMessage, reload, setReload } =
    useContext(AdminContext)

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      let response
      if (values.id) {
        response = await axios.patch(
          updateServiceProviderUrl + values.id,
          values,
          config
        )
      } else {
        response = await axios.post(createServiceProviderUrl, values, config)
      }

      if (response.status === 201 || 200) {
        displayMainMessage(
          {
            success: 1,
            message: 'Service Provider Saved',
            show: true,
          },
          3000
        )
        setSubmitting(false)
        setReload(reload + 1)
        if (edit) {
          setEdit(false)
        } else {
          navigate('/admin/service-providers/')
        }
      } else {
        displayMainMessage(
          {
            success: 0,
            message: 'Something went wrong',
            show: true,
          },
          4000
        )
        setSubmitting(false)
      }
    } catch (error) {
      displayMainMessage(
        {
          error: 1,
          message: 'Something went wrong',
          show: true,
        },
        4000
      )
    }
  }

  return (
    <div className='w-full flex-col px-4 md:px-12'>
      <h2 className='text-3xl font-bold mb-4 uppercase'>
        Add new Service Provider
      </h2>
      <Formik
        initialValues={
          serviceProvider || {
            id: null,
            name: '',
            email: '',
            pronouns: '',
            companyLink: '',
            instagram: '',
            linkedin: '',
            otherSocial: '',
            title: '',
            company: '',
            industry: '',
            location: '',
            bio: '',
            disclaimer: '',
            languages: [],
            services: [],
            skills: [],
            headshot: null,
            bookingType: '',
            bookingLink: '',
            available: true,
          }
        }
        validationSchema={ServiceProviderFormSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, values, errors, setValues }) => {
          return (
            <Form>
              <div className='form-section'>
                <div className='form-item'>
                  <label htmlFor='name' className='block font-bold mb-2'>
                    Name
                  </label>
                  <Field
                    type='text'
                    name='name'
                    id='name'
                    className='w-full border-gray-400 border-2 rounded-md p-2'
                  />
                  <ErrorMessage
                    name='name'
                    component='div'
                    className='text-invalid text-sm mt-1'
                  />
                </div>
                <div className='form-item'>
                  <label htmlFor='email' className='block font-bold mb-2'>
                    Email
                  </label>
                  <Field
                    type='text'
                    name='email'
                    id='email'
                    className='w-full border-gray-400 border-2 rounded-md p-2'
                  />
                  <ErrorMessage
                    name='email'
                    component='div'
                    className='text-invalid text-sm mt-1'
                  />
                </div>
              </div>
              <div className='form-section'>
                <div className='form-item'>
                  <label htmlFor='pronouns' className='block font-bold mb-2'>
                    Pronouns
                  </label>
                  <OptionList
                    label='pronouns'
                    dataList={pronounsList}
                    name='pronouns'
                    id='pronouns'
                  />
                </div>
                <div className='form-item'>
                  <label htmlFor='title' className='block font-bold mb-2'>
                    Title
                  </label>
                  <Field
                    type='text'
                    name='title'
                    id='title'
                    className='w-full border-gray-400 border-2 rounded-md p-2'
                  />
                  <ErrorMessage
                    name='title'
                    component='div'
                    className='text-invalid text-sm mt-1'
                  />
                </div>
              </div>
              <div className='form-section'>
                <div className='form-item'>
                  <label htmlFor='company' className='block font-bold mb-2'>
                    Company
                  </label>
                  <Field
                    type='text'
                    name='company'
                    id='company'
                    className='w-full border-gray-400 border-2 rounded-md p-2'
                  />
                  <ErrorMessage
                    name='company'
                    component='div'
                    className='text-invalid text-sm mt-1'
                  />
                </div>
                <div className='form-item'>
                  <label htmlFor='industry' className='block font-bold mb-2'>
                    Industry
                  </label>
                  <Field
                    type='text'
                    name='industry'
                    id='industry'
                    className='w-full border-gray-400 border-2 rounded-md p-2'
                  />
                  <ErrorMessage
                    name='industry'
                    component='div'
                    className='text-invalid text-sm mt-1'
                  />
                </div>
              </div>
              <div className='form-section'>
                <div className='form-item'>
                  <label htmlFor='companyLink' className='block font-bold mb-2'>
                    Company Link
                  </label>
                  <Field
                    type='text'
                    name='companyLink'
                    id='companyLink'
                    className='w-full border-gray-400 border-2 rounded-md p-2'
                  />
                  <ErrorMessage
                    name='companyLink'
                    component='div'
                    className='text-invalid text-sm mt-1'
                  />
                </div>
                <div className='form-item'>
                  <label htmlFor='linkedin' className='block font-bold mb-2'>
                    Linkedin
                  </label>
                  <Field
                    type='text'
                    name='linkedin'
                    id='linkedin'
                    className='w-full border-gray-400 border-2 rounded-md p-2'
                  />
                  <ErrorMessage
                    name='linkedin'
                    component='div'
                    className='text-invalid text-sm mt-1'
                  />
                </div>
              </div>
              <div className='form-section'>
                <div className='form-item'>
                  <label htmlFor='instagram' className='block font-bold mb-2'>
                    Instagram
                  </label>
                  <Field
                    type='text'
                    name='instagram'
                    id='instagram'
                    className='w-full border-gray-400 border-2 rounded-md p-2'
                  />
                  <ErrorMessage
                    name='instagram'
                    component='div'
                    className='text-invalid text-sm mt-1'
                  />
                </div>
                <div className='form-item'>
                  <label htmlFor='otherSocial' className='block font-bold mb-2'>
                    Other Social
                  </label>
                  <Field
                    type='text'
                    name='otherSocial'
                    id='otherSocial'
                    className='w-full border-gray-400 border-2 rounded-md p-2'
                  />
                  <ErrorMessage
                    name='otherSocial'
                    component='div'
                    className='text-invalid text-sm mt-1'
                  />
                </div>
              </div>
              <div className='form-section'>
                <div className='form-item'>
                  <label htmlFor='languages' className='block font-bold mb-2'>
                    Languages
                  </label>
                  <Tags
                    fieldName={'languages'}
                    defaultSuggestions={defaultSuggestionsLanguages}
                  />
                  <ErrorMessage
                    name='languages'
                    component='div'
                    className='text-invalid text-sm mt-1'
                  />
                </div>
                <div className='form-item'>
                  <label htmlFor='skills' className='block font-bold mb-2'>
                    Skills
                  </label>
                  <Tags
                    fieldName={'skills'}
                    defaultSuggestions={defaultSuggestionsSkills}
                  />
                  <ErrorMessage
                    name='skills'
                    component='div'
                    className='text-invalid text-sm mt-1'
                  />
                </div>
              </div>
              <div className='form-section'>
                <div className='col-span-2'>
                  <label htmlFor='services' className='block font-bold mb-2'>
                    Services
                  </label>
                  <Tags
                    fieldName={'services'}
                    defaultSuggestions={defaultSuggestionsServices}
                  />
                  <ErrorMessage
                    name='services'
                    component='div'
                    className='text-invalid text-sm mt-1'
                  />
                </div>
              </div>
              <div className='form-section'>
                <div className='form-item'>
                  <label htmlFor='bio' className='block font-bold mb-2'>
                    Bio
                  </label>
                  <Field
                    component='textarea'
                    name='bio'
                    id='bio'
                    className='w-full border-gray-400 border-2 rounded-md p-2'
                  />
                  <ErrorMessage
                    name='bio'
                    component='div'
                    className='text-invalid text-sm mt-1'
                  />
                </div>
                <div className='form-item'>
                  <label htmlFor='disclaimer' className='block font-bold mb-2'>
                    Disclaimer
                  </label>
                  <Field
                    component='textarea'
                    name='disclaimer'
                    id='disclaimer'
                    className='w-full border-gray-400 border-2 rounded-md p-2'
                  />
                  <ErrorMessage
                    name='disclaimer'
                    component='div'
                    className='text-invalid text-sm mt-1'
                  />
                </div>
              </div>
              <div className='form-section'>
                <div className='col-span-2'>
                  <label htmlFor='location' className='block font-bold mb-2'>
                    Location
                  </label>
                  <Field
                    type='text'
                    name='location'
                    id='location'
                    className='w-full border-gray-400 border-2 rounded-md p-2'
                  />
                  <ErrorMessage
                    name='location'
                    component='div'
                    className='text-invalid text-sm mt-1'
                  />
                </div>
              </div>
              <div className='form-section'>
                <div className='form-item'>
                  <label htmlFor='bookingType' className='block font-bold mb-2'>
                    Booking type
                  </label>
                  <OptionList
                    dataList={bookingTypes}
                    label='bookingType'
                    name='bookingType'
                    id='bookingType'
                  />
                </div>
                <div className='form-item'>
                  <label htmlFor='bookingLink' className='block font-bold mb-2'>
                    Booking Link
                  </label>
                  <Field
                    type='text'
                    name='bookingLink'
                    id='bookingLink'
                    className='w-full border-gray-400 border-2 rounded-md p-2'
                  />
                  <ErrorMessage
                    name='bookingLink'
                    component='div'
                    className='text-invalid text-sm mt-1'
                  />
                </div>
              </div>
              {serviceProvider?.id && (
                <div className='form-section'>
                  <div className='col-span-2'>
                    <label
                      htmlFor='bookingType'
                      className='block font-bold mb-2'
                    >
                      Availability
                    </label>
                    <OptionList
                      dataList={availability}
                      label='available'
                      name='available'
                      id='available'
                    />
                  </div>
                </div>
              )}
              <div className='form-section'>
                <div className='col-span-2'>
                  <label
                    htmlFor='headshot'
                    className='block uppercase tracking-wide text-xs font-bold mb-2'
                  >
                    Logo/Headshot
                  </label>
                  <Dropzone
                    classes={`appearance-none outline-none outline-none block w-full border-2 border-gray-300 border-black border-dotted  py-3 px-4 mb-3`}
                    type='headshot'
                    folder='service-providers'
                    required={true}
                    edit={edit}
                  />
                  <ErrorMessage
                    name='headshot'
                    component='div'
                    className='text-invalid text-sm mt-1'
                  />
                </div>
              </div>
              <div className='form-section flex-col-reverse'>
                <button
                  type='button'
                  disabled={isSubmitting}
                  className='button-secondary'
                  onClick={() => {
                    values.id ? setEdit(false) : navigate(-1)
                  }}
                >
                  cancel
                </button>
                <button
                  type='submit'
                  disabled={isSubmitting}
                  className='button-primary'
                >
                  {isSubmitting ? 'Submitting...' : 'Submit'}
                </button>
              </div>
            </Form>
          )
        }}
      </Formik>
    </div>
  )
}

export default AddServiceProvider
