import { FormattedMessage, useIntl } from 'react-intl'
import TextInput from './components/TextInput'
import OptionList from './components/OptionList'
import { Form } from 'formik'
import { useFormikContext } from 'formik'
import { useEffect } from 'react'
import TextArea from './components/TextArea'
import ThankYou from './ThankYou'
import { CheckIcon } from '@heroicons/react/24/outline'

const FormTemplate = ({
  formFields,
  identifyAsList,
  furtherIdentifyAsList,
  locationList,
  error,
  isSubmitting,
  success,
}) => {
  const intl = useIntl()
  const formikData = useFormikContext()
  const getKeys = async (obj) => {
    let keys = []
    for (const key in obj) {
      keys.push(key)
      if (typeof obj[key] === 'object') {
        let subkeys = await getKeys(obj[key])
        keys = keys.concat(subkeys.map((subkey) => key + '.' + subkey))
      }
    }
    return keys
  }
  // Jump to error
  useEffect(() => {
    if (!formikData.isSubmitting) return
    const checkErrorsOnPage = async () => {
      const currentErrors = await getKeys(formikData.errors)
      if (currentErrors.length > 0) {
        const errorOnPageArray = formFields.filter((error) =>
          currentErrors.includes(error)
        )
        if (errorOnPageArray.length) {
          const firstElement = document.getElementsByName(
            errorOnPageArray[0]
          )[0]
          var elOffset = 160
          var firstElementPos = firstElement.getBoundingClientRect().top
          var elOffsetPosition = firstElementPos + window.pageYOffset - elOffset

          window.scrollTo({
            top: elOffsetPosition,
            behavior: 'smooth',
          })
          setTimeout(() => {
            document.getElementsByName(errorOnPageArray[0])[0].focus()
          }, 800)
        }
      }
    }
    checkErrorsOnPage()
  }, [formikData])

  return (
    <Form className='grid grid-cols-10 gap-5 w-full'>
      {success ? (
        <ThankYou />
      ) : (
        <>
          <div className='col-span-6 lg:col-span-8 col-start-3 lg:col-start-2'>
            <h2>
              <FormattedMessage id='signup.title' defaultMessage='Join Us' />
            </h2>
            <h4>
              <FormattedMessage
                id='app.cta'
                defaultMessage='To be the first to know about events, resources, and the launch of our digital platform!'
              />
            </h4>
          </div>
          <div className='col-span-6 lg:col-span-8 col-start-3 lg:col-start-2'>
            <div className='form-section'>
              <div className='form-item'>
                <TextInput
                  id='firstName'
                  name='firstName'
                  label='First Name(s)'
                  type='text'
                  required={true}
                />
              </div>
              <div className='form-item'>
                <TextInput
                  id='lastName'
                  name='lastName'
                  label='Last Name(s)'
                  type='text'
                  required={true}
                />
              </div>
            </div>
            <div className='form-section'>
              <div className='form-item'>
                <TextInput
                  id='pronouns'
                  name='pronouns'
                  label='Pronouns'
                  type='text'
                  required={false}
                />
              </div>
              <div className='form-item'>
                <OptionList
                  id='furtherIdentifyAs'
                  name='furtherIdentifyAs'
                  label='I identify as'
                  required={false}
                  dataList={furtherIdentifyAsList}
                />
              </div>
            </div>
            <div className='form-section'>
              <div className='form-item'>
                <OptionList
                  id='identifyAs'
                  name='identifyAs'
                  label='I am'
                  type='text'
                  required={false}
                  dataList={identifyAsList}
                />
              </div>
              <div className='form-item'>
                <OptionList
                  id='location'
                  name='location'
                  label='Where are you based?'
                  type='text'
                  required={true}
                  dataList={locationList}
                />
              </div>
            </div>
            <div className='w-full mb-8'>
              <div className='form-item'>
                <TextArea
                  id='industry'
                  name='industry'
                  label='Which industry is your business in?'
                  notes='Only if applicable'
                  placeholder='(eg. tech, fashion, food)'
                  type='text'
                  required={false}
                />
              </div>
            </div>
            <div className='form-section'>
              <div className='form-item'>
                <TextInput
                  id='link'
                  name='link'
                  label='Can you share a link to your work?'
                  placeholder='(eg. website, portfolio, LinkedIn)'
                  notes='Only if applicable'
                  type='text'
                  required={false}
                />
              </div>
            </div>
            <div className='form-section'>
              <div className='form-item'>
                <TextInput
                  id='socials'
                  name='socials'
                  label='Socials (eg. LinkedIn)'
                  type='text'
                  required={false}
                />
              </div>
              <div className='form-item'>
                <TextInput
                  id='email'
                  name='email'
                  label='Email'
                  type='email'
                  required={true}
                />
              </div>
            </div>
            <div className='relative w-full flex flex-col justify-center items-center'>
              <p className='text-center absolute left-0 -top-6 md:-top-4 font-bold text-red-400 mx-auto '>
                {error &&
                  intl.formatMessage({
                    id: 'signup.' + error.toLowerCase(),
                    defaultMessage: 'Something went wrong, please try again',
                  })}
              </p>
            </div>
          </div>
        </>
      )}
      <div className='col-span-6 lg:col-span-8 col-start-3 lg:col-start-2'>
        <button
          disabled={isSubmitting || success}
          className={`flex space-x-4 justify-center items-center button primary relative group w-full mt-10`}
          type='submit'
        >
          <FormattedMessage id='app.signup' defaultMessage='Sign Up' />
          {success && <CheckIcon className='ml-2 w-4 h-4' />}
        </button>
      </div>
    </Form>
  )
}

export default FormTemplate
