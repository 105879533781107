import { useParams, useNavigate } from 'react-router-dom'
import { useState, useMemo, useEffect } from 'react'
import axios from 'axios'
import jwtDecode from 'jwt-decode'

const forgotUrl = '/api/v1/auth/reset-password'

const Forgot = () => {
  const { token } = useParams()
  const navigate = useNavigate()
  const [saving, setSaving] = useState(false)
  const [error, setError] = useState(null)
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [isTokenValid, setIsTokenValid] = useState(false)
  const [complete, setComplete] = useState(false)
  const config = useMemo(() => {
    return {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    }
  }, [token])
  const handleSubmit = async (e) => {
    e.preventDefault()
    setSaving(true)
    if (checkPassword() && checkConfirmPassword()) {
      try {
        const decode = jwtDecode(token)
        const now = Date.now() / 1000
        const expiry = decode?.exp //1667224386000
        if (now > expiry) {
          setIsTokenValid(false)
        }
        const data = { token, password }
        const result = await axios.post(forgotUrl, data, config)
        if (result?.status === 204) {
          setComplete(true)
          setSaving(false)
          setTimeout(() => {
            navigate('/admin/dashboard')
          }, 4000)
        } else {
          setSaving(false)
          setError('Sorry, something went wrong')
          setTimeout(() => {
            setError('')
          }, 4000)
        }
      } catch (e) {
        setSaving(false)
        setError('Sorry, something went wrong')
        setTimeout(() => {
          setError('')
        }, 4000)
      }
    } else {
      setSaving(false)
      setError(
        !checkPassword()
          ? "Password doesn't fulfill requirement"
          : 'Password do not match!'
      )
      setTimeout(() => {
        setError('')
      }, 4000)
    }
  }
  const checkPassword = () => {
    return /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/.test(password)
  }
  const checkConfirmPassword = () => {
    return password === confirmPassword
  }

  useEffect(() => {
    const decoded = jwtDecode(token)
    const now = Date.now() / 1000
    const expiry = decoded?.exp //1667224386000
    if (now < expiry) {
      setIsTokenValid(true)
    }
  }, [token])

  if (!isTokenValid) {
    return (
      <div className='w-full md:w-2/3 lg:w-1/2 mx-auto h-screen flex flex-col justify-center items-center'>
        <div className='sm:shadow w-full h-1/5 p-5'>
          <div className='my-3 flex items-center justify-between'>
            <span className='border-b '></span>
            <p className='text-grotesk text-center text-secondaryMagenta uppercase'>
              Admin login
            </p>
            <span className='border-b '></span>
          </div>
          <div className=' flex justify-center text-lg'>
            Sorry your request has expired.
          </div>
        </div>
      </div>
    )
  }

  return (
    <form onSubmit={handleSubmit}>
      <div className='w-full md:w-2/3 lg:w-1/2 h-screen mx-auto flex flex-col justify-center items-center p-5'>
        <div className='sm:shadow w-full p-5'>
          <div className='my-3 flex items-center justify-between'>
            <span className='border-b '></span>
            <p className='text-grotesk text-center text-secondaryMagenta uppercase'>
              Admin login
            </p>
            <span className='border-b '></span>
          </div>
          <div className='flex flex-col px-3 mb-5'>
            <label
              className='block uppercase tracking-wide text-grey-darker text-lg font-bold mb-2'
              htmlFor='password'
            >
              Set your new password
            </label>
          </div>
          <div className='-mx-3 md:flex mb-6'>
            <div className='md:w-full px-3'>
              <label
                className='block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2'
                htmlFor='password'
              >
                Password
              </label>
              <input
                className={`${
                  password === ''
                    ? ''
                    : !checkPassword()
                    ? 'border-l-4 border-red-500'
                    : 'border-l-4 border-green-500'
                } appearance-none outline-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter py-3 px-4 mb-3`}
                id='password'
                type='password'
                autoComplete='new-password'
                onChange={(e) => setPassword(e.target.value)}
                placeholder='******************'
              />
            </div>
            <div className='md:w-full px-3'>
              <label
                className='block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2'
                htmlFor='password'
              >
                Confirm Password
              </label>
              <input
                className={`${
                  password === ''
                    ? ''
                    : !checkConfirmPassword()
                    ? 'border-l-4 border-red-500'
                    : 'border-l-4 border-green-500'
                } appearance-none outline-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter py-3 px-4 mb-3`}
                id='confirmpassword'
                type='password'
                autoComplete='new-password'
                onChange={(e) => setConfirmPassword(e.target.value)}
                placeholder='******************'
              />
            </div>
          </div>
          <div className='-mt-6 text-xs'>
            *Minimum eight characters, at least one upper case letter, one lower
            case letter and one number
          </div>
          <div className='mt-4 px-4 py-4 flex flex-col-reverse sm:flex-row items-center justify-around -mb-3'>
            <button
              className='px-6 py-2 w-full shadow-lg bg-green-400 transition duration-200 hover:bg-green-500 hover:text-white mb-4'
              onClick={(e) => handleSubmit(e)}
            >
              {saving ? (
                <div className='flex justify-center'>
                  <div
                    style={{ borderTopColor: 'transparent' }}
                    className='w-5 h-5 border-2 border-black group-hover:border-white border-dotted rounded-full animate-spin'
                  ></div>
                </div>
              ) : error?.length ? (
                <span className=' animate-pulse'>{error}</span>
              ) : complete ? (
                <span className=' animate-pulse'>
                  Password saved, redirecting...
                </span>
              ) : (
                'Confirm'
              )}
            </button>
          </div>
        </div>
      </div>
    </form>
  )
}

export default Forgot
