import { useEffect } from 'react'
import About from './About/About'
import Footer from './Footer'
import Header from './Header'
import Menu from './Menu'
import { Route, Routes, useLocation } from 'react-router-dom'
import EventsList from './Participate/EventsList'
import PageNotFound from './PageNotFound'
import PrivacyPolicy from './PrivacyPolicy/PrivacyPolicy'
import Resources from './Resources/Resources'
import Impressum from './PrivacyPolicy/Impressum'
import OpenHours from './OpenHours/OpenHours'
import Booking from './OpenHours/Booking'
import TranslateButton from './components/TranslateButton'
import CookieNotice from './components/CookieNotice'
import Blog from './Blog/Blog'
import Marquee from './components/Marquee'
import InstagramFeed from './InstagramFeed/InstagramFeed'

const Main = () => {
  const { pathname, hash, key } = useLocation()

  useEffect(() => {
    if (hash === '') {
      window.scrollTo(0, 0)
    } else {
      setTimeout(() => {
        const id = hash.replace('#', '')
        const element = document.getElementById(id)
        if (element) {
          const headerHeight = 60 // Adjust this value to match your header height
          const elementTop = element.getBoundingClientRect().top
          const offset = elementTop - headerHeight

          window.scrollTo({
            top: offset + window.scrollY,
            behavior: 'smooth',
          })
        }
      }, 0)
    }
  }, [pathname, hash, key])

  return (
    <>
      <Menu />
      <Header />
      <div className='max-w-7xl border-x border-black mx-auto mt-20'>
        <Routes>
          <Route path='' element={<About />} />
          <Route path='events/*' element={<EventsList />} />
          <Route path='resources/*' element={<Resources />} />
          <Route path='privacy-policy/*' element={<PrivacyPolicy />} />
          <Route path='impressum/*' element={<Impressum />} />
          <Route path='open-hours/*' element={<OpenHours />} />
          <Route path='booking/*' element={<Booking />} />
          <Route path='blog/:id?' element={<Blog />} />
          <Route path='*' element={<PageNotFound />} />
        </Routes>
        <Marquee />
        <InstagramFeed />
      </div>
      <TranslateButton />
      <Footer />
      <CookieNotice />
    </>
  )
}

export default Main
