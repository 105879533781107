import Menu from './Menu'
import Header from './Header'
import Popup from '../Common/Widgets/Popup'
import { useState, useEffect, useContext } from 'react'
import AdminContext from '../../contexts/Admin'
import axios from 'axios'
import { Route, Routes } from 'react-router-dom'
import Dashboard from './Dashboard/Dashboard'
import Banner from '../Common/Widgets/Banner'
import Members from './Members/Members'
import Blog from './Blog/Blog'
import Resources from './Resources/Resources'
import Settings from './Settings/Settings'
import { checkVersionUrl } from './Config/APIurls'
import Events from './Events/Events'
import PageNotFound from './PageNotFound'
import ServiceProviders from './ServiceProviders/ServiceProviders'

const Main = () => {
  const { pageTop, mainBanner, setMainBanner } = useContext(AdminContext)
  const [popUp, setPopUp] = useState(false)
  const [versionUpdate, setVersionUpdate] = useState({
    title: '',
    content: '',
    message: 'Update',
  })

  const closePopUp = () => {
    setPopUp(false)
    window.location.reload(true)
  }
  const { config, user } = useContext(AdminContext)

  useEffect(() => {
    //Check version and reload on new
    const checkVersion = async () => {
      const { data } = await axios.get(checkVersionUrl, config)
      if (data.version !== user.version) {
        setVersionUpdate({
          title: '⚙️ Update available - version ' + data.version,
          content: data.update,
          message: 'Update',
        })
        setPopUp(true)
      }
    }
    checkVersion()
  }, [])

  return (
    <div className='flex w-full h-screen'>
      <Menu />
      <div className='absolute w-screen flex justify-center items-center top-2 px-4'>
        <Banner message={mainBanner} setBanner={setMainBanner} />
      </div>
      <div className='relative w-full flex flex-col overflow-hidden'>
        <Header />
        <main className='relative overflow-y-auto w-full h-screen flex flex-col pt-4'>
          <span ref={pageTop}></span>
          <Routes>
            <Route path='dashboard' element={<Dashboard />} />
            <Route path='resources/*' element={<Resources />} />
            <Route path='events/*' element={<Events />} />
            <Route path='blog/*' element={<Blog />} />
            <Route path='members/*' element={<Members />} />
            <Route path='service-providers/*' element={<ServiceProviders />} />
            <Route path='settings/*' element={<Settings />} />
            <Route path='*' element={<PageNotFound />} />
          </Routes>
        </main>
        <Popup
          popUp={popUp}
          closePopUp={closePopUp}
          title={versionUpdate.title}
          content={versionUpdate.content}
          message={versionUpdate.message}
        />
      </div>
    </div>
  )
}

export default Main
