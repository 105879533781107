import axios from 'axios'
import { useContext, useState } from 'react'
import AdminContext from '../../contexts/Admin'
import { ChevronLeftIcon } from '@heroicons/react/24/outline'
import { loginUrl, forgotPasswordUrl } from './Config/APIurls'

const Login = () => {
  const { setToken } = useContext(AdminContext)
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [forgotPassword, setForgotPassword] = useState(false)
  const [unverified, setUnverified] = useState(false)
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)

  const handleSubmit = async (e) => {
    e.preventDefault()
    let timeOutIds = window.setTimeout(function () {}, 0)
    while (timeOutIds--) {
      window.clearTimeout(timeOutIds)
    }
    setLoading(true)
    try {
      if (forgotPassword) {
        if (!email.length) {
          const exception = new Error()
          exception.name = 'missing field'
          exception.response = {
            status: '000',
          }
          throw exception
        }
        const result = await axios.post(forgotPasswordUrl, {
          email,
        })
        if (result.status === 204) {
          setLoading(false)
          setError('Email sent')
          setTimeout(() => {
            setForgotPassword(false)
            setError('')
          }, 3000)
        }
      } else {
        const loginData = { email, password }
        if (!email.length && !password.length) {
          const exception = new Error()
          exception.name = 'missing field'
          exception.response = {
            status: '000',
          }
          throw exception
        }
        const config = {
          header: {
            'Content-Type': 'application/json',
          },
        }
        const { data } = await axios.post(loginUrl, loginData, config)
        localStorage.setItem('BSAdminAuthToken', data.tokens.access.token)
        localStorage.setItem('BSAdminRefreshToken', data.tokens.refresh.token)
        if (data.user) {
          setError('Welcome ' + data.user.name)
          setLoading(false)
          setTimeout(() => {
            setToken(localStorage.BSAdminAuthToken)
          }, 2000)
        } else {
          throw new Error({ response: { status: '401' } })
        }
      }
    } catch (err) {
      setLoading(false)
      switch (err.response.status) {
        case 404: {
          setError('User not Found')
          break
        }
        case 401: {
          setError('Wrong Credentials')
          break
        }
        case 422: {
          setError('User unverified')
          setUnverified(true)
          break
        }
        case 423: {
          setError('Access locked')
          break
        }
        case '000': {
          setError('Please fill all fields')
          break
        }
        case 'NO RESULT': {
          setError('User not found')
          break
        }
        default: {
          setError('Sorry, Something went wrong')
          break
        }
      }
      setTimeout(() => {
        setError('')
        setUnverified(false)
      }, 5000)
    }
  }
  return (
    <div className='flex h-screen justify-center items-center w-full '>
      <div className='flex bg-white shadow-lg max-w-2xl md:w-5/6'>
        <div className='w-full p-8'>
          <div className='flex justify-center text-futura text-2xl'>
            Brave Spaces 2.0
          </div>
          <div className='mt-4 flex items-center justify-between'>
            <span className='border-b w-1/5 lg:w-1/4'></span>
            <p className='text-center text-secondaryMagenta uppercase'>
              {forgotPassword ? 'Forgot password?' : 'Admin access'}
            </p>
            <span className='border-b w-1/5 lg:w-1/4'></span>
          </div>
          <form onSubmit={handleSubmit}>
            <div className='w-full mt-4'>
              <label className='block text-sm font-bold mb-2'>email</label>
              <input
                className='border border-gray-300 py-2 px-4 block w-full appearance-none'
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                autoComplete='email'
              />
            </div>
            <div className={'w-full ' + (forgotPassword ? 'hidden' : 'mt-4')}>
              <label className='block text-sm font-bold mb-2'>Password</label>
              <input
                className='border border-gray-300 py-2 px-4 block w-full appearance-none'
                type='password'
                value={password}
                autoComplete='current-password'
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <div className='mt-1 flex justify-end text-xs '>
              {unverified ? (
                '⚠️ You must verify your email before acessing the portal. A verification email was sent to your inbox.'
              ) : forgotPassword ? (
                ' We’ll send instructions to your inbox to reset your password.'
              ) : forgotPassword && unverified ? (
                '⚠️ You must verify your email before acessing the portal. A verification email was sent to your inbox.'
              ) : (
                <button
                  type='button'
                  onClick={() => setForgotPassword(true)}
                  className={`px-2 hover:text-secondaryMagenta duration-200 ease-in text-xs text-justify ${
                    unverified ? 'animate-pulse text-gray-800' : 'text-gray-500'
                  }`}
                >
                  Forgot Password?
                </button>
              )}
            </div>
            <div className='mt-8 flex w-full justify-between'>
              <button
                type='button'
                onClick={() => {
                  setForgotPassword(false)
                }}
                className={
                  forgotPassword
                    ? 'group lg:w-1/4 px-4 py-2 text-mono bg-black transition-colors ease-in-out duration-500 hover:bg-secondaryMagenta hover:text-black text-lg text-white flex justify-center items-center  '
                    : 'hidden'
                }
              >
                <ChevronLeftIcon className='w-5 h-5 mr-1' />
                <span className='hidden xl:block text-sm'>Back</span>
              </button>
              <button
                type='submit'
                className={`
                  ${forgotPassword ? 'w-4/6 ' : 'w-full '}
                     bg-black text-white group px-4 py-2 text-mono transition-colors ease-in-out duration-500 hover:bg-secondaryMagenta hover:text-black text-sm`}
              >
                {loading ? (
                  <div className='flex justify-center'>
                    <div
                      style={{ borderTopColor: 'transparent' }}
                      className='w-5 h-5 border-2 border-white border-dotted rounded-full animate-spin'
                    ></div>
                  </div>
                ) : error ? (
                  <span className=' animate-pulse'>{error}</span>
                ) : forgotPassword ? (
                  'Reset Password'
                ) : (
                  'Login'
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default Login
