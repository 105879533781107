import { ReactComponent as StarSymbol } from '../../../assets/elements/star.svg'

const Marquee = ({ children }) => {
  return (
    <section id='marquee'>
      <div className='marquee'>
        <div className='marquee__group'>
          <StarSymbol className='mx-2 w-10 h-10 fill-secondaryLilac' />
          <span className='marquee-item text-white'>inclusive</span>
          <StarSymbol className='mx-2 w-10 h-10 fill-secondaryLilac' />
          <span className='marquee-item bg-white'>intersectional</span>
          <StarSymbol className='mx-2 w-10 h-10 fill-secondaryLilac' />
          <span className='marquee-item text-white'>unapologetic</span>
          <StarSymbol className='mx-2 w-10 h-10 fill-secondaryLilac' />
          <span className='marquee-item bg-white'>empowering</span>
          <StarSymbol className='mx-2 w-10 h-10 fill-secondaryLilac' />
          <span className='marquee-item text-white'>innovative</span>
          <StarSymbol className='mx-2 w-10 h-10 fill-secondaryLilac' />
          <span className='marquee-item bg-white'>inclusive</span>
          <StarSymbol className='mx-2 w-10 h-10 fill-secondaryLilac' />
          <span className='marquee-item text-white'>intersectional</span>
          <StarSymbol className='mx-2 w-10 h-10 fill-secondaryLilac' />
          <span className='marquee-item bg-white'>unapologetic</span>
          <StarSymbol className='mx-2 w-10 h-10 fill-secondaryLilac' />
          <span className='marquee-item text-white'>empowering</span>
          <StarSymbol className='mx-2 w-10 h-10 fill-secondaryLilac' />
          <span className='marquee-item bg-white'>innovative</span>
          <StarSymbol className='mx-2 w-10 h-10 fill-secondaryLilac' />
          <span className='marquee-item text-white'>inclusive</span>
          <StarSymbol className='mx-2 w-10 h-10 fill-secondaryLilac' />
          <span className='marquee-item bg-white'>intersectional</span>
          <StarSymbol className='mx-2 w-10 h-10 fill-secondaryLilac' />
          <span className='marquee-item text-white'>unapologetic</span>
          <StarSymbol className='mx-2 w-10 h-10 fill-secondaryLilac' />
          <span className='marquee-item bg-white'>empowering</span>
          <StarSymbol className='mx-2 w-10 h-10 fill-secondaryLilac' />
          <span className='marquee-item text-white'>innovative</span>
        </div>
        <div aria-hidden='true' className='marquee__group'>
          <StarSymbol className='mx-2 w-10 h-10 fill-secondaryLilac' />
          <span className='marquee-item text-white'>inclusive</span>
          <StarSymbol className='mx-2 w-10 h-10 fill-secondaryLilac' />
          <span className='marquee-item bg-white'>intersectional</span>
          <StarSymbol className='mx-2 w-10 h-10 fill-secondaryLilac' />
          <span className='marquee-item text-white'>unapologetic</span>
          <StarSymbol className='mx-2 w-10 h-10 fill-secondaryLilac' />
          <span className='marquee-item bg-white'>empowering</span>
          <StarSymbol className='mx-2 w-10 h-10 fill-secondaryLilac' />
          <span className='marquee-item text-white'>innovative</span>
          <StarSymbol className='mx-2 w-10 h-10 fill-secondaryLilac' />
          <span className='marquee-item bg-white'>inclusive</span>
          <StarSymbol className='mx-2 w-10 h-10 fill-secondaryLilac' />
          <span className='marquee-item text-white'>intersectional</span>
          <StarSymbol className='mx-2 w-10 h-10 fill-secondaryLilac' />
          <span className='marquee-item bg-white'>unapologetic</span>
          <StarSymbol className='mx-2 w-10 h-10 fill-secondaryLilac' />
          <span className='marquee-item text-white'>empowering</span>
          <StarSymbol className='mx-2 w-10 h-10 fill-secondaryLilac' />
          <span className='marquee-item bg-white'>innovative</span>
          <StarSymbol className='mx-2 w-10 h-10 fill-secondaryLilac' />
          <span className='marquee-item text-white'>inclusive</span>
          <StarSymbol className='mx-2 w-10 h-10 fill-secondaryLilac' />
          <span className='marquee-item bg-white'>intersectional</span>
          <StarSymbol className='mx-2 w-10 h-10 fill-secondaryLilac' />
          <span className='marquee-item text-white'>unapologetic</span>
          <StarSymbol className='mx-2 w-10 h-10 fill-secondaryLilac' />
          <span className='marquee-item bg-white'>empowering</span>
          <StarSymbol className='mx-2 w-10 h-10 fill-secondaryLilac' />
          <span className='marquee-item text-white'>innovative</span>
        </div>
      </div>
    </section>
  )
}

export default Marquee
