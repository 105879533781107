import { useRef, useState, useEffect } from 'react'
import { IntlProvider } from 'react-intl'
import axios from 'axios'
import MainContext from './Main'
import German from '../lang/de.json'
import English from '../lang/en.json'
import French from '../lang/fr.json'
import Spanish from '../lang/es.json'
import { useNavigate } from 'react-router-dom'
import Platform from '../components/Platform/Platform'
import { checkVersionUrl } from '../components/Admin/Config/APIurls'
import ReactGA from 'react-ga4'
import { CookiesProvider, useCookies } from 'react-cookie'
const analyticsId = process.env.REACT_APP_ANALYTICS_ID

const MainProvider = () => {
  const location = window.location
  ReactGA.initialize(analyticsId)
  const navigate = useNavigate()
  const languages = [
    { id: 1, name: 'EN', value: 'en', available: true },
    { id: 2, name: 'DE', value: 'de', available: true },
    { id: 3, name: 'FR', value: 'fr', available: true },
    { id: 4, name: 'ES', value: 'es', available: true },
  ]
  const actualLocale = navigator.language === 'de-De' ? 'de' : 'en'
  let lang
  if (actualLocale.startsWith('en') && !lang) {
    lang = English
  } else {
    if (actualLocale.startsWith('de')) {
      lang = German
    } else {
      lang = English
    }
  }

  const [locale, setLocale] = useState(actualLocale)
  const [messages, setMessages] = useState(lang)
  const [menuToggle, setMenuToggle] = useState(false)
  const [reload, setReload] = useState(0)
  const [cookies, setCookie] = useCookies(['privacyConsent'])

  const formElement = useRef(null)
  useEffect(() => {
    //Check version and reload on new
    const checkVersion = async () => {
      const { data } = await axios.get(checkVersionUrl, {
        header: {
          'Content-Type': 'application/json',
        },
      })
      if (data.version !== process.env.REACT_APP_VERSION) {
        window.location.reload(true)
      }
    }
    checkVersion()
  }, [])
  const scrollToElement = (elem) => {
    const offset = 20
    const bodyRect = document.body.getBoundingClientRect().top
    const elementRect = elem.current.getBoundingClientRect().top
    const elementPosition = elementRect - bodyRect
    const offsetPosition = elementPosition - offset

    window.scrollTo({
      top: offsetPosition,
      behavior: 'smooth',
    })
  }

  const selectLanguage = (e) => {
    const newLocale = e
    setLocale(newLocale)
    switch (newLocale) {
      case 'en':
        setMessages(English)
        break
      case 'de':
        setMessages(German)
        break
      case 'fr':
        setMessages(French)
        break
      case 'es':
        setMessages(Spanish)
        break
      default:
        setMessages(English)
    }
  }
  const isInViewport = (element) => {
    const rect = element.getBoundingClientRect()
    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <=
        (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    )
  }

  useEffect(() => {
    if (cookies.privacyConsent) {
      ReactGA.send({
        hitType: 'pageview',
        page: location.pathname + location.search,
      })
    }
  }, [location.pathname, location.search])

  return (
    <MainContext.Provider
      value={{
        cookies,
        setCookie,
        navigate,
        locale,
        selectLanguage,
        languages,
        menuToggle,
        setMenuToggle,
        formElement,
        reload,
        setReload,
        scrollToElement,
        isInViewport,
      }}
    >
      <CookiesProvider>
        <IntlProvider messages={messages} locale={locale}>
          <Platform />
        </IntlProvider>
      </CookiesProvider>
    </MainContext.Provider>
  )
}

export default MainProvider
