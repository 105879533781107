import { Dialog } from '@headlessui/react'
import { Form, Formik } from 'formik'
import { FormattedMessage } from 'react-intl'
import TextInput from './components/TextInput'
import TextArea from './components/TextArea'
import CheckBox from './components/CheckBox'
import { Fragment } from 'react'
import { Transition } from '@headlessui/react'
import moment from 'moment'

const Attend = ({ event, attend, setAttend, confirmed, setConfirmed }) => {
  const initialValues = {
    firstName: '',
    lastName: '',
    pronouns: '',
    email: '',
    message: '',
    identityConfirmation: false,
  }
  const handleSubmit = (values) => {
    // Handle form submission here
    try {
      setAttend(false)
      setConfirmed(true)
    } catch (err) {
      console.log(err)
    }
  }

  const validateForm = (values) => {
    const errors = {}

    if (!values.firstName) {
      errors.firstName = 'First name is required'
    }

    if (!values.lastName) {
      errors.lastName = 'Last name is required'
    }

    if (!values.email) {
      errors.email = 'Email address is required'
    }

    return errors
  }
  return (
    <Transition appear show={attend} as={Fragment}>
      <Dialog
        className='relative z-40 flex justify-start flex-col items-center w-full transition-all duration-300 ease-in-out'
        as='div'
        open={attend}
        onClose={() => setAttend(false)}
      >
        <Transition.Child
          as={Fragment}
          enter='ease-out duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='fixed inset-0 bg-black bg-opacity-25' />
        </Transition.Child>
        <div className='fixed inset-y-32 h-fit overflow-y-auto max-w-2xl bg-white border border-black'>
          <div className='flex items-center justify-center text-center overflow-y-scroll'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 scale-95'
              enterTo='opacity-100 scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 scale-100'
              leaveTo='opacity-0 scale-95'
            >
              <Dialog.Panel className='text-gray-500 bg-inherit overflow-scroll px-4'>
                <div className=''>
                  <h2>
                    {confirmed ? (
                      <FormattedMessage
                        id='events.confirmed'
                        defaultMessage='Request Sent'
                      />
                    ) : (
                      <FormattedMessage
                        id='events.attend'
                        defaultMessage='I want to attend'
                      />
                    )}
                  </h2>
                  <h3 className='text-lg font-bold mb-2'>{event.title}</h3>
                  <div className='text-gray-500 text-sm mb-2'>
                    {moment(event.dateStart).format('DD MMMM YYYY, HH:mm')}
                  </div>
                </div>
                {!confirmed && (
                  <Formik
                    initialValues={initialValues}
                    onSubmit={handleSubmit}
                    validate={validateForm}
                  >
                    <Form className='pt-4'>
                      <div className='w-full mb-4 grid grid-cols-1 md:grid-cols-2 gap-4'>
                        <div className='relative w-full mb-4 flex flex-col justify-start items-start'>
                          <TextInput
                            id='firstName'
                            name='firstName'
                            label='First Name(s)'
                            type='text'
                            required={true}
                          />
                        </div>
                        <div className='relative w-full mb-4 flex flex-col justify-start items-start'>
                          <TextInput
                            id='lastName'
                            name='lastName'
                            label='Last Name(s)'
                            type='text'
                            required={true}
                          />
                        </div>
                      </div>
                      <div className='w-full mb-4 grid grid-cols-1 md:grid-cols-2 gap-4'>
                        {' '}
                        <div className='relative w-full mb-4 flex flex-col justify-start items-start'>
                          <TextInput
                            id='pronouns'
                            name='pronouns'
                            label='Pronouns'
                            type='text'
                            required={false}
                          />
                        </div>
                        <div className='relative w-full mb-4 flex flex-col justify-start items-start'>
                          <TextInput
                            id='email'
                            name='email'
                            label='Email'
                            type='email'
                            required={true}
                          />
                        </div>
                      </div>
                      <div className='w-full'>
                        <div className='relative w-full mb-4 flex flex-col justify-start items-start'>
                          <TextArea
                            id='message'
                            name='message'
                            label="Anything you'd like to share prior to the event?"
                            type='text'
                            required={false}
                          />
                        </div>
                      </div>
                      <div className='w-full'>
                        <div className='relative w-full mb-4 flex flex-col justify-start items-start'>
                          <CheckBox
                            id='identityConfirmation'
                            name='identityConfirmation'
                            type='checkbox'
                            required={true}
                            defaultLabel='I hereby confirm that I identify as either TIN*BIPOC or BIWOC (Trans*,
      inter*, non-binary Black, Indigenous, Person of Color OR Black,
      Indigenous, Women of Color) '
                          />
                        </div>
                      </div>
                      <button type='submit' className='button secondary'>
                        Submit
                      </button>
                    </Form>
                  </Formik>
                )}
                {confirmed && (
                  <div className='flex flex-col justify-center items-center'>
                    <h2 className='text-2xl font-bold mb-2'>
                      <FormattedMessage
                        id='events.confirmed'
                        defaultMessage='Thank You'
                      />
                    </h2>
                    <p className='text-lg'>
                      <FormattedMessage
                        id='events.confirmedMessage'
                        defaultMessage='We will be in touch with you shortly'
                      />
                    </p>
                    <button
                      className='button secondary small my-4'
                      onClick={() => setAttend(false)}
                    >
                      <FormattedMessage
                        id='events.close'
                        defaultMessage='Close'
                      />
                    </button>
                  </div>
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
}

export default Attend
