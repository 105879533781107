import { FormattedMessage } from 'react-intl'
import { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios'
import { getEventsUrl } from '../Config/APIurls'
import MainContext from '../../../contexts/Main'
import EventCard from './EventCard'
import Banner from '../components/Banner'
import PastEventCard from './PastEventCard'

const Events = () => {
  const [upcomingEvents, setUpcomingEvents] = useState([])
  const [events, setEvents] = useState(upcomingEvents)
  const { locale, config } = useContext(MainContext)

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const { data } = await axios.get(
          getEventsUrl + '?sortBy=dateStart:desc&limit=20',
          config
        )
        // set upcoming events
        setUpcomingEvents(
          data.results.filter((event) => new Date(event.dateStart) > new Date())
        )
        // set past events
        setEvents(
          data.results.filter((event) => new Date(event.dateStart) < new Date())
        )
      } catch (err) {
        console.log(err)
      }
    }

    fetchEvents()
  }, [])

  return (
    <>
      <Banner section='events' />
      <div className='flex flex-grow flex-col justify-start py-8 px-10 items-center space-y-8 min-h-[500px]'>
        <h2 className='w-full font-bold text-left'>
          <FormattedMessage
            id='events.upcoming'
            defaultMessage='Upcoming Events'
          />
        </h2>
        {upcomingEvents.length > 0 ? (
          upcomingEvents.map((event, i) => (
            <EventCard key={event.id} event={event} locale={locale} />
          ))
        ) : (
          <div className='flex-grow flex-col flex m-20'>
            <h4 className='text-center'>
              <FormattedMessage
                id='events.noevents'
                defaultMessage='No future events - Sign up to be the first to know when is our next event'
              />
            </h4>
            <Link
              to='/#join'
              className='button primary w-60 mx-auto mt-10 text-center'
            >
              <FormattedMessage id='app.signup' defaultMessage='Sign Up' />
            </Link>
          </div>
        )}
      </div>
      <div className='flex flex-grow flex-col justify-start py-8 px-10 items-center space-y-8 '>
        <h3 className='w-full font-bold text-left'>
          <FormattedMessage id='events.past' defaultMessage='Past Events' />
        </h3>
        <div className='w-full flex pl-8 space-x-4 overflow-x-scroll pb-6 '>
          {events.length > 0 ? (
            events.map((event) => (
              <PastEventCard key={event.id} event={event} locale={locale} />
            ))
          ) : (
            <h4 className='text-left'>
              <FormattedMessage
                id='events.nopastevents'
                defaultMessage='No past events - Sign up to be the first to know when is our next event'
              />
            </h4>
          )}
        </div>
      </div>
    </>
  )
}

export default Events
