import { Disclosure } from '@headlessui/react'
import { FormattedMessage } from 'react-intl'
import { ReactComponent as StarSymbol } from '../../../assets/elements/star.svg'

const InfoBox = ({ title, details, color }) => {
  return (
    <Disclosure>
      {({ open }) => (
        <>
          <Disclosure.Button
            disabled={details ? false : true}
            className={`w-full px-2 py-4 ${
              open ? 'border-black' : 'border-transparent'
            } group border-x border-t justify-start items-center gap-2.5 border-black inline-flex ${
              details ? 'cursor-pointer' : ''
            }`}
          >
            <StarSymbol className='w-6 h-6 fill-primaryLime group-hover:fill-primaryBlue stroke-black' />
            <span className='font-bold uppercase'>
              <FormattedMessage id={title} default='Info' />
            </span>
          </Disclosure.Button>
          <Disclosure.Panel
            className={`px-12 py-8 text-sm text-gray-500 ${
              open && 'border-b border-x border-black'
            }`}
          >
            <FormattedMessage id={details} default='Details' />
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  )
}

export default InfoBox
