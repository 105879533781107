const LinkPreview = ({ url }) => {
  return (
    <a
      href={url}
      target='_blank'
      rel='noreferrer'
      className='text-xs font-bold underline'
    >
      {url}
    </a>
  )
}

export default LinkPreview
