import React, { useContext } from 'react'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import Dropzone from '../Widgets/DropZone'
import AdminContext from '../../../contexts/Admin'
import Tags from '../Widgets/Tags'
import axios from 'axios'
import { createPostUrl, updatePostUrl } from '../Config/APIurls'
import Editor from './components/Editor'

const defaultSuggestionsTags = [
  'health',
  'growth',
  'education',
  'finance',
  'sport',
  'conference',
  'fundraiser',
  'masterclass',
]

const PostFormSchema = Yup.object().shape({
  title: Yup.string().required('Title is required'),
  author: Yup.object().shape({
    name: Yup.string().required('Author name is required'),
    link: Yup.string().url('Link must be a valid URL'),
    bio: Yup.string().nullable(),
    image: Yup.object().nullable(),
  }),
  image: Yup.object()
    .shape(
      {
        public_id: Yup.string(),
        url: Yup.string(),
        focus: Yup.string(),
      },
      'Please reupload a valid image'
    )
    .required('Image cover is required'),
  tags: Yup.array().of(Yup.string()).nullable(),
  content: Yup.string().required('Content is required'),
  seo: Yup.object().shape({
    description: Yup.string().nullable(),
    tags: Yup.string().nullable(),
  }),
})

const AddPost = ({ post, setEdit }) => {
  const {
    navigate,
    config,
    displayMainMessage,
    reload,
    setReload,
    checkAuthToken,
  } = useContext(AdminContext)

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      if (await !checkAuthToken()) {
        return navigate('/login')
      } else {
        let response
        if (values.id) {
          response = await axios.patch(
            updatePostUrl + values.id,
            values,
            config
          )
        } else {
          response = await axios.post(createPostUrl, values, config)
        }

        if (response.status === 201 || 200) {
          displayMainMessage(
            {
              success: 1,
              message: 'Post Saved',
              show: true,
            },
            3000
          )
          setSubmitting(false)
          setReload(reload + 1)
          if (values.id) {
            setEdit(false)
          } else {
            navigate('/admin/blog/' + response.data.id)
          }
        } else {
          displayMainMessage(
            {
              success: 0,
              message: 'Something went wrong',
              show: true,
            },
            4000
          )
          setSubmitting(false)
        }
      }
    } catch (error) {
      displayMainMessage(
        {
          error: 1,
          message: 'Something went wrong',
          show: true,
        },
        4000
      )
    }
  }
  return (
    <div className='w-full flex-col px-4 md:px-12'>
      <h2 className='text-3xl font-bold mb-4 uppercase'>Add new blog post</h2>
      <Formik
        initialValues={
          post || {
            id: null,
            title: '',
            author: {
              name: '',
              link: '',
              bio: '',
              image: null,
            },
            image: null,
            content: '',
            tags: [],
            seo: {
              description: '',
              tags: '',
            },
          }
        }
        validationSchema={PostFormSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, values }) => {
          return (
            <Form>
              <div className='form-section'>
                <div className='col-span-2'>
                  <label htmlFor='name' className='block font-bold mb-2'>
                    Title
                  </label>
                  <Field
                    type='text'
                    name='title'
                    id='title'
                    className='w-full border-gray-400 border-2 rounded-md p-2'
                  />
                  <ErrorMessage
                    name='title'
                    component='div'
                    className='text-red-500 text-sm mt-1'
                  />
                </div>
              </div>
              <div className='form-section'>
                <div className='form-item'>
                  <label className='block uppercase tracking-wide text-xs font-bold mb-2'>
                    Cover Image
                  </label>
                  <Dropzone
                    classes={`relative appearance-none outline-none outline-none block w-full border-2 border-gray-300 border-black border-dotted py-3 px-4 mb-3`}
                    type='image'
                    folder='blog'
                    edit={values.id ? true : false}
                  />
                  <ErrorMessage
                    name='image'
                    component='div'
                    className='text-red-500 text-sm mt-1'
                  />
                </div>
                <div className='form-item'>
                  <label className='block uppercase tracking-wide text-xs font-bold mb-2'>
                    Cover Point of focus
                  </label>

                  <Field
                    as='select'
                    name='image.focus'
                    id='image.focus'
                    className='w-full border-gray-400 border-2 rounded-md p-2'
                  >
                    <option value='left-top'>Top Left</option>
                    <option value='top'>Top Center</option>
                    <option value='right-top'>Top Right</option>
                    <option value='left'>Center Left</option>
                    <option value='center'>Center</option>
                    <option value='right'>Center Right</option>
                    <option value='left-bottom'>Bottom Left</option>
                    <option value='bottom'>Bottom Center</option>
                    <option value='right-bottom'>Bottom Right</option>
                  </Field>
                  <ErrorMessage
                    name='image.focus'
                    component='div'
                    className='text-red-500 text-sm mt-1'
                  />
                </div>
              </div>
              <div className='form-section'>
                <div className='col-span-2'>
                  <label className='block uppercase tracking-wide text-xs font-bold mb-2'>
                    Tags
                  </label>
                  <Tags
                    fieldName={'tags'}
                    defaultSuggestions={defaultSuggestionsTags}
                  />
                </div>
              </div>

              <div className='form-section'>
                <div className='col-span-2'>
                  <label htmlFor='content' className='block font-bold mb-2'>
                    Content
                  </label>
                  <Editor
                    name='content'
                    label='content'
                    id='content'
                    disable={true}
                    content={values.content}
                  />
                  <ErrorMessage
                    name='content'
                    component='div'
                    className='text-red-500 text-sm mt-1'
                  />
                </div>
              </div>
              <div className='form-section'>
                <div className='col-span-2'>
                  <label htmlFor='author' className='block font-bold text-xl'>
                    Author
                  </label>
                </div>
                <div className='form-item'>
                  <label htmlFor='author.name' className='block font-bold mb-2'>
                    Name
                  </label>
                  <Field
                    type='text'
                    name='author.name'
                    id='author.name'
                    className='w-full border-gray-400 border-2 rounded-md p-2'
                  />
                  <ErrorMessage
                    name='author.name'
                    component='div'
                    className='text-red-500 text-sm mt-1'
                  />
                </div>
                <div className='form-item'>
                  <label htmlFor='author.name' className='block font-bold mb-2'>
                    Pronouns
                  </label>
                  <Field
                    type='text'
                    name='author.pronouns'
                    id='author.pronouns'
                    className='w-full border-gray-400 border-2 rounded-md p-2'
                  />
                  <ErrorMessage
                    name='author.pronouns'
                    component='div'
                    className='text-red-500 text-sm mt-1'
                  />
                </div>
                <div className='col-span-2'>
                  <label htmlFor='author.link' className='block font-bold mb-2'>
                    Social Link
                  </label>
                  <Field
                    type='text'
                    name='author.link'
                    id='author.link'
                    className='w-full border-gray-400 border-2 rounded-md p-2'
                  />
                  <ErrorMessage
                    name='author.link'
                    component='div'
                    className='text-red-500 text-sm mt-1'
                  />
                </div>

                <div className='form-item'>
                  <label htmlFor='author.bio' className='block font-bold mb-2'>
                    Author Image
                  </label>
                  <Dropzone
                    classes={`relative appearance-none outline-none outline-none block w-full border-2 border-gray-300 border-black border-dotted py-3 px-4 mb-3`}
                    type='author.image'
                    folder='blog.author'
                    edit={values.id ? true : false}
                  />
                  <ErrorMessage
                    name='image'
                    component='div'
                    className='text-red-500 text-sm mt-1'
                  />
                </div>
                <div className='form-item'>
                  <label htmlFor='author.bio' className='block font-bold mb-2'>
                    Bio
                  </label>
                  <Field
                    as='textarea'
                    name='author.bio'
                    id='author.bio'
                    className='w-full border-gray-400 border-2 rounded-md p-2'
                  />
                  <ErrorMessage
                    name='author.bio'
                    component='div'
                    className='text-red-500 text-sm mt-1'
                  />
                </div>
              </div>
              <div className='form-section'>
                <div className='col-span-2'>
                  <label htmlFor='author' className='block font-bold text-xl'>
                    SEO
                  </label>
                </div>
                <div className='form-item'>
                  <label htmlFor='author.name' className='block font-bold mb-2'>
                    Description
                  </label>
                  <Field
                    type='text'
                    name='seo.description'
                    id='seo.description'
                    className='w-full border-gray-400 border-2 rounded-md p-2'
                  />
                  <ErrorMessage
                    name='seo.description'
                    component='div'
                    className='text-red-500 text-sm mt-1'
                  />
                </div>
                <div className='form-item'>
                  <label htmlFor='author.link' className='block font-bold mb-2'>
                    Tags (separated by comma)
                  </label>
                  <Field
                    type='text'
                    name='seo.tags'
                    id='seo.tags'
                    className='w-full border-gray-400 border-2 rounded-md p-2'
                  />
                  <ErrorMessage
                    name='seo.tags'
                    component='div'
                    className='text-red-500 text-sm mt-1'
                  />
                </div>
              </div>
              <div className='form-section flex-col-reverse'>
                <button
                  type='button'
                  disabled={isSubmitting}
                  className='button-secondary'
                  onClick={() => {
                    values.id ? setEdit(false) : navigate(-1)
                  }}
                >
                  cancel
                </button>
                <button
                  type='submit'
                  disabled={isSubmitting}
                  className='button-primary'
                >
                  {isSubmitting ? 'Submitting...' : 'Submit'}
                </button>
              </div>
            </Form>
          )
        }}
      </Formik>
    </div>
  )
}

export default AddPost
