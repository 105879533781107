import { Disclosure } from '@headlessui/react'
import { MinusIcon, PlusIcon } from '@heroicons/react/24/solid'
import { FormattedMessage } from 'react-intl'

const ImportantLinks = ({ data }) => {
  return (
    <>
      <Disclosure>
        {({ open }) => (
          <>
            <Disclosure.Button className='w-full h-28 px-24 py-8 bg-secondaryPink border-t border-black justify-between items-center gap-2.5 inline-flex'>
              <span className='font-bold uppercase'>
                <FormattedMessage
                  id='resources.importantLinks1'
                  default='Germany 101'
                />
              </span>
              {open ? (
                <MinusIcon className={`h-8 w-8 text-black`} />
              ) : (
                <PlusIcon className={`h-8 w-8 text-black`} />
              )}
            </Disclosure.Button>
            <Disclosure.Panel className='px-4 pb-8 text-sm text-black bg-secondaryPink'>
              <ul className='w-full flex flex-col space-y-2 px-20 list-inside list-disc'>
                <li>
                  <a
                    href='https://www.simplegermany.com/germany-101/'
                    target='_blank'
                    rel='noreferrer'
                    className='underline'
                  >
                    https://www.simplegermany.com/germany-101/
                  </a>
                </li>
                <li>
                  <a
                    href='https://www.iamexpat.de/'
                    target='_blank'
                    rel='noreferrer'
                    className='underline'
                  >
                    https://www.iamexpat.de/
                  </a>
                </li>
              </ul>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
      <Disclosure>
        {({ open }) => (
          <>
            <Disclosure.Button className='w-full h-28 px-24 py-8 bg-primaryLime border-t border-black justify-between items-center gap-2.5 inline-flex'>
              <span className='font-bold uppercase'>
                <FormattedMessage
                  id='resources.importantLinks2'
                  default='Legal'
                />
              </span>
              {open ? (
                <MinusIcon className={`h-8 w-8 text-black`} />
              ) : (
                <PlusIcon className={`h-8 w-8 text-black`} />
              )}
            </Disclosure.Button>
            <Disclosure.Panel className='px-4 pb-8 text-sm text-black bg-primaryLime'>
              <ul className='w-full flex flex-col space-y-2 px-20 list-inside list-disc'>
                <li>-</li>
              </ul>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
      <Disclosure>
        {({ open }) => (
          <>
            <Disclosure.Button className='w-full h-28 px-24 py-8 bg-secondaryLilac border-t border-black justify-between items-center gap-2.5 inline-flex'>
              <span className='font-bold uppercase'>
                <FormattedMessage
                  id='resources.importantLinks3'
                  default='Insurance'
                />
              </span>
              {open ? (
                <MinusIcon className={`h-8 w-8 text-black`} />
              ) : (
                <PlusIcon className={`h-8 w-8 text-black`} />
              )}
            </Disclosure.Button>
            <Disclosure.Panel className='px-4 pb-8 text-sm text-black bg-secondaryLilac'>
              <ul className='w-full flex flex-col space-y-2 px-20 list-inside list-disc'>
                <li>
                  <a
                    href='https://allaboutberlin.com/guides/insurance'
                    target='_blank'
                    rel='noreferrer'
                    className='underline'
                  >
                    These are the basic 8 insurances German’s love.
                  </a>
                </li>
                <li>
                  <a
                    href='https://www.simplegermany.com/insurance-in-germany/'
                    target='_blank'
                    rel='noreferrer'
                    className='underline'
                  >
                    Must-have vs nice-to-have insurances in Germany.
                  </a>
                </li>
                <li>
                  Our team can highly recommend working with the insurance
                  startup{' '}
                  <a
                    href='https://feather-insurance.com/'
                    target='_blank'
                    rel='noreferrer'
                    className='underline'
                  >
                    Feather
                  </a>
                </li>
              </ul>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </>
  )
}

export default ImportantLinks
