import { FormattedMessage } from 'react-intl'
import { useContext, useEffect, useRef, useState } from 'react'
import MainContext from '../../../contexts/Main'
import ImportantLinks from './ImportantLinks'
import SearchList from './Components/SearchLists'
import ResourcesList from './ResourcesList'
import SearchBar from '../components/SearchBar'
import { getResourcesUrl } from '../Config/APIurls'
import axios from 'axios'
import Loading from '../../Common/Widgets/Loading'
import InfoBox from '../../Common/Widgets/InfoBox'
import Banner from '../components/Banner'
import Marquee from '../components/Marquee'
import InstagramFeed from '../InstagramFeed/InstagramFeed'

const audiencesTerms = [
  {
    term: 'Black',
    values: [
      'Black entrepreneurs',
      'Black people',
      'Black womxn',
      'Black FLINTA',
      'Black investor',
    ],
  },
  { term: 'Muslim', values: 'Muslim FLINTA' },
  { term: 'PoC', values: ['PoC', 'Latinx', 'PoC womxn', 'FLINTA LATINX'] },
  {
    term: 'Queer / LGBTQIA+',
    values: ['LGBTQIA+', 'LGBTQIA+ YOUTH', 'Non-binary', 'Trans & inter'],
  },
  {
    term: 'Latin/a/o/x',
    values: ['Latinx', 'Latinx womxn', 'FLINTA LATINX', 'Spanish Speakers'],
  },
  {
    term: 'Migrant',
    values: [
      'Migrant organisations',
      'Migrant organisations by women',
      'Migrant womxn',
      'Migrants',
    ],
  },
  {
    term: 'Rom:nja und Sinti:zze',
    values: ['Rom:nja', 'Rom:nja und Sinti:zze'],
  },
  { term: 'People w/ Disability', values: ['People w/ Disability'] },
  { term: 'Undocumented Refugees', values: ['Undocumented refugees'] },
  { term: 'CIS-Women', values: ['Women', 'Women entrepreneurs'] },
  {
    term: 'TIN + all women',
    values: ['FLINTA', 'Non-binary', 'Trans & inter'],
  },
]
const industriesTerms = [
  { term: 'Creative', values: ['creativity', 'Creative industries'] },
  { term: 'Social', values: ['Social Business', 'Social Entrepreneurship'] },
  { term: 'Tech', values: ['Tech', 'Tech Entrepreneurship'] },
]

const Resources = () => {
  const { config, reload, scrollToElement } = useContext(MainContext)
  const [resourcesListData, setResourcesListData] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [loading, setLoading] = useState(true)
  const [search, setSearch] = useState('')
  const [filters, setFilters] = useState({
    audiences: {
      name: 'Audiences',
      search: [],
      show: false,
    },
    offers: {
      name: 'Offers',
      search: [],
      show: false,
    },
    states: {
      name: 'States',
      search: [],
      show: false,
    },
    industries: {
      name: 'Industry',
      search: [],
      show: false,
    },
  })
  const resourcesList = useRef(null)

  //clear filters
  const reset = () => {
    setSearch('')
    setFilters({
      audiences: {
        name: 'Audiences',
        search: [],
        show: false,
      },
      states: {
        name: 'States',
        search: [],
        show: false,
      },
      offers: {
        name: 'Offers',
        search: [],
        show: false,
      },
    })
  }

  // QUERY CONSTRUCT
  const constructApiQuery = (searchTerm) => {
    try {
      let query = ''
      // Check if the searchTerm is provided and append it to the query string

      // Initialize an empty array to store sanitized query parameters
      const sanitizedQueryParams = []
      // Go through filters
      for (const key of Object.keys(filters)) {
        if (filters[key].search.length > 0) {
          if (key === 'audiences') {
            // Audiences and Industries are a special case, as they are stored in a different format
            const audiencesQuery = audiencesTerms
              .filter((audienceTerm) =>
                filters.audiences.search.includes(audienceTerm.term)
              )
              .map((audienceTerm) => audienceTerm.values.toString())
              .join(',')

            if (audiencesQuery.length > 0) {
              sanitizedQueryParams.push(
                `audiences=${encodeURIComponent(audiencesQuery)}`
              )
            }
          } else if (key === 'industries') {
            const industriesQuery = industriesTerms
              .filter((industryTerm) =>
                filters.industries.search.includes(industryTerm.term)
              )
              .map((industryTerm) => industryTerm.values.toString())
              .join(',')

            if (industriesQuery.length > 0) {
              sanitizedQueryParams.push(
                `industries=${encodeURIComponent(industriesQuery)}`
              )
            }
          } else {
            sanitizedQueryParams.push(
              `${key}=${encodeURIComponent(filters[key].search.toString())}`
            )
          }
        }
      }
      if (searchTerm) {
        sanitizedQueryParams.push(`&name=${encodeURIComponent(searchTerm)}`)
      }
      query += sanitizedQueryParams.join('&')
      return query
    } catch (err) {
      console.log(err)
    }
  }

  //Get all resources and set the result as data
  useEffect(() => {
    const fetchData = async () => {
      try {
        const query = constructApiQuery(search)
          ? '?sortBy=name&page=' + currentPage + '&' + constructApiQuery(search)
          : '?sortBy=name&page=' + currentPage
        const response = await axios.get(getResourcesUrl + query, config)
        setResourcesListData(response.data)
        setCurrentPage(response.data.page)
        setLoading(false)
        scrollToElement(resourcesList)
      } catch (err) {}
    }
    setLoading(true)
    fetchData()
  }, [reload, currentPage, search])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const query = constructApiQuery(search)
          ? '?sortBy=name&page=1&' + constructApiQuery(search)
          : '?sortBy=name&page=1'
        const response = await axios.get(getResourcesUrl + query, config)
        setResourcesListData(response.data)
        setCurrentPage(response.data.page)
        setLoading(false)
        // scrollToElement(resourcesList)
      } catch (err) {}
    }
    setLoading(true)
    fetchData()
  }, [filters])

  return (
    <>
      <Banner section='resources' />
      <section id='resourcesList' className='p-10 border-b-0'>
        <InfoBox title='resources.welcome' details='resources.help' />
        <div className='my-2' ref={resourcesList}>
          <SearchBar
            search={search}
            setSearch={setSearch}
            location={'resources'}
          />
        </div>
        <div className='my-2'>
          <SearchList reset={reset} filters={filters} setFilters={setFilters} />
        </div>
        <div className='flex w-full min-h-[550px]'>
          {loading ? (
            <div className='border border-black flex flex-grow justify-center min-h-[550px]'>
              <Loading />
            </div>
          ) : (
            <ResourcesList
              data={resourcesListData}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          )}
        </div>
      </section>
      <section id='importantLinks' className='pt-4 overflow-hidden'>
        <div className='mx-4 p-4 flex flex-col justify-center items-start flex-grow '>
          <h2>
            <FormattedMessage
              id='resources.importantLinks'
              defaultMessage='Important Links'
            />
          </h2>
        </div>
        <ImportantLinks />
      </section>
    </>
  )
}

export default Resources
