import axios from 'axios'
import { useContext, useEffect, useState } from 'react'
import AdminContext from '../../../contexts/Admin'
import Tabs from '../Widgets/Tabs'
import { getUsersUrl } from '../Config/APIurls'
import Loading from '../../Common/Widgets/Loading'
import Profile from './Profile'
import Table from '../Widgets/Table'
import { UserPlusIcon } from '@heroicons/react/24/outline'
import UpdateList from './UpdateList'

const userHeaders = [
  {
    title: 'Name',
    key: 'name',
    style: 'w-2/5 text-center',
    rstyle:
      'w-full truncate whitespace-nowrap mx-auto py-1 px-4 text-sm text-center capitalize',
    type: 'text',
  },
  {
    title: 'Email',
    key: 'email',
    style: 'w-1/5 text-center',
    rstyle: 'w-full  whitespace-nowrap mx-auto py-1 px-4 text-sm text-center ',
    type: 'text',
  },
  {
    title: 'Role',
    key: 'role',
    style: 'w-1/5 text-center',
    rstyle:
      'w-full truncate whitespace-nowrap mx-auto py-1 px-4 text-sm text-center capitalize',
    type: 'text',
  },
  {
    title: '',
    key: '$edit',
    style: 'w-1/5 text-center',
    rstyle:
      'w-full truncate whitespace-nowrap mx-auto py-1 px-4 text-sm text-center capitalize',
    type: 'text',
  },
]
const Settings = () => {
  const {
    config,
    user,
    reload,
    checkAuthToken,
    navigate,
    selectedTab,
    currentParams,
    displayMainMessage,
  } = useContext(AdminContext)
  const [loading, setLoading] = useState(true)
  const [currentPage, setCurrentPage] = useState(1)
  const [data, setData] = useState({ results: [], totalPages: 0 })
  //Get all registered Users and set the result as data
  useEffect(() => {
    const fetchData = async () => {
      try {
        if (await !checkAuthToken()) return navigate('/login')
        if (selectedTab !== 1) {
          return setLoading(false)
        }
        const response = await axios.get(
          getUsersUrl + '?sortBy=name&limit=10&page=' + currentPage,
          config
        )
        const membersData = response.data.results.filter(
          (member) => member.id !== user.id
        )
        setData({
          results: [...membersData],
          totalPages: response.data.totalPages,
        })
        setLoading(false)
      } catch (err) {
        displayMainMessage(
          {
            error: 1,
            message: err.status
              ? err.response.message
              : 'Something went wrong getting the data - please try again',
            show: true,
          },
          5000
        )
      }
    }
    fetchData()
  }, [selectedTab, reload, currentPage])

  const tabs = [
    {
      index: 0,
      name: 'Profile',
      restricted: '',
    },
    {
      index: 1,
      name: 'Users',
      restricted: 'admin',
    },
  ]
  const { id } = currentParams
  return (
    <>
      <Tabs tabs={tabs} setData={setData} id={id} />
      <div className='relative h-full w-full flex px-4 md:px-8 items-center flex-col bg-white outline-none overflow-y-scroll'>
        {loading ? (
          <div className='w-full flex justify-center items-center'>
            <Loading />
          </div>
        ) : id || selectedTab === 0 ? (
          <Profile />
        ) : selectedTab === 1 ? (
          <>
            <button
              className='button-primary mr-auto my-4'
              onClick={() => navigate('settings/new')}
            >
              <UserPlusIcon className='h-5 w-5' />
              <span className='text-mono text-sm'>New user</span>
            </button>
            <Table
              title=''
              data={data.results}
              headers={userHeaders}
              pageCount={data.totalPages}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              link={'/admin/settings/'}
            />
          </>
        ) : (
          <UpdateList />
        )}
      </div>
    </>
  )
}

export default Settings
