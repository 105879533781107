import moment from 'moment'
import { useContext } from 'react'
import AdminContext from '../../../contexts/Admin'
import { EyeIcon } from '@heroicons/react/24/outline'

const PostCard = ({ post }) => {
  const { navigate } = useContext(AdminContext)

  return (
    <div
      className='relative w-96 h-96 p-4 bg-stone-50 border border-black flex-col justify-start items-start inline-flex cursor-pointer hover:shadow-lg transition-all duration-150 ease-in-out overflow-hidden'
      onClick={() => {
        navigate('/admin/blog/' + post.id)
      }}
    >
      <img
        className={`w-96 h-60 relative object-cover object-${post.image.focus}`}
        src={post.image ? post.image.url : '/images/placeholder.png'}
        alt='cover'
      />
      <div className='absolute bottom-0 left-0 w-full bg-black bg-opacity-50 flex justify-center items-center'>
        <div className='flex gap-2 text-white text-sm font-bold items-center'>
          <EyeIcon className='h-4 w-4' />
          {post.views}
        </div>
      </div>
      <div className='w-full h-32 flex-col justify-start items-start gap-4 flex'>
        <div className='w-full text-left py-2 text-black text-xl font-bold truncate overflow-clip'>
          {post.title}
        </div>
        <div className='w-full h-14 flex-col justify-start items-start gap-2 flex'>
          <div className='flex w-full justify-between items-start '>
            <div className='flex-grow text-left text-black text-xs font-normal leading-loose'>
              By {post.author.name}
            </div>
            <div className='text-right text-black text-xs font-normal leading-loose'>
              {moment(post.createdAt).format('MMM DD, YYYY')}
            </div>
          </div>
          <div className='justify-start items-start gap-2 inline-flex'>
            {post.tags.map((tag, i) => (
              <div
                key={tag + i}
                className='w-fit pl-1.5 pr-2 py-0.5 bg-purple-300 rounded-sm border border-violet-400 justify-center items-center gap-1.5 flex'
              >
                <div className='text-center text-indigo-500 text-xs font-medium capitalize leading-none'>
                  {tag}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default PostCard
