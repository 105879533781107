import { Listbox, Transition } from '@headlessui/react'
import { ChevronDownIcon, CheckIcon } from '@heroicons/react/24/outline'
import { Fragment, useState } from 'react'

const MultipleChoiceList = ({
  field,
  options,
  choices,
  setChoices,
  resetFilter,
}) => {
  const [show, setShow] = useState(false)
  return (
    <Listbox value={choices} as='div' className='relative z-40' multiple>
      {({ open }) => {
        if (open) setShow(true)
        return (
          <>
            <Listbox.Button
              className={`relative group w-40 ${
                open ? 'ring-1' : 'ring-0'
              } h-10 py-2 px-4 rounded-full items-center gap-2 inline-flex capitalize ${
                choices.length > 0
                  ? 'bg-black hover:ring-1 ring-black border border-black text-white justify-around'
                  : 'bg-zinc-950 hover:ring-1 ring-black border border-zinc-950 justify-start '
              }`}
              onClick={() => {
                if (!open) setShow(true)
              }}
            >
              <span
                className={`flex-grow text-left ${
                  choices.length > 0 ? 'text-stone-50' : 'text-black'
                } text-sm font-normal leading-tight`}
              >
                {field}
              </span>
              {choices.length > 0 && (
                <div className='h-6 px-2 p-1 mr-6 bg-secondaryBlue rounded-full border border-primaryBlue flex-col justify-center items-center gap-1 inline-flex'>
                  <span className='text-center text-primaryBlue text-xs font-normal leading-tight'>
                    {choices.length}
                  </span>
                </div>
              )}
              <span className='absolute inset-y-2 right-2 w-6 h-6 flex-col justify-center items-center inline-flex'>
                <ChevronDownIcon
                  className={`w-6 h-6 ${
                    choices.length > 0 ? ' text-white' : 'text-zinc-950'
                  }`}
                  aria-hidden='true'
                />
              </span>
            </Listbox.Button>
            {show && open ? (
              <Transition
                as={Fragment}
                leave='transition ease-in duration-100'
                leaveFrom='opacity-100'
                leaveTo='opacity-0'
              >
                <Listbox.Options
                  as='div'
                  className='absolute top-10 mt-2 w-fit overflow-y-scroll text-base bg-white shadow-lg border border-black truncate ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm'
                >
                  {options?.map((option, index) => (
                    <Listbox.Option
                      key={option}
                      className={`cursor-pointer text-gray-900 w-full select-none flex items-center relative p-1 pr-4`}
                      value={option}
                      onClick={() => setChoices(option)}
                    >
                      <>
                        {choices.includes(option) ? (
                          <div className=' w-9 h-9 justify-center items-center gap-2.5 inline-flex'>
                            <div className='relative w-4 h-4'>
                              <div className='w-4 h-4 bg-primaryBlue border border-stone-900' />
                              <CheckIcon className='w-4 h-4 absolute top-0 text-secondaryBlue' />
                            </div>
                          </div>
                        ) : (
                          <div className='w-9 h-9 justify-center items-center gap-2.5 inline-flex'>
                            <div className='w-4 h-4 bg-stone-50 border border-stone-900' />
                          </div>
                        )}
                        <div
                          className={`${
                            choices.includes(option) ? 'text-md' : 'font-normal'
                          } flex-grow`}
                        >
                          <span className='truncate'> {option}</span>
                        </div>
                      </>
                    </Listbox.Option>
                  ))}
                  <div className='flex justify-around w-full px-4 py-2'>
                    <button
                      className='rounded-full px-2 py-1 w-fit text-sm text-grotesk justify-center items-center cursor-pointer transition duration-150 ease-in hover:bg-secondaryBlue '
                      onClick={() => {
                        resetFilter(field)
                      }}
                    >
                      Reset
                    </button>
                  </div>
                </Listbox.Options>
              </Transition>
            ) : (
              ''
            )}
          </>
        )
      }}
    </Listbox>
  )
}

export default MultipleChoiceList
