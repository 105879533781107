import { useEffect, useState } from 'react'
import Modal from '../../Common/Widgets/Modal'
import ResourceCard from './Components/ResourceCard'
import Table from './Components/Table'

const resourceHeaders = [
  {
    title: 'Name',
    key: 'name',
    style: 'w-full lg:w-60 flex justify-center items-center cursor-pointer',
    rstyle: 'px-4 py-2 w-full text-center',
    type: 'text',
  },
  {
    title: 'Description',
    key: 'description',
    style: 'flex-grow justify-center items-center p-4',
    rstyle:
      'w-full lg:w-[550px] h-full whitespace-pre-wrap text-sm truncate p-4 cursor-pointer',
    type: 'text',
  },
  {
    title: 'link',
    key: 'link',
    style: 'w-full lg:w-20 flex justify-center items-center',
    rstyle: 'text-xs',
    type: 'text',
  },
  {
    title: 'Language',
    key: 'languages',
    style:
      'w-full lg:w-40 items-center justify-center p-4 flex flex-row flex-wrap lg:flex-col gap-1 cursor-pointer',
    rstyle:
      'w-24 text-xs pl-1.5 pr-2 py-0.5 border border-stone-900 justify-center items-center flex',
    type: 'array',
  },
]

const ResourcesList = ({ data, currentPage, setCurrentPage }) => {
  const [selectedId, setSelectedId] = useState(null)
  const [display, setDisplay] = useState(false)

  useEffect(() => {
    if (selectedId) setDisplay(true)
  }, [selectedId])
  useEffect(() => {
    if (!display) setSelectedId(null)
  }, [display])

  return (
    <>
      <Modal display={display} setDisplay={setDisplay}>
        <ResourceCard
          id={selectedId}
          display={display}
          setDisplay={setDisplay}
        />
      </Modal>
      <Table
        data={data.results}
        pageCount={data.totalPages}
        headers={resourceHeaders}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        link={setSelectedId}
      />
    </>
  )
}

export default ResourcesList
