import post1 from './post1610.webp'
import post2 from './post0610.webp'
import post3 from './post0210.webp'
import post4 from './post2909.webp'
import post5 from './post2809.webp'
import post6 from './post2709.webp'

export const instagramFeed = [
  {
    img: post1,
    caption: '#bravespace #biwocbusiness #Entrepreneurship #BIPOCEntrepreneurs',
    link: 'https://www.instagram.com/p/CydAHOaoemx/',
  },
  {
    img: post2,
    caption: '#bravespace #biwocbusiness #Entrepreneurship #BIPOCEntrepreneurs',
    link: 'https://www.instagram.com/p/CyDnoX4MfCf/',
  },
  {
    img: post3,
    caption: '#bravespace #biwocbusiness #Entrepreneurship #BIPOCEntrepreneurs',
    link: 'https://www.instagram.com/p/Cx4y9FnouL6/',
  },
  {
    img: post4,
    caption: '#bravespace #biwocbusiness #Entrepreneurship #BIPOCEntrepreneurs',
    link: 'https://www.instagram.com/p/CxyDszmo71W/',
  },
  {
    img: post5,
    caption: '#bravespace #biwocbusiness #Entrepreneurship #BIPOCEntrepreneurs',
    link: 'https://www.instagram.com/p/CxvrftToyaN/',
  },
  {
    img: post6,
    caption: '#bravespace #biwocbusiness #Entrepreneurship #BIPOCEntrepreneurs',
    link: 'https://www.instagram.com/p/CxtIEM9oeR_/',
  },
]
