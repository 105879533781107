import InfoBox from '../../Common/Widgets/InfoBox'
import Banner from '../components/Banner'
import FAQ from './FAQ'
import { Widget } from '@typeform/embed-react'
import '@typeform/embed/build/css/widget.css'
import ServiceProviders from './ServiceProviders'
import { useContext, useEffect, useRef, useState } from 'react'
import axios from 'axios'
import { getServiceProvidersUrl } from '../Config/APIurls'
import MainContext from '../../../contexts/Main'
import Loading from '../../Common/Widgets/Loading'
import Pagination from '../components/Pagination'
import SearchList from './Components/SearchLists'
import { FormattedMessage } from 'react-intl'

const OpenHours = () => {
  const [serviceProviders, setServiceProviders] = useState([])
  const [loading, setLoading] = useState(true)
  const { config, scrollToElement } = useContext(MainContext)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const serviceProvidersList = useRef(null)
  const [filters, setFilters] = useState({
    industry: {
      name: 'Industry',
      search: [],
      show: false,
    },
    services: {
      name: 'Services',
      search: [],
      show: false,
    },
    languages: {
      name: 'Languages',
      search: [],
      show: false,
    },
  })

  //clear filters
  const reset = () => {
    setFilters({
      industry: {
        name: 'Industries',
        search: [],
        show: false,
      },
      services: {
        name: 'Services',
        search: [],
        show: false,
      },
      languages: {
        name: 'Languages',
        search: [],
        show: false,
      },
    })
  }
  // QUERY CONSTRUCT
  const constructApiQuery = (searchTerm) => {
    try {
      let query = ''
      // Initialize an empty array to store sanitized query parameters
      const sanitizedQueryParams = []
      // Go through filters
      for (const key of Object.keys(filters)) {
        if (filters[key].search.length > 0) {
          sanitizedQueryParams.push(
            `${key}=${encodeURIComponent(filters[key].search.toString())}`
          )
        }
      }
      if (searchTerm) {
        sanitizedQueryParams.push(`&name=${encodeURIComponent(searchTerm)}`)
      }
      // Check if the searchTerm is provided and append it to the query string
      query += sanitizedQueryParams.join('&')
      return query
    } catch (err) {
      console.log(err)
    }
  }
  useEffect(() => {
    const fetchData = async () => {
      try {
        let query = ''
        if (constructApiQuery('')) {
          query =
            '?available=true&limit=4&sortBy=name&page=' +
            currentPage +
            '&' +
            constructApiQuery('')
        } else {
          query = '?available=true&limit=4&sortBy=name&page=' + currentPage
        }
        const { data } = await axios.get(getServiceProvidersUrl + query, config)
        setServiceProviders(data.results)
        setCurrentPage(data.page)
        setTotalPages(data.totalPages)
        setLoading(false)
      } catch (err) {}
    }
    fetchData()
  }, [currentPage, filters])

  useEffect(() => {
    if (serviceProvidersList.current) {
      scrollToElement(serviceProvidersList)
    }
  }, [currentPage])

  return (
    <>
      <Banner section='openHours' />
      <section id='openhours' className='px-10 pt-10 border-b-0'>
        <InfoBox title='openHours.welcome' details='openHours.help' />
      </section>
      <div className='w-full pl-10'>
        <h2 className='mb-0'>
          <FormattedMessage
            id='openHours.serviceProviders'
            default='Available Professionals'
          />
        </h2>
      </div>
      <div className='pl-10 my-2' ref={serviceProvidersList}>
        <SearchList reset={reset} filters={filters} setFilters={setFilters} />
      </div>
      <section id='serviceProviders' className='border-b-0 p-0'>
        {loading ? (
          <Loading />
        ) : (
          <ServiceProviders serviceProviders={serviceProviders} noBooking />
        )}

        {totalPages > 1 && (
          <div className='my-8'>
            <Pagination
              currentPage={currentPage}
              setPage={setCurrentPage}
              pageCount={totalPages}
            />
          </div>
        )}
      </section>
      <section id='form' className=' border border-x-0 min-h-[500px]'>
        <Widget id='wsvaxiHR' style={{ width: '100%', height: '650px' }} />
      </section>

      <div className='mx-4 p-4 flex flex-col justify-center items-start flex-grow '>
        <h2>FAQs</h2>
      </div>
      <FAQ />
    </>
  )
}

export default OpenHours
