import { FormattedMessage } from 'react-intl'
import Attend from './Attend'
import { useState } from 'react'
import moment from 'moment'
import { MapPinIcon } from '@heroicons/react/24/solid'
import { GlobeEuropeAfricaIcon } from '@heroicons/react/24/outline'

const EventCard = ({ event }) => {
  const [attend, setAttend] = useState(false)
  const [confirmed, setConfirmed] = useState(false)

  return (
    <>
      <div className='relative lg:mx-20 flex flex-col lg:flex-row bg-white border border-black  max-w-4xl p-5'>
        <img
          src={event.eventCover.url}
          alt={event.title}
          className='w-full lg:w-1/2 h-auto object-cover border border-black aspect-square'
        />
        {event.isCanceled && (
          <div className='w-26 h10 md:w-52 md:h-20 border-2 bg-white opacity-80 border-invalid rounded-lg absolute top-10 left-10 flex items-center justify-center space-x-2 '>
            <span className='mx-auto w-full p-4 text-sm md:text-xl text-center font-bold text-invalid whitespace-nowrap'>
              Cancelled
            </span>
          </div>
        )}
        <div className='w-full flex flex-col gap-2 my-2 lg:my-0 lg:ml-8'>
          <span className='text-xl md:text-2xl font-bold'>{event.title}</span>
          <div className='flex justify-between'>
            {event.location ? (
              <a
                href={`http://maps.google.com/?q=${
                  event.location + ',' + event.address + ',' + event.city
                }`}
                target='_blank'
                rel='noreferrer'
                className='flex gap-1 justify-center items-center hover:text-secondaryMagenta'
              >
                <MapPinIcon className='h-4 w-4 text-secondaryPink' />
                <div className='text-xs md:text-sm'>{event.location}</div>
              </a>
            ) : (
              <div className='flex justify-end gap-1 items-center'>
                <GlobeEuropeAfricaIcon className='h-4 w-4 text-secondaryPink' />{' '}
                <div className=' text-sm'>Online event</div>
              </div>
            )}
            <span className='font-bold text-xs md:text-sm text-right'>
              {moment(event.dateStart).format('DD MMMM YYYY')}
            </span>
          </div>
          <span className='flex-grow text-sm md:text-base'>
            {event.description}
          </span>
          <div className='flex justify-center'>
            {event.extLink && !confirmed ? (
              <a
                href={event.extLink || 'null'}
                rel='noreferrer'
                target='_blank'
                className='button small primary'
              >
                <FormattedMessage
                  id='events.button'
                  defaultMessage='I want to attend'
                />
              </a>
            ) : event.extLink && confirmed ? (
              <span className='button small primary'>
                <FormattedMessage
                  id='events.confirmed'
                  defaultMessage='Thank You'
                />
              </span>
            ) : (
              <button
                className={`button primary ${confirmed && ` small`}`}
                onClick={() => {
                  setAttend(true)
                }}
              >
                {confirmed ? (
                  <FormattedMessage
                    id='events.confirmed'
                    defaultMessage='Request Sent'
                  />
                ) : (
                  <FormattedMessage
                    id='events.attend'
                    defaultMessage='I want to attend'
                  />
                )}
              </button>
            )}
          </div>
        </div>
      </div>
      <Attend
        event={event}
        attend={attend}
        setAttend={setAttend}
        confirmed={confirmed}
        setConfirmed={setConfirmed}
      />
    </>
  )
}

export default EventCard
