import moment from 'moment'

const types = { feature: '✨', bug: '🐞', maintenance: '🛠' }

const UpdateDetails = ({ update }) => {
  return (
    <div className='w-full py-4 px-2'>
      <div className=' w-full overflow-hidden shadow-md text-center pb-2'>
        <div className='relative'>
          <span className='absolute top-2 left-2  inline-block bg-gray-200 rounded-full px-3 py-1 text-xs font-semibold text-gray-700'>
            {update.version}
          </span>
          <span className='absolute top-2 right-2 h-6 w-6 text-black group-hover:text-primaryBlue'>
            {types[update.type]}
          </span>
        </div>
        <div className='px-2 pb-2 pt-10'>
          <div
            className='px-2 overflow-none font-bold text-black text-base mb-2 font-mono'
            dangerouslySetInnerHTML={{ __html: update.details }}
          ></div>
          <div className='flex px-2 text-black text-xs md:text-sm text-mono w-full'>
            Published on {moment(update.date).format('DD/MM/YYYY')}
          </div>
        </div>
      </div>
    </div>
  )
}

export default UpdateDetails
