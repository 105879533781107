import axios from 'axios'
import { useState, useContext } from 'react'
import AdminContext from '../../../contexts/Admin'
import { deleteResourceUrl } from '../Config/APIurls'

const DeleteResource = ({ resource }) => {
  const [saving, setSaving] = useState(false)
  const {
    setShowModal,
    displayMainMessage,
    navigate,
    config,
    reload,
    setReload,
  } = useContext(AdminContext)
  const save = async () => {
    setSaving(true)
    try {
      const response = await axios.delete(
        deleteResourceUrl + resource.id,
        config
      )
      if (response.data || response.status === 204) {
        setSaving(false)
        setShowModal(false)
        displayMainMessage(
          {
            success: 1,
            message: 'Resource deleted! Redirecting..',
            show: true,
          },
          5000
        )
        setReload(reload + 1)
        navigate('/admin/resources')
      }
    } catch (e) {
      setSaving(false)
      setShowModal(false)
      displayMainMessage(
        {
          success: 0,
          message: 'An error occured while trying to delete this resource!',
          show: true,
        },
        5000
      )
    }
  }
  return (
    <div className='bg-white px-8 pt-8 pb-4 flex rounded flex-col w-full shadow-lg'>
      <div className='md:flex w-full px-3'>
        <div className='w-full mb-2 px-2 flex flex-col justify-center items-center'>
          <label className='block uppercase tracking-wide text-grey-darker text-md font-bold mb-2'>
            Are you sure you want to delete this resource
          </label>
        </div>
      </div>
      <div className='px-4 pt-6 flex flex-col-reverse sm:flex-row items-center justify-around '>
        <button
          className='w-40 button-secondary mt-4 sm:mt-0'
          onClick={() => {
            setShowModal(false)
          }}
        >
          Cancel
        </button>
        <button className='w-40 button-primary group' onClick={save}>
          {saving ? (
            <div className='flex justify-center'>
              <div
                style={{ borderTopColor: 'transparent' }}
                className='w-6 h-6 border-4 border-black group-hover:border-white border-dotted rounded-full animate-spin'
              ></div>
            </div>
          ) : (
            'Confirm'
          )}
        </button>
      </div>
    </div>
  )
}

export default DeleteResource
