import { PropTypes } from 'prop-types'
import { getTimeDifference } from '../../../Common/Utils/display'
import { LinkIcon } from '@heroicons/react/24/outline'

const Row = ({ item, headers, link }) => {
  // Construct based on type
  const designField = (field, i) => {
    if (headers[i].type === 'text') {
      if (headers[i].key === 'link') {
        return (
          <a
            href={field?.length ? field : '-'}
            target='_blank'
            rel='noreferrer'
          >
            <LinkIcon className='w-6 h-6 hover:text-primaryBlue' />
          </a>
        )
      }
      return <p className={headers[i].rstyle}>{field?.length ? field : '-'}</p>
    }
    if (headers[i].type === 'number') {
      return (
        <span className={headers[i].rstyle}>{field?.length ? field : '-'}</span>
      )
    }
    if (headers[i].type === 'date') {
      return <span>{getTimeDifference(field)}</span>
    }
    if (headers[i].type === 'image') {
      return <span>img</span>
    }
    if (headers[i].type === 'object') {
      const finalContent = []
      for (const item in field) {
        finalContent.push(
          <span
            key={field[item].value + Math.floor(Math.random() * 100)}
            className={
              field[item].style[field[item].value]
                ? field[item].style[field[item].value]
                : field[item].style
            }
          >
            {field[item].value}
          </span>
        )
      }
      return <div className='flex space-x-2 justify-center'>{finalContent}</div>
    }
    if (headers[i].type === 'array') {
      const arrayDisplay = field.map((item, e) => (
        <span className={`${headers[i].rstyle}`} key={item}>
          {item}
        </span>
      ))
      return <>{arrayDisplay}</>
    }
  }
  return (
    <div className='flex flex-col lg:flex-row w-full border-b border-grey-light hover:bg-gray-100 overflow-none'>
      {headers.map((header, i) => {
        return (
          <div
            key={header.key + item.id}
            className={header.style + (i === 0 ? ' font-bold' : '')}
            onClick={
              header.key === 'link'
                ? null
                : () => {
                    link(item.id)
                  }
            }
          >
            {designField(item[header.key], i)}
          </div>
        )
      })}
    </div>
  )
}

Row.propTypes = {
  name: PropTypes.string,
  item: PropTypes.object,
  headers: PropTypes.array,
}

export default Row
