import { PencilIcon, TrashIcon } from '@heroicons/react/24/outline'
import { useContext, useEffect, useState } from 'react'
import AdminContext from '../../../contexts/Admin'
import LinkPreview from '../Widgets/LinkPreview'
import Loading from '../../Common/Widgets/Loading'
import axios from 'axios'
import { getResourceUrl } from '../Config/APIurls'
import AddResource from './AddResource'
import ComponentModal from '../Widgets/ComponentModal'
import DeleteResource from './DeleteResource'
import Tag from '../../Platform/Resources/Components/Tag'

const styles = {
  industries: {
    tag: 'bg-secondaryPink border-pink-400',
    text: 'text-secondaryMagenta',
  },
  states: {
    tag: 'bg-secondaryLilac border-violet-400',
    text: 'text-primaryBlue',
  },
  type: {
    tag: 'bg-secondaryBlue border-sky-300',
    text: 'text-primaryBlue',
  },
  offers: {
    tag: 'bg-primaryBlue border-primaryBlue',
    text: 'text-white',
  },
  audiences: {
    tag: 'bg-primaryBlue border-primaryBlue',
    text: 'text-white',
  },
}

const Resource = () => {
  const [edit, setEdit] = useState(false)
  const [action, setAction] = useState(null)
  const { currentParams, displayMainMessage, config, user, setShowModal } =
    useContext(AdminContext)
  const [resource, setResource] = useState(null)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const { id } = currentParams

  useEffect(() => {
    const getResource = async () => {
      try {
        const { data } = await axios.get(getResourceUrl + id, config)
        if (!data) {
          // if the data is empty
          setError('No data found')
          setLoading(false)
          return
        }
        setResource(data)
        setLoading(false)
      } catch (err) {
        setError(
          'Error getting the data, please refresh the page and try again'
        )
        displayMainMessage(
          {
            error: 1,
            message: err.status
              ? err.response.message
              : 'Something went wrong while loading the widgets data',
            show: true,
          },
          5000
        )
      }
    }
    setLoading(true)
    getResource()
  }, [id])

  return loading ? (
    <Loading />
  ) : resource && !edit ? (
    <>
      <ComponentModal>
        <DeleteResource resource={resource} />
      </ComponentModal>
      <div className='relative self-center flex flex-col w-full xl:w-5/6 2xl:w-4/6 pb-6 shadow-lg border-0'>
        <div className='flex flex-col md:flex-row px-4 pt-2  w-full justify-between'>
          <div className='w-full mb-2 mx-auto'>
            <p className='text-xl xl:text-2xl 2xl:text-3xl text-mono tracking-wider self-center font-bold uppercase'>
              {resource.name}
            </p>
          </div>
        </div>

        <hr className={`mt-6 border-b-1 border-gray-400`} />
        <div className='w-full flex flex-col px-4 py-2'>
          <h6 className='text-gray-400 text-sm mt-3 font-bold uppercase'>
            Link
          </h6>
          <div className='px-3 bg-white text-base md:text-lg text-mono w-full ease-linear transition-all duration-150 text-justify'>
            <LinkPreview url={resource.link} />
          </div>
          <h6 className='text-gray-400 text-sm mt-3 font-bold uppercase'>
            Description
          </h6>
          <div className='px-3 bg-white text-base md:text-lg text-mono w-full ease-linear transition-all duration-150 text-justify'>
            {resource.description}
          </div>
          <h6 className='text-gray-400 text-sm mt-3 font-bold uppercase'>
            Location
          </h6>
          <div className='px-3 bg-white text-base md:text-lg text-mono w-full ease-linear transition-all duration-150 text-justify'>
            {resource.location ? resource.location : ' -'}
          </div>
          <h6 className='text-gray-400 text-sm mt-3 font-bold uppercase'>
            Type
          </h6>
          <div className='px-3 bg-white text-base md:text-lg text-mono w-full ease-linear transition-all duration-150 text-justify'>
            <div className='flex items-center py-1 space-x-2 overflow-x-scroll scrollbar scrollbar-thin scrollbar-thumb-secondaryPink scrollbar-track-green-100'>
              {resource.type.length ? (
                resource.type.map((tag) => (
                  <Tag key={tag + resource.id} tag={tag} style={styles.type} />
                ))
              ) : (
                <p className='text-xs ml-2'>No Types</p>
              )}
            </div>
          </div>
          <h6 className='text-gray-400 text-sm mt-3 font-bold uppercase'>
            Offers
          </h6>
          <div className='px-3 bg-white text-base md:text-lg text-mono w-full ease-linear transition-all duration-150 text-justify'>
            <div className='flex items-center py-1 space-x-2 overflow-x-scroll scrollbar scrollbar-thin scrollbar-thumb-secondaryPink scrollbar-track-green-100'>
              {resource.offers.length ? (
                resource.offers.map((tag) => (
                  <Tag
                    key={tag + resource.id}
                    tag={tag}
                    style={styles.offers}
                  />
                ))
              ) : (
                <p className='text-xs ml-2'>No offers</p>
              )}
            </div>
          </div>
          <h6 className='text-gray-400 text-sm mt-3 font-bold uppercase'>
            Audiences
          </h6>
          <div className='px-3 bg-white text-base md:text-lg text-mono w-full ease-linear transition-all duration-150 text-justify'>
            <div className='flex items-center py-1 space-x-2 overflow-x-scroll scrollbar scrollbar-thin scrollbar-thumb-secondaryPink scrollbar-track-green-100'>
              {resource.audiences.length ? (
                resource.audiences.map((tag) => (
                  <Tag
                    key={tag + resource.id}
                    tag={tag}
                    style={styles.audiences}
                  />
                ))
              ) : (
                <p className='text-xs ml-2'>No audiences</p>
              )}
            </div>
          </div>
          <h6 className='text-gray-400 text-sm mt-3 font-bold uppercase'>
            Not Audiences
          </h6>
          <div className='px-3 bg-white text-base md:text-lg text-mono w-full ease-linear transition-all duration-150 text-justify'>
            <div className='flex items-center py-1 space-x-2 overflow-x-scroll scrollbar scrollbar-thin scrollbar-thumb-secondaryPink scrollbar-track-green-100'>
              {resource.notAudiences.length ? (
                resource.notAudiences.map((tag) => (
                  <Tag
                    key={tag + resource.id}
                    tag={tag}
                    style={styles.notAudiences}
                  />
                ))
              ) : (
                <p className='text-xs ml-2'>No Not Audiences</p>
              )}
            </div>
          </div>
          <h6 className='text-gray-400 text-sm mt-3 font-bold uppercase'>
            Fields/Industries
          </h6>
          <div className='px-3 bg-white text-base md:text-lg text-mono w-full ease-linear transition-all duration-150 text-justify'>
            <div className='flex items-center py-1 space-x-2 overflow-x-scroll scrollbar scrollbar-thin scrollbar-thumb-secondaryPink scrollbar-track-green-100'>
              {resource.industries.length ? (
                resource.industries.map((tag) => (
                  <Tag
                    key={tag + resource.id}
                    tag={tag}
                    style={styles.industries}
                  />
                ))
              ) : (
                <p className='text-xs ml-2'>No fields/industries</p>
              )}
            </div>
          </div>
          <h6 className='text-gray-400 text-sm mt-3 font-bold uppercase'>
            Languages
          </h6>
          <div className='px-3 bg-white text-base md:text-lg text-mono w-full ease-linear transition-all duration-150 text-justify'>
            <div className='flex items-center py-1 space-x-2 overflow-x-scroll scrollbar scrollbar-thin scrollbar-thumb-secondaryPink scrollbar-track-green-100'>
              {resource.languages.length ? (
                resource.languages.map((tag) => (
                  <Tag key={tag + resource.id} tag={tag} />
                ))
              ) : (
                <p className='text-xs ml-2'>No languages</p>
              )}
            </div>
          </div>
          <h6 className='text-gray-400 text-sm mt-3 font-bold uppercase'>
            States
          </h6>
          <div className='px-3 bg-white text-base md:text-lg text-mono w-full ease-linear transition-all duration-150 text-justify'>
            <div className='flex items-center py-1 space-x-2 overflow-x-scroll scrollbar scrollbar-thin scrollbar-thumb-secondaryPink scrollbar-track-green-100'>
              {resource.states.length ? (
                resource.states.map((tag) => (
                  <Tag
                    key={tag + resource.id}
                    tag={tag}
                    style={styles.states}
                  />
                ))
              ) : (
                <p className='text-xs ml-2'>No States</p>
              )}
            </div>
          </div>
          <h6 className='text-gray-400 text-sm mt-3 font-bold uppercase'>
            Miscelaneous Tags
          </h6>
          <div className=' mt-2 pt-2 px-3 bg-white text-mono text-base w-full ease-linear transition-all duration-150 items-center'>
            <div className='flex items-center py-1 space-x-2 overflow-x-scroll scrollbar scrollbar-thin scrollbar-thumb-secondaryPink scrollbar-track-green-100'>
              {resource.tags.length ? (
                resource.tags.map((tag) => (
                  <Tag key={tag + resource.id} tag={tag} />
                ))
              ) : (
                <p className='text-xs ml-2'>No tags</p>
              )}
            </div>
          </div>
        </div>
        <div className=' w-full px-4 my-4 flex flex-col space-y-4 sm:space-y-0 sm:flex-row items-center justify-around '>
          {user.role.includes('admin') && (
            <>
              <button
                className='w-40 flex space-x-4 justify-center items-center button-secondary'
                onClick={() => {
                  setEdit(true)
                }}
              >
                <PencilIcon className='h-5 w-5' />
                <span>Edit</span>
              </button>

              <button
                className='w-40 flex space-x-4 justify-center items-center button-primary'
                onClick={() => {
                  setAction('delete')
                  setShowModal(true)
                }}
              >
                <TrashIcon className='h-5 w-5' />
                <span>Delete</span>
              </button>
            </>
          )}
        </div>
      </div>
    </>
  ) : resource && edit ? (
    <AddResource resource={resource} setEdit={setEdit} />
  ) : (
    <Loading />
  )
}

export default Resource
