const Impressum = () => {
  return (
    <div className='max-w-7xl border-black mx-auto mt-20 p-10'>
      <h1>Impressum</h1>
      <p>Brave Spaces 2.0 is a sister project of BIWOC*Rising & Founderland.</p>
      <section id='impressum'>
        <figure className='p-4'>
          <h2>BIWOC*Rising</h2>
          <p>
            <strong>Anschrift/Address:</strong> BIWOC Rising gUG
            (haftungsbeschränkt) Dresdener Str. 11 10999 Berlin Germany
          </p>
          <p>
            <strong>Email:</strong> info@biwoc-rising.org
          </p>
          <p>
            <strong>Registergericht/Register Court:</strong> Amtsgericht
            Charlottenburg{' '}
          </p>
          <p>
            <strong>Register-Nr./ Register no.:</strong> HRB 210944 B{' '}
          </p>
          <p>
            <strong>Steuer-Id./Tax Identification:</strong> 27/611/06515
          </p>
          <p>
            <strong>Geschäftsführerin/Managing Director:</strong> Loubna
            Messaoudi
          </p>
        </figure>
        <figure className='p-4'>
          <h2>Founderland</h2>
          <p>
            <strong>Email:</strong> hello@founderland.org{' '}
          </p>
          <p>
            <strong>Anschrift/Address:</strong> Founderland gUG
            (haftungsbeschränkt) c/o Factory Mitte HQ Rheinsberger Str. 76/77
            10115 Berlin
          </p>
          <p>
            <strong>Handelsregister/Commercial register:</strong> HRB 239168
          </p>
          <p>
            <strong>Registergericht/Register Court:</strong> District Court
            Berlin (Charlottenburg)
          </p>
          <p>
            <strong>Geschäftsführerinnen/Managing Directors:</strong> Alina
            Bassi, Deborah Choi, and Stephanie von Behr
          </p>
        </figure>
      </section>
      <figure>
        <p className='Footer-copy'>
          * Founderland & BIWOC*Rising do not take any responsibility for the
          content of linked pages.
        </p>
      </figure>
    </div>
  )
}

export default Impressum
