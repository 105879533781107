import React, { useState, useEffect } from 'react'
import { instagramFeed } from '../../../assets/images/instagram/feed'

const InstagramFeed = () => {
  // const [publications, setPublications] = useState([])

  // useEffect(() => {
  //   let isMounted = true // Flag to track component's mount status

  //   const fetchPublications = async () => {
  //     try {
  //       const response = await fetch(
  //         'https://graph.instagram.com/me/media?fields=id,media_type,media_url&access_token=YOUR_ACCESS_TOKEN'
  //       )
  //       const data = await response.json()

  //       if (isMounted && response.ok) {
  //         const lastFivePublications = data.data.slice(0, 5)
  //         setPublications(lastFivePublications)
  //       } else {
  //         console.error(
  //           'Error fetching Instagram publications:',
  //           data.error.message
  //         )
  //       }
  //     } catch (error) {
  //       console.error('Error fetching Instagram publications:', error.message)
  //     }
  //   }

  //   fetchPublications()

  //   return () => {
  //     isMounted = false // Clean up the flag when the component unmounts
  //   }
  // }, []) // The empty array ensures that this effect only runs on mount and unmount

  return (
    <div id='instagram' className='p-0 border-0 '>
      <div className='flex overflow-x-auto overflow-y-hidden border-0 p-0'>
        {instagramFeed.map((item, i) => (
          <a
            href={item.link}
            target='_blank'
            rel='noreferrer'
            key={i}
            className='w-52 h-52 lg:w-80 lg:h-80 aspect-square object-cover border-0 no-underline'
          >
            <img
              src={item.img}
              className='w-52 h-52 lg:w-80 lg:h-80 aspect-square border-l border-black object-cover'
              alt={item.caption}
            />
          </a>
        ))}
      </div>
    </div>
  )
}

export default InstagramFeed
