const Tag = ({ tag, style }) => {
  return (
    <div
      className={`max-w-fit max-h-6 whitespace-nowrap px-2 py-0.5 rounded-sm border justify-center items-center flex ${
        style?.tag ? style.tag : ' bg-gray-200 border-gray-400'
      } `}
    >
      <span
        className={`text-center text-xs font-medium leading-none ${
          style?.text ? style.text : 'text-gray-400'
        } `}
      >
        {tag}
      </span>
    </div>
  )
}

export default Tag
