import { useRef } from 'react'
import SunEditor from 'suneditor-react'
import 'suneditor/dist/css/suneditor.min.css'
import plugins from 'suneditor/src/plugins'
import { useField, useFormikContext } from 'formik'

const colors = [
  '#1F1F1F',
  '#6C6C6C',
  '#C7C7C7',
  '#E0FD8D',
  '#4F64EA',
  '#D6ECF7',
  '#D1C1FC',
  '#FFC1ED',
  '#DA5EB7',
  '#0063e2',
  '#f6331c',
  '#ee93b5',
  '#d7fb03',
  '#F9F9F9',
  '#ffffff',
  '#e60000',
  '#ff9900',
  '#ffff00',
  '#008a00',
  '#0066cc',
  '#9933ff',
  '#000000',
]

const template = `
<link href="https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&family=Poppins:wght@500;700&display=swap" rel="stylesheet"> 
<style type="text/css" scoped>
  body{margin:0; padding:0;}
  .flex{display:flex;}
  .flex-col{flex-direction:column;}
  .w-full{width:100%;}
  .padContent {padding: 2rem 3rem;}
  </style>
<div class="flex flex-col" style="place-items: center; padding: 0rem 10rem;">
<div class="flex w-full">
    <div class="w-full padContent">
      {{contents}}
    </div>
</div>
</div>`

const Editor = ({ label, name, disable, content }) => {
  const [field] = useField({ label, name })
  const { setFieldValue } = useFormikContext()

  const changeValue = (value) => {
    setFieldValue(name, value, true)
  }

  return (
    <div className='w-full'>
      <SunEditor
        showPathLabel={false}
        onChange={changeValue}
        defaultTag='div'
        plugins={plugins}
        disable={!disable}
        hideToolbar={!disable}
        defaultValue={field.value || content || ''}
        width='100%'
        height='50vh'
        setContents={field.value || content || ''}
        setOptions={{
          value: field.value,
          previewTemplate: template,
          mode: 'classic',
          font: ['Inter', 'Poppins', 'Roboto', 'Arial', 'Tahoma'],
          colorList: [[...colors]],
          linkTargetNewWindow: true,
          placeholder: 'Start writing something here...',
          buttonList: [
            [
              'undo',
              'font',
              'fontSize',
              'formatBlock',
              'paragraphStyle',
              'bold',
              'underline',
              'italic',
              'fontColor',
              'hiliteColor',
              'textStyle',
              'removeFormat',
              'outdent',
              'indent',
              'align',
              'horizontalRule',
              'list',
              'lineHeight',
              'link',
              'image',
              'fullScreen',
              'showBlocks',
              'codeView',
              'preview',
            ],
          ],
          resizingBar: false,
          charCounter: true,
          charCounterType: 'char',
          charCounterLabel: 'Characters',
          charCounterMax: 20000,
        }}
      />
    </div>
  )
}

export default Editor
