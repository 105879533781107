import { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'

const Modal = ({ children, display, setDisplay }) => {
  return (
    <Transition appear show={display} as={Fragment}>
      <Dialog
        as='div'
        onClose={() => setDisplay(false)}
        className='fixed z-50 inset-1 overflow-auto h-screen'
      >
        <div className='flex justify-center items-center h-screen'>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-100'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-75'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <Dialog.Overlay className='fixed inset-0 bg-clip-padding backdrop-filter backdrop-blur-sm bg-opacity-60' />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-100'
            enterFrom='opacity-0 scale-80'
            enterTo='opacity-100 scale-100'
            leave='ease-in duration-75'
            leaveFrom='opacity-100 scale-100'
            leaveTo='opacity-0 scale-80'
          >
            <div className='relative px-1 sm:px-4 md:px-6 lg:px-8 mb-2 w-full max-h-screen lg:w-2/3 xl:w-1/2 4xl:w-1/3 6xl:w-1/4 overflow-y-scroll'>
              {children}
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  )
}

export default Modal
