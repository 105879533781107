import SignUp from '../components/Platform/SignUp/SignUp'
import SignUpContext from './SignUp'

const SignUpProvider = () => {
  return (
    <SignUpContext.Provider value={{ test: 'Signup test' }}>
      <SignUp />
    </SignUpContext.Provider>
  )
}

export default SignUpProvider
