import { useContext } from 'react'
import AdminContext from '../../../contexts/Admin'
import { useState, useEffect } from 'react'
import axios from 'axios'
import { getMemberUrl } from '../Config/APIurls'
import Loading from '../../Common/Widgets/Loading'
import moment from 'moment'

const Member = () => {
  const { currentParams, displayMainMessage, config } = useContext(AdminContext)
  const [member, setMember] = useState(null)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const { id } = currentParams

  useEffect(() => {
    const getMember = async () => {
      try {
        const { data } = await axios.get(getMemberUrl + id, config)
        setMember(data)
        setLoading(false)
      } catch (err) {
        setError(
          'Error getting the data, please refresh the page and try again'
        )
        displayMainMessage(
          {
            error: 1,
            message: err.status
              ? err.response.message
              : 'Something went wrong while loading the widgets data',
            show: true,
          },
          5000
        )
      }
    }
    getMember()
  }, [id])

  const getLocale = (locale) => {
    switch (locale) {
      case 'en':
        return '🇬🇧'
      case 'es':
        return '🇪🇸'
      case 'fr':
        return '🇫🇷'
      case 'de':
        return '🇩🇪'
      default:
        return locale
    }
  }

  return loading ? (
    <Loading />
  ) : error ? (
    <div>{error}</div>
  ) : (
    <div className='w-full font-inter pb-20'>
      <div className='px-6 py-4'>
        <div className='flex flex-col space-y-0 items-start mb-4'>
          <span className='text-4xl font-bold'>
            {member.firstName} {member.lastName}
          </span>
          <span className='text-base capitalize font-bold'>
            {member.pronouns.length ? member.pronouns : '-'}
          </span>
        </div>
        <div className='flex flex-col mb-2'>
          <a
            href={`mailto:${member.email}`}
            className='text-lg text-primaryBlue font-bold'
          >
            {member.email}
          </a>
        </div>
        <div className='flex flex-col mb-2'>
          <span className='text-sm font-bold text-secondaryMagenta'>
            Location{' '}
          </span>
          <span className='text-lg capitalize'>{member.location}</span>
        </div>
        <div className='flex flex-col mb-2'>
          <span className='text-sm font-bold text-secondaryMagenta'>
            Identify As{' '}
          </span>
          <span className='text-lg capitalize'>
            {member.identifyAs.length ? member.identifyAs : '-'}
          </span>
        </div>
        {member.furtherIdentifyAs && (
          <div className='flex flex-col mb-2'>
            <span className='text-sm font-bold text-secondaryMagenta'>
              Further Identify As{' '}
            </span>
            <span className='text-lg capitalize'>
              {member.furtherIdentifyAs.length ? member.furtherIdentifyAs : '-'}
            </span>
          </div>
        )}
        <div className='flex flex-col mb-2'>
          <span className='text-sm font-bold text-secondaryMagenta'>
            Socials{' '}
          </span>
          <span className='text-lg capitalize'>
            {member.socials.length ? member.socials : '-'}
          </span>
        </div>
        <div className='flex flex-col mb-2'>
          <span className='text-sm font-bold text-secondaryMagenta'>
            Industry{' '}
          </span>
          <span className='text-lg capitalize'>
            {member.industry.length ? member.industry : '-'}
          </span>
        </div>
        <div className='flex flex-col mb-2'>
          <span className='text-sm font-bold text-secondaryMagenta'>Link </span>
          {member.link.length ? (
            <a
              href={member.link.length ? member.link : '#'}
              className='text-primaryBlue text-lg font-bold'
              target='_blank'
              rel='noopener noreferrer'
            >
              {member.link}
            </a>
          ) : (
            '-'
          )}
        </div>
        <div className='flex flex-col mb-2'>
          <span className='text-sm font-bold text-secondaryMagenta'>
            Language
          </span>
          <span className='text-xl capitalize'>
            {getLocale(member.selectedLocale)}
          </span>
        </div>
        <div className='flex flex-col mb-2'>
          <span className='text-sm font-bold text-secondaryMagenta'>
            Signed up On
          </span>
          <span className='text-lg capitalize'>
            {moment(member.createdAt).format('DD/MM/YYYY')}
          </span>
        </div>
      </div>
    </div>
  )
}

export default Member
