import { Listbox, Transition } from '@headlessui/react'
import { ChevronDownIcon, GlobeAltIcon } from '@heroicons/react/24/outline'
import { Fragment, useContext } from 'react'
import MainContext from '../../../contexts/Main'
const TranslateButton = () => {
  const { locale, selectLanguage, languages, cookies } = useContext(MainContext)

  return (
    <div
      className={`hidden lg:inline-block 2xl:hidden fixed ${
        cookies.privacyScreened ? 'bottom-0' : 'bottom-[58px]'
      } right-0 z-50`}
    >
      <Listbox value={locale} onChange={selectLanguage}>
        <div className='border border-black bg-white transition duration-200 flex items-center justify-center'>
          <Listbox.Button className='relative max-w-fit cursor-default text-left py-2.5 pl-2 pr-8 focus:outline-none '>
            <span className='relative block truncate'>
              <GlobeAltIcon className='h-8 w-8 text-gray-600' />
              <span className='sr-only'>Change language</span>
            </span>
            <span className='absolute inset-y-0 right-0 flex items-center pr-2'>
              <ChevronDownIcon
                className='h-5 w-5 text-black'
                aria-hidden='true'
              />
            </span>
          </Listbox.Button>
          <Transition
            as={Fragment}
            leave='transition ease-in duration-100'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <Listbox.Options className='absolute bottom-14 w-full max-h-40 overflow-auto bg-white text-base lg:shadow-lg'>
              {languages.map(
                (language) =>
                  language.available && (
                    <Listbox.Option
                      key={language.id}
                      value={language.value}
                      className={({ active }) =>
                        `cursor-default select-none p-2 text-center ${
                          active ? 'bg-primaryLime text-black' : 'text-gray-900'
                        }`
                      }
                    >
                      {({ selected }) => (
                        <span
                          className={`block ${
                            selected ? 'font-medium' : 'font-normal'
                          }`}
                        >
                          {language.name}
                        </span>
                      )}
                    </Listbox.Option>
                  )
              )}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
    </div>
  )
}

export default TranslateButton
