import { useContext } from 'react'
import MainContext from '../../../contexts/Main'
import { useState } from 'react'
import { Switch } from '@headlessui/react'
import { CheckIcon, XMarkIcon } from '@heroicons/react/24/outline'

const PrivacyPolicy = () => {
  const { cookies, setCookie } = useContext(MainContext)
  const [enabled, setEnabled] = useState(cookies.privacyConsent ? true : false)

  const acceptCookies = () => {
    setCookie('privacyConsent', true, { path: '/' })
    setCookie('privacyScreened', true, { path: '/' })
  }
  const declineCookies = () => {
    setCookie('privacyConsent', false, { path: '/' })
    setCookie('privacyScreened', true, { path: '/' })
  }
  const toggle = (e) => {
    if (e) {
      acceptCookies()
    } else {
      declineCookies()
    }
    setEnabled(e)
  }
  return (
    <>
      <section id='headline' className='p-4 '>
        <h1>Privacy Policy</h1>
      </section>
      <section id='privacy-en' className='p-4'>
        <p>
          Thank you for visiting our website. We take data protection and
          privacy very seriously. This page provides information on our digital
          presence and how it abides by both the German Federal Data Protection
          Act and the European General Data Protection Regulation (GDPR).
        </p>
        <p>In German further below.</p>
        <p>
          Responsible in terms of data protection laws, in particular the EU
          General Data Protection Regulation (DSGVO), is:{' '}
          <strong>Brave Spaces 2.0</strong>
        </p>
        <h3>Your rights</h3>
        <p>
          You can always exercise the following rights under the contact details
          of our data protection officer:
        </p>
        <ul className='list-disc list-inside px-10'>
          <li>
            Information about your stored data and their processing (Art. 15
            GDPR),
          </li>
          <li>Correction of incorrect personal data (Art. 16 GDPR),</li>
          <li>Deletion of your stored data (Art. 17 GDPR),</li>
          <li>
            Restriction of data processing, if we are not yet allowed to delete
            your data due to legal obligations (Art. 18 GDPR),
          </li>
          <li>
            Objection to the processing of your data by us (Article 21 GDPR) and
          </li>
          <li>
            Data portability, provided that you have consented to the data
            processing or have concluded a contract with us (Article 20 GDPR).
          </li>
        </ul>
        <p>
          If you have given us your consent, you can revoke it at any time with
          effect for the future.
        </p>
        <p>
          You can contact a regulatory authority at any time with a complaint,
          such as: to the competent supervisory authority of the federal state
          of your domicile or to the authority responsible for us as responsible
          authority.
        </p>
        <p>
          A list of the supervisory authorities (for the non-public area) with
          address can be found at:{' '}
          <a
            href='https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html'
            target='_blank'
            rel='noreferrer'
            className='underline'
          >
            https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html
          </a>
          .
        </p>
        <h3>Sharing of your information on the Community Platform:</h3>
        <h4>Within Brave Spaces 2.0:</h4>
        <p>
          The data you enter on your profile will be shared within our community
          members and accessible to any member that is registered. Community
          members may also see your activity when you interact with our products
          or their profile.
        </p>
        <h4>With Third Parties:</h4>
        <p>
          The data you enter may also be shared with third parties, this
          information is limited to the information you provide on your profile
          and is only for the purpose of future contacts, it does not include
          the activity on the Community platform.
        </p>
        <h3>Contact form</h3>
        <h4>Type and purpose of processing:</h4>
        <p>
          The data you enter will be stored for the purpose of individual
          communication with you. This requires a valid e-mail address and your
          name. This serves to assign the request and the subsequent answering
          of the same. The specification of further data is optional.
        </p>
        <h4>Legal basis:</h4>
        <p>
          The processing of the data entered into the contact form takes place
          on the basis of a legitimate interest (Article 6 (1) (f) DSGVO).
        </p>
        <p>
          By providing the contact form we would like to allow you an
          uncomplicated contact. Your information will be stored for the purpose
          of processing the request and for possible follow-up questions.
        </p>
        <p>
          If you contact us to inquire about an offer, the processing of the
          data entered in the contact form for the implementation of
          pre-contractual measures (Art. 6 (1) (b) GDPR) will take place.
        </p>
        <h4>Recipient:</h4>
        <p>The recipient of the data may be a processor.</p>
        <h4>Storage time:</h4>
        <p>
          Data will be deleted no later than 24 months after processing the
          request.
        </p>
        <p>
          If it comes to a contractual relationship, we are subject to the
          statutory retention periods according to HGB and delete your data
          after these deadlines.
        </p>
        <h4>Provision required:</h4>
        <p>
          The provision of your personal data is voluntary. However, we can only
          process your request if you provide us with your name, your e-mail
          address and the reason for the request.
        </p>
        <h4>External link:</h4>
        <p>
          On our website we offer you to connect with us via Facebook. When you
          visit Facebook via the provided link Facebook will collect your data
          as follows: Facebook privacy policy.
        </p>
        <h4>Third country transfer:</h4>
        <p>
          Google processes your data in the US and has submitted to the EU/US
          Privacy Shield{' '}
          <a
            href='https://www.privacyshield.gov/EU-US'
            className='underline'
            rel='noreferrer'
            target='_blank'
          >
            https://www.privacyshield.gov/EU-US
          </a>{' '}
          Framework.
        </p>
        <h4>Provision required:</h4>
        <p>
          The provision of your personal data is voluntary, solely on the basis
          of your consent. If you prevent access, this may result in functional
          restrictions on the website.
        </p>
        <h4>SSL encryption:</h4>
        <p>
          To protect the security of your data during transmission, we use
          state-of-the-art encryption techniques (such as SSL) over HTTPS.
        </p>
        <h4>Brave Spaces 2.0 Website Analysis and Further Development</h4>
        <p>
          In order to continuously optimize our service, we want to understand
          how users use our site. For this purpose, we collect and process the
          following technical data in particular: Device information (e.g.
          Information about the device, operating system and website version,
          language, time zone), the time when you open the Brave Spaces 2.0
          site, your behavior in the website , and duration of use or length of
          stay in certain functionalities.
        </p>
        <h3>Google Analytics</h3>
        <p>
          Our website uses Google Analytics, an analysis service from Google
          Inc. We analyze your usage behavior in our website in order to be able
          to improve our website and our offers on this basis.
        </p>
        <p>The following data is processed by Google Analytics:</p>
        <ul>
          <li>Anonymized IP address;</li>
          <li>
            Functions used or pages accessed (date, time, function/URL, length
            of stay);
          </li>
          <li>If necessary Achievement of certain goals (conversions);</li>
          <li>
            Technical information, including operating system, version and
            language; device type, brand, model;
          </li>
          <li>
            Approximate location (country and if necessary city, starting from
            an anonymized IP address).
          </li>
        </ul>
        <p>
          The personal data collected by Google Analytics is automatically
          deleted after 14 months. We have concluded an order processing
          contract with Google. Further information on these services can be
          found here:{' '}
          <a
            href='https://policies.google.com/technologies/partner-sites'
            rel='noreferrer'
            target='_blank'
            className='underline'
          >
            https://policies.google.com/technologies/partner-sites
          </a>
        </p>
        <p>
          The legal basis is article 6.1 (f) GDPR, based on our legitimate
          interest in analyzing errors in the website in order to fix them and
          improve the website as well as to analyze the use of the website in
          pseudonymous form in order to improve the website and our offers.
        </p>
        <h3>Hotjar</h3>
        <p>
          We use services provided by Hotjar Ltd. to better understand our
          website users’ needs and to optimize the user experience. For example,
          we may track how much time users spend on which pages, which links
          they choose to click, and so on. Hotjar uses cookies and other
          technologies to collect data on our users’ behavior and their devices.
          This includes, for example, a device’s IP address (processed during
          your session and stored in a de-identified form), device screen size,
          device type (device identifiers), browser information, and geographic
          location (country only). The service stores this information on our
          behalf in a pseudonymized user profile and does not sell the data
          collected on our behalf.
        </p>
        <p>
          The legal basis of our processing of your data by HotJar is your
          consent as per GDPR article 6.1(a) which we request via the cookie
          consent manager implemented on our website. If you consented to
          cookies set by Hotjar, you can withdraw your consent on their website
          at{' '}
          <a
            href='https://www.hotjar.com/privacy/do-not-track/'
            rel='noreferrer'
            target='_blank'
            className='underline'
          >
            https://www.hotjar.com/privacy/do-not-track/
          </a>{' '}
          or by adjusting your cookie settings either in the cookie consent
          manager implemented on our website or in your browser.
        </p>
        <h3>Opting Out</h3>
        <p>
          If you do not want any information to be collected and used by
          tracking technologies, you can visit the third-party vendor or the
          Network Advertising Initiative Opt-Out Tool (
          <a
            href='https://thenai.org/opt-out/'
            rel='noreferrer'
            target='_blank'
            className='underline'
          >
            https://thenai.org/opt-out/
          </a>
          ) or Digital Advertising Alliance Opt-Out Tool (
          <a
            href='https://youradchoices.com/control'
            rel='noreferrer'
            target='_blank'
            className='underline'
          >
            https://youradchoices.com/control
          </a>
          ).
        </p>
        <h3>Change of our privacy policy</h3>
        <p>
          We reserve the right to amend this privacy policy to always comply
          with the current legal requirements or to implement changes to our
          services in the privacy policy, e.g. when introducing new services.
          Your new visit will be subject to the new privacy policy.
        </p>
        <h3>Questions to the data protection officer</h3>
        <p>
          If you have any questions about privacy, please email us or contact
          the person responsible for data protection in our organization:{' '}
          <a href='mailto:hallo@bravespaces.de' className='underline'>
            hallo@bravespaces.de
          </a>
        </p>
      </section>
      <section id='privacy-de' className='p-4'>
        <div className='h-1 w-full bg-fdlpink'></div>
        <h2>Datenschutzerklärung</h2>
        <p>
          Verantwortlicher im Sinne der Datenschutzgesetze, insbesondere der
          EU-Datenschutzgrundverordnung (DSGVO), ist: Brave Spaces 2.0
        </p>
        <h3>Ihre Betroffenenrechte</h3>
        <ul className='list-disc list-inside px-10'>
          <li>
            Unter den angegebenen Kontaktdaten unseres Datenschutzbeauftragten
            können Sie jederzeit folgende Rechte ausüben:
          </li>
          <li>
            Auskunft über Ihre bei uns gespeicherten Daten und deren
            Verarbeitung (Art. 15 DSGVO),
          </li>
          <li>
            Berichtigung unrichtiger personenbezogener Daten (Art. 16 DSGVO),
          </li>
          <li>Löschung Ihrer bei uns gespeicherten Daten (Art. 17 DSGVO),</li>
          <li>
            Einschränkung der Datenverarbeitung, sofern wir Ihre Daten aufgrund
            gesetzlicher Pflichten noch nicht löschen dürfen (Art. 18 DSGVO),
          </li>
          <li>
            Widerspruch gegen die Verarbeitung Ihrer Daten bei uns (Art. 21
            DSGVO) und
          </li>
          <li>
            Datenübertragbarkeit, sofern Sie in die Datenverarbeitung
            eingewilligt haben oder einen Vertrag mit uns abgeschlossen haben
            (Art. 20 DSGVO).
          </li>
        </ul>
        <p>
          Sofern Sie uns eine Einwilligung erteilt haben, können Sie diese
          jederzeit mit Wirkung für die Zukunft widerrufen.
        </p>
        <p>
          Sie können sich jederzeit mit einer Beschwerde an eine
          Aufsichtsbehörde wenden, z. B. an die zuständige Aufsichtsbehörde des
          Bundeslands Ihres Wohnsitzes oder an die für uns als verantwortliche
          Stelle zuständige Behörde.
        </p>
        <p>
          Eine Liste der Aufsichtsbehörden (für den nichtöffentlichen Bereich)
          mit Anschrift finden Sie unter:{' '}
          <a
            href='https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html'
            className='underline'
            rel='noreferrer'
            target='_blank'
          >
            https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html
          </a>
          .
        </p>
        <h3>Weitergabe Ihrer Informationen auf der Community-Plattform:</h3>
        <h4>Innerhalb von Brave Spaces 2.0:</h4>
        <p>
          Die Daten, die Sie in Ihrem Profil angeben, werden innerhalb unserer
          Community-Mitglieder geteilt und sind für jedes registrierte Mitglied
          zugänglich. Die anderen Community-Mitglieder können Ihre Aktivitäten
          sehen, zum Beispiel wenn Sie mit deren Profilen oder unseren Produkten
          interagieren .
        </p>
        <h4>Von Dritten:</h4>
        <p>
          Die von Ihnen eingegebenen Daten können auch an Dritte weitergegeben
          werden. Die Weitergegebenen Informationen beschränken sich auf die
          Angaben, die Sie in Ihrem Profil machen, und dienen nur dem Zweck
          künftiger Kontakte, sie umfassen nicht die Weitergabe Ihrer
          Aktivitäten auf der Community-Plattform.
        </p>
        <h3>Kontaktformular</h3>
        <h4>Art und Zweck der Verarbeitung:</h4>
        <p>
          Die von Ihnen eingegebenen Daten werden zum Zweck der individuellen
          Kommunikation mit Ihnen gespeichert. Hierfür ist die Angabe einer
          validen E-Mail-Adresse sowie Ihres Namens erforderlich. Diese dient
          der Zuordnung der Anfrage und der anschließenden Beantwortung
          derselben. Die Angabe weiterer Daten ist optional.
        </p>
        <h4>Rechtsgrundlage:</h4>
        <p>
          Die Verarbeitung der in das Kontaktformular eingegebenen Daten erfolgt
          auf der Grundlage eines berechtigten Interesses (Art. 6 Abs. 1 lit. f
          DSGVO).
        </p>
        <p>
          Durch Bereitstellung des Kontaktformulars möchten wir Ihnen eine
          unkomplizierte Kontaktaufnahme ermöglichen. Ihre gemachten Angaben
          werden zum Zwecke der Bearbeitung der Anfrage sowie für mögliche
          Anschlussfragen gespeichert.
        </p>
        <p>
          Sofern Sie mit uns Kontakt aufnehmen, um ein Angebot zu erfragen,
          erfolgt die Verarbeitung der in das Kontaktformular eingegebenen Daten
          zur Durchführung vorvertraglicher Maßnahmen (Art. 6 Abs. 1 lit. b
          DSGVO).
        </p>
        <h4>Empfänger:</h4>
        <p>Empfänger der Daten sind ggf. Auftragsverarbeiter.</p>
        <h4>Speicherdauer:</h4>
        <p>
          Daten werden spätestens 24 Monate nach Bearbeitung der Anfrage
          gelöscht.
        </p>
        <p>
          Sofern es zu einem Vertragsverhältnis kommt, unterliegen wir den
          gesetzlichen Aufbewahrungsfristen nach HGB und löschen Ihre Daten nach
          Ablauf dieser Fristen.
        </p>
        <p>Bereitstellung vorgeschrieben oder erforderlich:</p>
        <p>
          Die Bereitstellung Ihrer personenbezogenen Daten erfolgt freiwillig.
          Wir können Ihre Anfrage jedoch nur bearbeiten, sofern Sie uns Ihren
          Namen, Ihre E-Mail-Adresse und den Grund der Anfrage mitteilen.
        </p>
        <h4>Externer Link:</h4>
        <p>
          Auf unserer Website bieten wir Ihnen die Möglichkeit, sich über
          Facebook mit uns zu verbinden. Wenn Sie Facebook über den angegebenen
          Link besuchen, erhebt Facebook Ihre Daten wie folgt:
          Facebook-Datenschutzerklärung.
        </p>
        <h4>Drittlandtransfer:</h4>
        <p>
          Google verarbeitet Ihre Daten in den USA und hat sich dem EU_US
          Privacy Shield unterworfen{' '}
          <a
            href='https://www.privacyshield.gov/EU-US-Framework'
            className='underline'
            rel='noreferrer'
            target='_blank'
          >
            https://www.privacyshield.gov/EU-US-Framework
          </a>
          .&#39;
        </p>
        <h4>Brave Spaces 2.0 Website Analyse und Weiterentwicklung</h4>
        <p>
          Um unseren Service fortlaufend zu optimieren, möchten wir verstehen,
          wie Nutzer unsere Website nutzen. Hierzu erheben und verarbeiten wir
          insbesondere die folgenden technischen Daten: Geräteinformationen
          (z.B. Informationen zum Gerät, Betriebssystem und zur Seite-Version,
          Sprache, Zeitzone), den Zeitpunkt, zu dem Sie die Brave Spaces 2.0
          Website öffnen, Ihr Verhalten in der Website , und Dauer der Nutzung
          bzw. Verweildauer in bestimmten Funktionalitäten.
        </p>
        <h3>Google Analytics</h3>
        <p>
          Unsere Website verwendet Google Analytics, einen Analysedienst von
          Google Inc. Dabei analysieren wir Ihr Nutzungsverhalten in unserer
          Seite, um auf dieser Basis unsere Website und unsere Angebote
          verbessern zu können.
        </p>
        <p>Folgende Daten werden von Google Analytics verarbeitet:</p>
        <p>- Anonymisierte IP-Adresse;</p>
        <p>
          - Verwendete Funktionen bzw. aufgerufene Seiten (Datum, Uhrzeit,
          Funktion/URL, Verweildauer);
        </p>
        <p>- ggf. Erreichung von bestimmten Zielen (Conversions);</p>
        <p>
          - Technische Informationen, einschließlich Betriebssystem, -version
          und –sprache; Gerätetyp, -marke, -modell;
        </p>
        <p>
          - Ungefährer Standort (Land und ggf. Stadt, ausgehend von
          anonymisierter IP-Adresse).
        </p>
        <p>
          Die von Google Analytics erhobenen personenbeziehbaren Daten werden
          nach 14 Monaten automatisch gelöscht. Wir haben mit Google einen
          Auftragsverarbeitungsvertrag abgeschlossen. Diensten finden Sie hier:{' '}
          <a
            href='https://policies.google.com/technologies/partner-sites'
            rel='noreferrer'
            target='_blank'
            className='underline'
          >
            https://policies.google.com/technologies/partner-sites
          </a>
        </p>
        <p>
          Rechtsgrundlage ist Art. 6 Absatz 1 lit. f DSGVO, beruhend auf unserem
          berechtigten Interesse, Fehler in der Website zu analysieren um diese
          zu beheben und die Website zu verbessern sowie die Nutzung der Website
          in pseudonymer Form zu analysieren, um die Website und unsere Angebote
          zu verbessern.
        </p>
        <h3>Hotjar</h3>
        <p>
          Wir nutzen die von Hotjar Ltd. bereitgestellten Dienste, um die
          Bedürfnisse der Nutzer unserer Website besser zu verstehen und die
          Nutzererfahrung zu optimieren. Wir können zum Beispiel verfolgen, wie
          viel Zeit die Nutzer auf welchen Seiten verbringen, welche Links sie
          anklicken und so weiter.Hotjar verwendet Cookies und andere
          Technologien, um Daten über das Verhalten unserer Nutzer und deren
          Geräte zu sammeln. Dazu gehören zum Beispiel die IP-Adresse eines
          Geräts (die während Ihrer Sitzung verarbeitet und in anonymisierter
          Form gespeichert wird), die Bildschirmgröße des Geräts, der Gerätetyp
          (Gerätekennungen), Browserinformationen und der geografische Standort
          (nur Land). Der Dienst speichert diese Informationen in unserem Namen
          in einem pseudonymisierten Nutzerprofil und verkauft die in unserem
          Namen erhobenen Daten nicht.
        </p>
        <p>
          Die Rechtsgrundlage für die Verarbeitung Ihrer Daten durch HotJar ist
          Ihre Einwilligung gemäß Artikel 6.1(a) der DSGVO, die wir über den auf
          unserer Website implementierten Cookie-Zustimmungsmanager einholen.
          Wenn Sie dem Setzen von Cookies durch Hotjar zugestimmt haben, können
          Sie Ihre Zustimmung auf der Website{' '}
          <a
            href='https://www.hotjar.com/privacy/do-not-track/'
            className='underline'
            rel='noreferrer'
            target='_blank'
          >
            https://www.hotjar.com/privacy/do-not-track/
          </a>
          widerrufen oder Ihre Cookie-Einstellungen entweder über den auf
          unserer Website implementierten Cookie-Zustimmungsmanager oder in
          Ihrem Browser anpassen.
        </p>
        <h3>Opt-out</h3>
        <p>
          Wenn Sie nicht möchten, dass Informationen durch Tracking-Technologien
          erfasst und verwendet werden, können Sie den Drittanbieter oder das
          Opt-Out-Tool der Network Advertising Initiative (
          <a
            href='https://thenai.org/opt-out/'
            rel='noreferrer'
            target='_blank'
            className='underline'
          >
            https://thenai.org/opt-out/
          </a>
          ) oder das Opt-Out-Tool der Digital Advertising Alliance (
          <a
            href='https://youradchoices.com/control'
            rel='noreferrer'
            target='_blank'
            className='underline'
          >
            https://youradchoices.com/control
          </a>
          ) besuchen
        </p>
        <h3>Bereitstellung vorgeschrieben oder erforderlich:</h3>
        <p>
          Die Bereitstellung Ihrer personenbezogenen Daten erfolgt freiwillig,
          allein auf Basis Ihrer Einwilligung. Sofern Sie den Zugriff
          unterbinden, kann es hierdurch zu Funktionseinschränkungen auf der
          Website kommen.
        </p>
        <p>SSL-Verschlüsselung</p>
        <p>
          Um die Sicherheit Ihrer Daten bei der Übertragung zu schützen,
          verwenden wir dem aktuellen Stand der Technik entsprechende
          Verschlüsselungsverfahren (z. B. SSL) über HTTPS.
        </p>
        <p>Änderung unserer Datenschutzbestimmungen</p>
        <p>
          Wir behalten uns vor, diese Datenschutzerklärung anzupassen, damit sie
          stets den aktuellen rechtlichen Anforderungen entspricht oder um
          Änderungen unserer Leistungen in der Datenschutzerklärung umzusetzen,
          z.B. bei der Einführung neuer Services. Für Ihren erneuten Besuch gilt
          dann die neue Datenschutzerklärung.
        </p>
        <p>Fragen an den Datenschutzbeauftragten</p>
        <p>
          Wenn Sie Fragen zum Datenschutz haben, schreiben Sie uns bitte eine
          E-Mail oder wenden Sie sich direkt an die für den Datenschutz
          verantwortliche Person in unserer Organisation:{' '}
          <a href='mailto:hallo@bavespaces.de'>hallo@bravespaces.de</a>
        </p>
      </section>
      <section className='p-8'>
        <div className='w-full '>
          <h1>Privacy Consent</h1>
          <div className='w-full flex flex-row-reverse justify-end items-center gap-6 p-6'>
            <span>
              I accept the use of cookies to help improve the user experience of
              the website.
            </span>
            <Switch
              checked={enabled}
              onChange={toggle}
              className={`${enabled ? 'bg-primaryLime' : 'bg-gray-400'}
          relative inline-flex h-[38px] w-[74px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
            >
              <span className='sr-only'>Use setting</span>
              <span
                aria-hidden='true'
                className={`${enabled ? 'translate-x-9' : 'translate-x-0'}
            flex items-center justify-center pointer-events-none h-[34px] w-[34px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
              >
                {enabled ? (
                  <CheckIcon className='h-6 w-6' />
                ) : (
                  <XMarkIcon className='w-6 h-6' />
                )}
              </span>
            </Switch>
          </div>
        </div>
      </section>
    </>
  )
}

export default PrivacyPolicy
