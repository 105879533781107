import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from 'use-places-autocomplete'
import {
  Combobox,
  ComboboxInput,
  ComboboxPopover,
  ComboboxList,
  ComboboxOption,
} from '@reach/combobox'
import { useState } from 'react'

import '@reach/combobox/styles.css'
import { useFormikContext } from 'formik'

const Places = () => {
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete()
  const { values, setFieldValue } = useFormikContext()
  const [selected, setSelected] = useState(values.address ? true : false)
  const handleInput = (e) => {
    setSelected(false)
    setValue(e.target.value)
  }

  const handleSelect = (val) => {
    setSelected(true)
    const [completeData] = data.filter((item) => item.description === val)
    setValue(val, false)
    if (
      /route|stree_address|premise|neighborhood|intersection/.test(
        completeData?.types.join('|')
      )
    ) {
      setFieldValue('address', completeData.structured_formatting.main_text)
      setFieldValue('city', completeData.structured_formatting.secondary_text)
      setFieldValue('zoom', 16)
    } else if (/locality/.test(completeData?.types.join('|'))) {
      setFieldValue('address', '')
      setFieldValue('city', completeData.terms[0].value)
      setFieldValue('zoom', 14)
    } else if (/point_of_interest/.test(completeData?.types.join('|'))) {
      setFieldValue('location', completeData.terms[0].value)
      setFieldValue('zoom', 14)
      const address =
        completeData.structured_formatting.secondary_text.split(', ')
      setFieldValue('address', address[0])
      setFieldValue('city', address[1])
    } else {
      setFieldValue('zoom', 14)
      const address =
        completeData.structured_formatting.secondary_text.split(', ')
      setFieldValue('address', address[0])
      setFieldValue('city', address[1])
    }
    clearSuggestions()
    getGeocode({ address: val })
      .then((results) => getLatLng(results[0]))
      .then(({ lat, lng }) => {
        setFieldValue('geoLocation', { lat, lng })
      })
      .catch((error) => {})
  }
  return (
    <Combobox onSelect={handleSelect}>
      <ComboboxInput
        className='appearance-none pl-4 block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-3 pr-4 mb-3'
        value={selected ? values.address : value}
        onChange={handleInput}
        disabled={!ready}
        placeholder='Start typing an address here...'
      />
      <ComboboxPopover>
        <ComboboxList>
          {status === 'OK' &&
            data.map(({ place_id, description }) => (
              <ComboboxOption key={place_id} value={description} />
            ))}
        </ComboboxList>
      </ComboboxPopover>
    </Combobox>
  )
}

export default Places
