import Banner from '../components/Banner'
import { useContext, useEffect, useRef, useState } from 'react'
import axios from 'axios'
import { getBlogPostsUrl } from '../Config/APIurls'
import MainContext from '../../../contexts/Main'
import Loading from '../../Common/Widgets/Loading'
import PostsList from './PostsList'
import { useParams } from 'react-router-dom'
import Post from './Post'
import InfoBox from '../../Common/Widgets/InfoBox'

const Blog = () => {
  const [posts, setPosts] = useState({ results: [] })
  const [allPosts, setAllPosts] = useState({ results: [] })
  const [loading, setLoading] = useState(true)
  const { config, scrollToElement } = useContext(MainContext)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const blogPosts = useRef(null)
  const [filters, setFilters] = useState({})
  const { id } = useParams()

  //clear filters
  const reset = () => {
    setFilters({})
  }
  // QUERY CONSTRUCT
  const constructApiQuery = (searchTerm) => {
    try {
      let query = ''
      // Initialize an empty array to store sanitized query parameters
      const sanitizedQueryParams = []
      // Go through filters
      for (const key of Object.keys(filters)) {
        if (filters[key].search.length > 0) {
          sanitizedQueryParams.push(
            `${key}=${encodeURIComponent(filters[key].search.toString())}`
          )
        }
      }
      if (searchTerm) {
        sanitizedQueryParams.push(`&name=${encodeURIComponent(searchTerm)}`)
      }
      // Check if the searchTerm is provided and append it to the query string
      query += sanitizedQueryParams.join('&')
      return query
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          getBlogPostsUrl +
            '?sortBy=createdAt:desc&limit=4&page=' +
            currentPage,
          config
        )
        setPosts(response.data)
        setCurrentPage(response.data.page)
        setTotalPages(response.data.totalPages)
      } catch (err) {
        console.log(err)
      }
    }
    fetchData()
  }, [currentPage])

  //GET ALL POSTS
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          getBlogPostsUrl + '?sortBy=createdAt:desc&limit=1000',
          config
        )
        setAllPosts(response.data)
        setLoading(false)
      } catch (err) {
        console.log(err)
      }
    }
    setLoading(true)
    fetchData()
  }, [])

  useEffect(() => {
    if (blogPosts.current) {
      scrollToElement(blogPosts)
    }
  }, [currentPage])

  return (
    <>
      <Banner section='blog' />
      {!id && (
        <section id='blog' className='px-10 pt-10 border-b-0'>
          <InfoBox title='blog.welcome' />
        </section>
      )}
      <section id='blogPosts' className='border-b-0 p-0 mt-8' ref={blogPosts}>
        {loading ? (
          <Loading />
        ) : id ? (
          <Post
            post={allPosts?.results.filter((post) => post.id === id)[0]}
            posts={allPosts.results}
          />
        ) : (
          <PostsList
            posts={posts}
            totalPages={totalPages}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        )}
      </section>
    </>
  )
}

export default Blog
