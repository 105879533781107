import { useContext } from 'react'
import AdminContext from '../../../contexts/Admin'
import Event from './Event'
import EventsList from './EventsList'

const Events = () => {
  const { currentParams } = useContext(AdminContext)
  const { id } = currentParams

  return (
    <div className='relative h-full w-full flex px-4 md:px-8 items-center flex-col bg-white outline-none overflow-y-scroll'>
      {!id ? <EventsList /> : <Event />}
    </div>
  )
}

export default Events
