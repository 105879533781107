import { FormattedMessage } from 'react-intl'
import { ReactComponent as FDLLogo } from '../../assets/logos/2_lines.svg'
import { ReactComponent as BiwocBW } from '../../assets/logos/biwocBW.svg'
import { Link } from 'react-router-dom'

const Footer = () => {
  return (
    <footer id='footer'>
      <div className='w-full 2xl:max-w-6xl p-2 lg:mx-auto text-white'>
        <div className='Footer-main'>
          <div className='Footer-nav'>
            <div className='Footer-nav-group'>
              <div className='Footer-nav-heading'>
                <FormattedMessage id='app.about' default='About Us' />
              </div>
              <Link to='/#about' className='Footer-nav-item'>
                <span>
                  <FormattedMessage
                    id='app.aboutus'
                    defaultMessage='About Us'
                  />
                </span>
              </Link>
              <Link to='/#team' className='Footer-nav-item'>
                <FormattedMessage
                  id='app.teamTitle'
                  defaultMessage='Meet the Team'
                />
              </Link>
              <a
                href='mailto:hallo@bravespaces.de'
                target='_blank'
                className='Footer-nav-item'
                rel='noreferrer'
              >
                <FormattedMessage
                  id='app.contact'
                  defaultMessage='Contact Us'
                />
              </a>
              <Link to='/impressum' className='Footer-nav-item'>
                <FormattedMessage
                  id='app.impressum'
                  defaultMessage='Impressum'
                />
              </Link>
              <Link to='/privacy-policy' className='Footer-nav-item'>
                <FormattedMessage
                  id='app.privacy'
                  defaultMessage='Privacy Policy'
                />
              </Link>
              <Link to='/#join' className='Footer-nav-item'>
                <span>
                  <FormattedMessage id='app.signup' defaultMessage='Sign Up' />
                </span>
              </Link>
            </div>
            <div className='Footer-nav-group'>
              <div className='Footer-nav-heading'>
                <FormattedMessage id='app.resources' default='Resources' />
              </div>
              <Link to='/events' className='Footer-nav-item'>
                <FormattedMessage id='app.events' defaultMessage='Events' />
              </Link>
              <Link to='/resources' className='Footer-nav-item'>
                <FormattedMessage
                  id='app.resources'
                  defaultMessage='Resources'
                />
              </Link>
              <Link to='/blog' className='Footer-nav-item'>
                <FormattedMessage id='app.blog' defaultMessage='Blog' />
              </Link>
              <Link to='/open-hours' className='Footer-nav-item'>
                <FormattedMessage
                  id='app.openHours'
                  defaultMessage='Open Hours'
                />
              </Link>
            </div>
            <div className='Footer-nav-group'>
              <div className='Footer-nav-heading'>
                <FormattedMessage id='app.findUs' default='Find Us' />
              </div>
              <a
                href='https://www.eventbrite.de/o/brave-spaces-20-64624123293'
                target='_blank'
                className='Footer-nav-item'
                rel='noreferrer'
              >
                eventbrite
              </a>
              <a
                href='https://www.instagram.com/bravespaces2.0/'
                target='_blank'
                className='Footer-nav-item'
                rel='noreferrer'
              >
                Instagram
              </a>
              <a
                href='https://www.linkedin.com/company/brave-spaces-2point0/'
                target='_blank'
                className='Footer-nav-item'
                rel='noreferrer'
              >
                LinkedIn
              </a>
            </div>
          </div>
          <div className='p-10'>
            <div className='w-80 mx-auto flex flex-row justify-center items-center space-x-8 text-white'>
              <FDLLogo className='w-40 lg:w-52 fill-white' />
              <span>X</span>
              <BiwocBW className='w-20 lg:w-28 fill-white' />
            </div>
            <div className='w-80 mx-auto my-4 text-sm font-inter text-justify'>
              <FormattedMessage
                id='app.note'
                defaultMessage='Brave Spaces 2.0 is a joint project between BIWOC* Rising and
                Founderland who are financially supported by SEND e.V. within
                "emp:our now"'
              />
            </div>
          </div>
        </div>
      </div>
      <div className='Footer-copy'>
        <p className='text-white text-xs text-center w-full'>
          {new Date().getFullYear()} © Brave Spaces 2.0
        </p>
      </div>
    </footer>
  )
}

export default Footer
