import { useField, useFormikContext } from 'formik'
import { at } from 'lodash'
import { FormattedMessage, useIntl } from 'react-intl'

const TextInput = ({
  defaultLabel,
  name,
  type,
  required,
  dataList,
  placeholder,
  notes,
}) => {
  const intl = useIntl()
  const [field, meta] = useField({ name, defaultLabel })
  const [touched, error] = at(meta, 'touched', 'error')
  const { validateField, handleChange } = useFormikContext()

  const update = (e) => {
    handleChange(e)
    if (touched) {
      validateField(name)
    }
  }

  return (
    <>
      <label>
        <FormattedMessage id={'signup.' + name} defaultMessage={defaultLabel} />{' '}
        {required ? '*' : ''}
      </label>
      <input
        {...field}
        type={type}
        list={name}
        placeholder={intl.formatMessage({
          id: 'signup.' + name + '.placeholder',
          defaultMessage: placeholder
            ? placeholder
            : intl.formatMessage({ id: 'signup.' + name }),
        })}
        className={`${
          !touched
            ? 'border-black'
            : error
            ? 'border-invalid'
            : meta.value !== '' && meta.value !== null
            ? 'border-valid'
            : 'border-black'
        }`}
        autoComplete={type === 'password' ? 'new-password' : 'none'}
        value={meta.value}
        onChange={(e) => {
          update(e)
        }}
      />
      {dataList && (
        <datalist id={name} className='relative'>
          {dataList.length > 0 &&
            dataList
              .filter((item) => item.name.startsWith(meta.value))
              .splice(0, 10)
              .map((item, i) => (
                <option
                  className='absolute py-1 mt-1 overflow-auto text-base bg-white shadow-lg max-h-40 focus:ring-1 ring-secondaryMagenta ring-opacity-5 focus:outline-none sm:text-sm'
                  key={i}
                >
                  {item.name}
                </option>
              ))}
        </datalist>
      )}
      {touched && error ? (
        <p className='absolute m-0 -bottom-8 text-sm lg:text-base text-invalid'>
          {meta.error &&
            intl.formatMessage({
              id: 'signup.' + meta.error.toLowerCase(),
              defaultMessage: 'Required',
            })}
        </p>
      ) : notes ? (
        <p className='mt-2 text-xs lg:text-sm'>
          {notes &&
            intl.formatMessage({
              id: 'signup.' + name + '.notes',
              defaultMessage: notes,
            })}
        </p>
      ) : null}
    </>
  )
}

export default TextInput
