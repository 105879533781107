import { ChevronDoubleLeftIcon } from '@heroicons/react/24/outline'
import moment from 'moment'
import { useContext, useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import MainContext from '../../../contexts/Main'
import axios from 'axios'
import { saveViewUrl } from '../Config/APIurls'
import { ReactComponent as Instagram } from '../../../assets/logos/instagramLogoCC.svg'
import { ReactComponent as LinkedIn } from '../../../assets/logos/linkedin.svg'
import { ReactComponent as Website } from '../../../assets/logos/website.svg'
import { FormattedMessage } from 'react-intl'
import BlogCard from './BlogCard'

const Post = ({ post, posts }) => {
  const authorRef = useRef(null)
  const [isAuthorInViewport, setIsAuthorInViewport] = useState(false)
  const [viewSaved, setViewSavwed] = useState(false)
  const { isInViewport, config } = useContext(MainContext)

  useEffect(() => {
    const handleScroll = () => {
      if (authorRef.current) {
        setIsAuthorInViewport(isInViewport(authorRef.current))
      }
    }

    window.addEventListener('scroll', handleScroll)
    handleScroll()

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  useEffect(() => {
    const handleSaveView = async () => {
      try {
        const response = await axios.patch(
          saveViewUrl + post.id,
          { post: post.id },
          config
        )
        setViewSavwed(true)
      } catch (err) {
        console.log(err)
      }
    }
    if (isAuthorInViewport && !viewSaved) {
      handleSaveView()
    }
  }, [isAuthorInViewport])

  const relatedPosts = posts.filter(
    (p) => p.id !== post.id && p.tags.some((t) => post.tags.includes(t))
  )

  return (
    <>
      <div className='flex flex-col items-center justify-center w-full h-full pb-8 pt-8 lg:pt-12 px-8 md:px-12 lg:px-40'>
        <div className='w-full flex justify-between items-center gap-2'>
          <div>
            <span className='text-xs font-bold'>
              <Link to='/blog'>Blog</Link> /{' '}
            </span>
            <span className='text-xs font-bold text-primaryBlue'>
              {post.title}
            </span>
          </div>
          <div>
            <Link to='/blog' className='flex gap-1 items-center'>
              <ChevronDoubleLeftIcon className='h-3 w-3' />
              <span className='text-xs'>
                <FormattedMessage id='blog.back' defaultMessage={'back'} />
              </span>
            </Link>{' '}
          </div>
        </div>
        <div className='w-full flex flex-col justify-between items-center my-8'>
          <div className='w-full mb-8'>
            <img
              className={`w-full h-60 md:h-72 lg:h-96 object-cover border border-black object-${post.image.focus}`}
              src={post.image ? post.image.url : '/images/placeholder.png'}
              alt='cover'
            />
          </div>
          <div>
            <h2 className='text-center px-12'>{post.title}</h2>
          </div>
          <div className='my-8 overflow-hidden'>
            <div dangerouslySetInnerHTML={{ __html: post.content }} />
          </div>
          <div
            className='w-full flex flex-col justify-start items-center mb-8'
            ref={authorRef}
          >
            <div className='w-full flex justify-between items-center'>
              <div className='flex space-x-2 items-end'>
                {post.author.image && (
                  <img
                    src={post.author.image.url}
                    alt='author'
                    className='w-14 h-14 flex items-center justify-center bg-blue-500 rounded-full text-white'
                  />
                )}
                <span className='text-sm'>Written by {post.author.name}</span>
                {post.author.pronouns && (
                  <span className='text-xs'>{post.author.pronouns}</span>
                )}
              </div>

              {post.author.link && (
                <div className=''>
                  <a
                    href={post.author.link}
                    target='_blank'
                    rel='noreferrer'
                    className='flex gap-2 items-center text-secondaryMagenta text-sm cursor-pointer'
                  >
                    {post.author.link.includes('instagram') ? (
                      <Instagram className='w-4 h-4 stroke-secondaryMagenta text-secondaryMagenta' />
                    ) : post.author.link.includes('linkedin') ? (
                      <LinkedIn className='w-4 h-4 fill-secondaryMagenta text-secondaryMagenta' />
                    ) : (
                      <Website className='w-4 h-4 stroke-secondaryMagenta text-secondaryMagenta' />
                    )}
                    Follow {post.author.name}
                  </a>
                </div>
              )}
            </div>
            {post.author.bio && (
              <div className='flex justify-start w-1/2 mr-auto text-xs text-justify mt-2'>
                {post.author.bio}
              </div>
            )}
          </div>
        </div>
        <div className='w-full flex justify-between items-center'>
          <div className='flex gap-2'>
            {post.tags.map((tag, index) => (
              <span
                key={tag + index}
                className='flex gap-1 w-fit py-1 pl-2 pr-1 text-center text-xs cursor-default bg-secondaryLilac border-violet-400 text-primaryBlue'
              >
                {tag}
              </span>
            ))}
          </div>
          <div>
            <span className='text-xs font-bold'>
              {moment(post.createdAt).format('DD/MM/YYYY')}
            </span>
          </div>
        </div>
      </div>
      {relatedPosts.length > 0 && (
        <div className='w-full flex flex-col justify-start items-center pb-8'>
          <h2 className='w-full text-left pl-8 md:pl-12 lg:pl-40'>
            <FormattedMessage
              id='blog.related'
              defaultMessage={'Related Posts'}
            />
          </h2>
          <div className='w-full flex pl-8 md:pl-12 lg:pl-40 py-4 pr-8 gap-4 overflow-x-scroll '>
            {relatedPosts.map((post) => (
              <BlogCard key={post.id} post={post} />
            ))}
          </div>
        </div>
      )}
    </>
  )
}

export default Post
