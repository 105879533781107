import { FormattedMessage } from 'react-intl'
import ServiceProviderCard from './Components/ServiceProviderCard'

const ServiceProviders = ({ serviceProviders, noBooking }) => {
  return (
    <>
      <div
        className={`w-full ${
          serviceProviders.length
            ? 'flex flex-col md:grid md:grid-cols-2'
            : 'flex'
        } ${
          serviceProviders.length > 2 ? 'md:grid-rows-2' : 'md:grid-rows-1'
        } gap-8 px-10 py-2 justify-center items-center`}
      >
        {serviceProviders.length > 0 ? (
          serviceProviders.map((serviceProvider) => (
            <ServiceProviderCard
              key={serviceProvider.id}
              serviceProvider={serviceProvider}
              noBooking={noBooking}
            />
          ))
        ) : (
          <div className='flex flex-col justify-center items-center flex-grow p-12'>
            <p>
              <FormattedMessage
                id='serviceProviders.noProviders'
                default='No Service Providers'
              />
            </p>
            <p>
              <FormattedMessage
                id='serviceProviders.noProviders2'
                default='No Service Providers'
              />
            </p>
          </div>
        )}
      </div>
    </>
  )
}

export default ServiceProviders
