import hero from '../../../assets/images/hero.jpg'
import { ReactComponent as StarSymbol } from '../../../assets/elements/star.svg'
import { ReactComponent as BSPrimary } from '../../../assets/logos/BSPrimaryCustom.svg'
import { ReactComponent as BSSecondary } from '../../../assets/logos/BSSecondary.svg'
import { ReactComponent as Star4Symbol } from '../../../assets/elements/4star.svg'

import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'

const Header = () => {
  return (
    <section id='headline'>
      <figure className='hidden lg:flex border-b border-black'>
        <div className='relative w-1/3 h-auto z-0 border-r border-black'>
          <img
            src={hero}
            className='w-full object-cover z-0'
            alt='Group of people'
          />
          <span className='absolute bottom-5 -right-4'>
            <StarSymbol className='w-28 h-28 rotate-45 fill-primaryBlue stroke-black' />
          </span>
        </div>
        <div className='w-5 h-auto bg-primaryLime border-r border-black'>
          &nbsp;
        </div>
        <div className='w-10 h-auto bg-primaryBlue border-r border-black'>
          &nbsp;
        </div>
        <div className='flex flex-col justify-center items-start flex-grow px-8 bg-secondaryLilac'>
          <div className='flex flex-col space-y-1 max-w-md'>
            <h2 className='m-0'>
              <FormattedMessage
                id='app.resourcesTitle'
                defaultMessage='Find the Resources you need'
              />
            </h2>
            <BSPrimary className='w-80 m-0 h-auto fill-white stroke-black' />
          </div>
          <div className='flex flex-col items-center space-y-8'>
            <p className='max-w-xl'>
              <FormattedMessage
                id='app.resourcesText'
                defaultMessage='We are curating a list of resources that will help you on your entrepreneurial journey.'
              />
            </p>
            <Link
              className='button primary w-80 h-14 flex justify-center items-center py-4 my-5 mr-auto uppercase'
              to='/resources'
            >
              <FormattedMessage
                id='app.resourcesButton'
                defaultMessage='Check the Resources'
              />
            </Link>
          </div>
        </div>
      </figure>
      <figure className='flex border-b border-black flex-col lg:hidden bg-secondaryLilac'>
        <div className='flex flex-col pt-6 mx-6 text-center'>
          <p className='max-w-sm mx-auto mb-0'>
            <strong>
              <FormattedMessage
                id='app.resourcesTitle'
                defaultMessage='Resources Database'
              />
            </strong>
          </p>
        </div>
        <div className='relative flex justify-center w-full py-8 px-2'>
          <div className='relative w-52 h-60'>
            <div className='absolute flex justify-center items-center top-0 left-0 w-52 h-44 bg-primaryBlue border border-black z-20'>
              <BSSecondary className='w-40 lg:w-60 inline-flex fill-white stroke-black' />
            </div>
            <div className='absolute top-4 left-4 w-52 h-44 bg-secondaryMagenta border border-black z-10'>
              &nbsp;
            </div>
            <div className='absolute top-8 left-8 w-52 h-44 bg-primaryLime border border-black z-0'>
              &nbsp;
            </div>

            <Star4Symbol className='absolute bottom-4 -left-6 rotate-45 w-12 h-12 fill-secondaryBlue stroke-black z-30' />
            <StarSymbol className='absolute -top-2 -right-6 rotate-45 w-20 h-20 fill-primaryLime stroke-black z-30' />
          </div>
        </div>
        <div className='flex flex-col py-6 mx-6 text-center justify-center'>
          <p>
            <FormattedMessage
              id='app.resourcesText'
              defaultMessage='Check the various resources Brave Spaces 2.0 has gathered to help you in your journey'
            />
          </p>
          <Link
            className='button primary w-full sm:w-80 mx-auto h-14 flex justify-center items-center py-4 my-5 uppercase'
            to='/resources'
          >
            <FormattedMessage
              id='app.resourcesButton'
              defaultMessage='Check the Resources'
            />
          </Link>
        </div>
      </figure>
    </section>
  )
}

export default Header
