import axios from 'axios'
import jwt from 'jwt-decode'
import { refreshTokenUrl } from '../../Admin/Config/APIurls'

// NAVIGATION
export const getParams = (path) => {
  const params = path['*'].split('/')
  const appParams = ['view', 'id']
  const paramsObject = { view: false, id: false }
  for (let i = 0; i < appParams.length; i++) {
    if (params[i] === '' || params[i] === undefined) continue
    paramsObject[appParams[i]] = params[i]
  }
  return paramsObject
}

export const renewToken = async (setToken) => {
  const accessToken = localStorage.BSAdminAuthToken
  const refreshToken = localStorage.BSAdminRefreshToken
  const decodeToken = jwt(accessToken)
  const now = Math.floor(Date.now() / 1000)
  if (decodeToken.exp > now) {
    return true
  }
  const decodeRefreshToken = jwt(refreshToken)
  if (decodeRefreshToken.exp < now) {
    setToken(null) //logout
    return false
  }
  const config = { headers: { 'Content-Type': 'application/json' } }
  try {
    const response = await axios.post(
      refreshTokenUrl,
      { refreshToken: refreshToken },
      config
    )
    localStorage.setItem('BSAdminAuthToken', response.data.access.token)
    localStorage.setItem('BSAdminRefreshToken', response.data.refresh.token)
    setToken(localStorage.BSAdminAuthToken)
    setTimeout(() => {
      return true
    }, 1000)
  } catch (err) {
    return false
  }
}
