import { useContext, useEffect, useState } from 'react'
import Filter from './Filter'
import axios from 'axios'
import { getListsApi } from '../Config/APIurls'
import AdminContext from '../../../contexts/Admin'
import { getServiceProviderListsApi } from '../../Platform/Config/APIurls'

const defaultLists = {
  resources: {
    audiences: [
      'Black',
      'CIS-Women',
      'Latin/a/o/x',
      'Migrant',
      'Muslim',
      'People w/ Disability',
      'PoC',
      'Queer / LGBTQIA+',
      'Rom:nja und Sinti:zze',
      'TIN + all women',
      'Undocumented Refugees',
    ],
    offers: [],
    states: [],
    industries: ['Creative', 'Social', 'Tech'],
  },
  serviceProviders: {
    industry: ['Creative', 'Social', 'Tech'],
    services: [
      'Consulting',
      'Coaching',
      'Mentoring',
      'Workshops',
      'Training',
      'Events',
      'Networking',
      'Community',
      'Funding',
      'Jobs',
      'Other',
    ],
    languages: ['English', 'German', 'Spanish', 'French', 'Arabic', 'Other'],
  },
}

const SearchList = ({ reset, filters, setFilters, location }) => {
  const { config } = useContext(AdminContext)
  const [lists, setLists] = useState({
    ...defaultLists.resources,
    ...defaultLists.serviceProviders,
  })

  useEffect(() => {
    const getLists = async () => {
      try {
        const { data } = await axios.get(
          location === 'resources' ? getListsApi : getServiceProviderListsApi,
          config
        )
        if (data) {
          setLists((prev) => ({
            ...lists,
            ...data,
          }))
        }
      } catch (e) {}
    }
    getLists()
  }, [])

  return (
    <div className='flex'>
      <div className='w-full justify-start items-start gap-2 inline-flex'>
        <div className='w-full justify-start items-center gap-4 flex flex-wrap'>
          {Object.keys(filters).map((key) => (
            <Filter
              key={key}
              filter={filters[key]}
              setFilter={setFilters}
              field={key}
              lists={lists}
            />
          ))}
          <div className='text-center text-stone-900 text-sm font-normal leading-tight'>
            <button
              className='rounded-full px-2 py-1 w-fit text-sm text-grotesk justify-center items-center cursor-pointer transition duration-150 ease-in hover:bg-primaryLime '
              onClick={() => {
                reset()
              }}
            >
              Reset
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SearchList
