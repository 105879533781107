import { useField, useFormikContext } from 'formik'
import { at } from 'lodash'
import { useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

const CheckBox = ({ defaultLabel, type, name, required, notes }) => {
  const intl = useIntl()
  const [field, meta] = useField({ name, defaultLabel })
  const [touched, error] = at(meta, 'touched', 'error')
  const { validateField, handleChange } = useFormikContext()
  const [state, setState] = useState(meta.value)

  const update = (e) => {
    handleChange(e)
    if (touched) {
      validateField(name)
    }
  }

  return (
    <div className='inline-block my-8'>
      <label>
        <input
          id={name}
          {...field}
          type={type}
          className='w-5 h-5 ease-in-out text-base ml-7 rounded-none checked:bg-gradient-to-tl checked:from-gray-900 checked:to-black duration-200 relative float-left mt-1 cursor-pointer appearance-none border border-solid border-black bg-white bg-contain bg-center bg-no-repeat align-top transition-all '
          // className={`align-middle float-left w-5 h-5`}
          value={state}
          onChange={(e) => {
            update(e)
          }}
          required={required}
        />
        <label for={name} class='cursor-pointer select-none text-slate-700'>
          {' '}
          <FormattedMessage
            id={'events.' + name}
            defaultMessage={defaultLabel}
          />
          {required ? '*' : ''}
        </label>
      </label>
      {touched && error ? (
        <p className='absolute m-0 -bottom-8 text-sm lg:text-base text-invalid'>
          {meta.error &&
            intl.formatMessage({
              id: 'events.' + meta.error.toLowerCase(),
              defaultMessage: 'Required',
            })}
        </p>
      ) : notes ? (
        <p className='mt-2 text-xs lg:text-sm'>
          {notes &&
            intl.formatMessage({
              id: 'events.' + name + '.notes',
              defaultMessage: notes,
            })}
        </p>
      ) : null}
    </div>
  )
}

export default CheckBox
