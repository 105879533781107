import { useField, useFormikContext } from 'formik'
import { at } from 'lodash'
import { FormattedMessage, useIntl } from 'react-intl'

const TextArea = ({
  defaultLabel,
  name,
  type,
  required,
  placeholder,
  notes,
}) => {
  const intl = useIntl()
  const [field, meta] = useField({ name, defaultLabel })
  const [touched, error] = at(meta, 'touched', 'error')
  const { validateField, handleChange } = useFormikContext()

  const update = (e) => {
    handleChange(e)
    if (touched) {
      validateField(name)
    }
  }

  return (
    <>
      <label>
        <FormattedMessage id={'signup.' + name} defaultMessage={defaultLabel} />{' '}
        {required ? '*' : ''}
      </label>
      <textarea
        {...field}
        type={type}
        list={name}
        placeholder={intl.formatMessage({
          id: 'signup.' + name + '.placeholder',
          defaultMessage: placeholder,
        })}
        rows='3'
        className={`${
          !touched
            ? 'border border-black'
            : error
            ? 'border border-invalid'
            : meta.value !== '' && meta.value !== null
            ? 'border border-valid'
            : 'border border-black'
        }`}
        autoComplete={type === 'password' ? 'new-password' : 'none'}
        value={meta.value}
        onChange={(e) => {
          update(e)
        }}
      ></textarea>
      {touched && error ? (
        <p className='absolute m-0 -bottom-8 text-sm lg:text-base text-invalid'>
          {meta.error &&
            intl.formatMessage({
              id: 'signup.' + meta.error.toLowerCase(),
              defaultMessage: 'Required',
            })}
        </p>
      ) : notes ? (
        <p className='mt-2 text-xs lg:text-sm'>
          {notes &&
            intl.formatMessage({
              id: 'signup.' + name + '.notes',
              defaultMessage: notes,
            })}
        </p>
      ) : null}
    </>
  )
}

export default TextArea
