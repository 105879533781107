import { useContext, useEffect, useState } from 'react'
import Filter from './Filter'
import axios from 'axios'
import MainContext from '../../../../contexts/Main'
import { getListsApi } from '../../Config/APIurls'

const SearchList = ({ reset, filters, setFilters }) => {
  const { config } = useContext(MainContext)
  const [lists, setLists] = useState({
    audiences: [
      'Black',
      'CIS-Women',
      'Latin/a/o/x',
      'Migrant',
      'Muslim',
      'People w/ Disability',
      'PoC',
      'Queer / LGBTQIA+',
      'Rom:nja und Sinti:zze',
      'TIN + all women',
      'Undocumented Refugees',
    ],
    offers: [],
    states: [],
    industries: ['Creative', 'Social', 'Tech'],
  })

  useEffect(() => {
    const getLists = async () => {
      try {
        const { data } = await axios.get(getListsApi, config)
        if (data) {
          setLists((prev) => ({
            ...lists,
            ...data,
          }))
        }
      } catch (e) {}
    }
    getLists()
  }, [])

  return (
    <div className='my-4 flex'>
      <div className='w-full justify-start items-start gap-2 inline-flex'>
        <div className='w-full justify-start items-center gap-4 flex flex-wrap'>
          {Object.keys(filters).map((key) => (
            <Filter
              key={key}
              filter={filters[key]}
              setFilter={setFilters}
              field={key}
              lists={lists}
            />
          ))}
          <div className='text-center text-stone-900 text-sm font-normal leading-tight'>
            <button
              className='rounded-full px-2 py-1 w-fit text-sm text-grotesk justify-center items-center cursor-pointer transition duration-150 ease-in hover:bg-primaryLime '
              onClick={() => {
                reset()
              }}
            >
              Reset
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SearchList
