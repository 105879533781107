import { useContext, useEffect, useRef, useState } from 'react'
import InfoBox from '../../Common/Widgets/InfoBox'
import Banner from '../components/Banner'
import FAQ from './FAQ'
import ServiceProviders from './ServiceProviders'
import { getServiceProvidersUrl } from '../Config/APIurls'
import axios from 'axios'
import MainContext from '../../../contexts/Main'
import Loading from '../../Common/Widgets/Loading'
import SearchBar from '../components/SearchBar'
import SearchList from './Components/SearchLists'
import Pagination from '../components/Pagination'

const Booking = () => {
  const [serviceProviders, setServiceProviders] = useState([])
  const [searchTerm, setSearchTerm] = useState('')
  const [filters, setFilters] = useState({
    industry: {
      name: 'Industry',
      search: [],
      show: false,
    },
    services: {
      name: 'Services',
      search: [],
      show: false,
    },
    languages: {
      name: 'Languages',
      search: [],
      show: false,
    },
  })
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const { config, reload, scrollToElement } = useContext(MainContext)

  const serviceProvidersList = useRef(null)

  //clear filters
  const reset = () => {
    setSearchTerm('')
    setFilters({
      industry: {
        name: 'Industries',
        search: [],
        show: false,
      },
      services: {
        name: 'Services',
        search: [],
        show: false,
      },
      languages: {
        name: 'Languages',
        search: [],
        show: false,
      },
    })
  }
  // QUERY CONSTRUCT
  const constructApiQuery = (searchTerm) => {
    try {
      let query = ''
      // Initialize an empty array to store sanitized query parameters
      const sanitizedQueryParams = []
      // Go through filters
      for (const key of Object.keys(filters)) {
        if (filters[key].search.length > 0) {
          sanitizedQueryParams.push(
            `${key}=${encodeURIComponent(filters[key].search.toString())}`
          )
        }
      }
      if (searchTerm) {
        sanitizedQueryParams.push(`&name=${encodeURIComponent(searchTerm)}`)
      }
      // Check if the searchTerm is provided and append it to the query string
      query += sanitizedQueryParams.join('&')
      return query
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    const fetchServiceProviders = async () => {
      try {
        let query = ''
        if (constructApiQuery(searchTerm)) {
          query =
            '?available=true&limit=4&sortBy=name&page=' +
            currentPage +
            '&' +
            constructApiQuery(searchTerm)
        } else {
          query = '?available=true&limit=4&sortBy=name&page=' + currentPage
        }
        const { data } = await axios.get(getServiceProvidersUrl + query, config)
        setServiceProviders(data.results)
        setCurrentPage(data.page)
        setTotalPages(data.totalPages)
      } catch (error) {
        setError(true)
      } finally {
        setLoading(false)
      }
    }
    fetchServiceProviders()
  }, [reload, currentPage, searchTerm])

  useEffect(() => {
    const fetchData = async () => {
      try {
        let query = ''
        if (constructApiQuery(searchTerm)) {
          query =
            '?available=true&limit=4&sortBy=name&page=' +
            currentPage +
            '&' +
            constructApiQuery(searchTerm)
        } else {
          query = '?available=true&limit=4&sortBy=name&page=' + currentPage
        }
        const { data } = await axios.get(getServiceProvidersUrl + query, config)

        setServiceProviders(data.results)
        setTotalPages(data.totalPages)
        setCurrentPage(data.page)
        setLoading(false)
        scrollToElement(serviceProvidersList)
      } catch (err) {}
    }
    fetchData()
  }, [filters])

  return (
    <>
      <Banner section='serviceProviders' />
      <section id='serviceProviders' className='px-10 pt-8 border-b-0'>
        <InfoBox
          title='serviceProviders.welcome'
          details='serviceProviders.help'
        />
        <div className='mt-2' ref={serviceProvidersList}>
          <SearchBar
            search={searchTerm}
            setSearch={setSearchTerm}
            location={'serviceProviders'}
          />
        </div>
        <div className='mt-2'>
          <SearchList reset={reset} filters={filters} setFilters={setFilters} />
        </div>
      </section>
      {loading ? (
        <Loading />
      ) : (
        <div className='my-8'>
          <ServiceProviders serviceProviders={serviceProviders} error={error} />
          {totalPages > 1 && (
            <div className='my-8'>
              <Pagination
                currentPage={currentPage}
                setPage={setCurrentPage}
                pageCount={totalPages}
              />
            </div>
          )}
        </div>
      )}
      <FAQ />
    </>
  )
}

export default Booking
