import { Disclosure } from '@headlessui/react'
import { MinusIcon, PlusIcon } from '@heroicons/react/24/solid'
import { FormattedMessage } from 'react-intl'

const FAQ = () => {
  return (
    <>
      <Disclosure>
        {({ open }) => (
          <>
            <Disclosure.Button className='w-full h-28 px-24 py-8 bg-secondaryPink border-t border-black justify-between items-center gap-2.5 inline-flex'>
              <span className='font-bold uppercase'>
                <FormattedMessage
                  id='openHours.FAQ1Title'
                  default='What are open hours?'
                />
              </span>
              {open ? (
                <MinusIcon className={`h-8 w-8 text-black`} />
              ) : (
                <PlusIcon className={`h-8 w-8 text-black`} />
              )}
            </Disclosure.Button>
            <Disclosure.Panel className='px-4 pb-8 text-sm text-black bg-secondaryPink'>
              <span className='w-full flex px-20'>
                <FormattedMessage
                  id='openHours.FAQ1Text'
                  default='Open hours are so-called consultation hours that you can book with experts and mentors from our network free of charge. Depending on the service, you are entitled to 60 to 90 minute appointments. You choose who you want to be supported by on your journey.'
                />
              </span>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
      <Disclosure>
        {({ open }) => (
          <>
            <Disclosure.Button className='w-full h-28 px-24 py-8 bg-primaryLime border-t border-black justify-between items-center gap-2.5 inline-flex'>
              <span className='font-bold uppercase'>
                <FormattedMessage
                  id='openHours.FAQ2Title'
                  default='How can I book?'
                />
              </span>
              {open ? (
                <MinusIcon className={`h-8 w-8 text-black`} />
              ) : (
                <PlusIcon className={`h-8 w-8 text-black`} />
              )}
            </Disclosure.Button>
            <Disclosure.Panel className='px-4 pb-8 text-sm text-black bg-primaryLime'>
              <span className='w-full flex px-20'>
                <FormattedMessage
                  id='openHours.FAQ2Text'
                  default='You can use the form above to check whether our services are suitable for you. If yes, you can take a look at the profiles of the experts and choose what is relevant for you. Most appointments can be booked automatically, for example with Calendly, directly with the helping experts.'
                />
              </span>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
      <Disclosure>
        {({ open }) => (
          <>
            <Disclosure.Button className='w-full h-28 px-24 py-8 bg-secondaryLilac border-t border-black justify-between items-center gap-2.5 inline-flex'>
              <span className='font-bold uppercase'>
                <FormattedMessage
                  id='openHours.FAQ3Title'
                  default='Why am I not eligible?'
                />
              </span>
              {open ? (
                <MinusIcon className={`h-8 w-8 text-black`} />
              ) : (
                <PlusIcon className={`h-8 w-8 text-black`} />
              )}
            </Disclosure.Button>
            <Disclosure.Panel className='px-4 pb-8 text-sm text-black bg-secondaryLilac'>
              <span className='w-full flex px-20'>
                <FormattedMessage
                  id='openHours.FAQ2Text'
                  default='As an empowerment project, we focus on founders, solopreneurs and freelancers who identify as BIWOC and TIN*BIPOC. If you identify as such and the form does not work, please contact our team: hallo@bravespaces.org. <br/>If you are not part of the community, there are more resources here (link to WIKI)'
                />
              </span>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
      <Disclosure>
        {({ open }) => (
          <>
            <Disclosure.Button className='w-full h-28 px-24 py-8 bg-secondaryBlue border-t border-black justify-between items-center gap-2.5 inline-flex'>
              <span className='font-bold uppercase'>
                <FormattedMessage
                  id='openHours.FAQ4Title'
                  default='Apply as expert / mentor'
                />
              </span>
              {open ? (
                <MinusIcon className={`h-8 w-8 text-black`} />
              ) : (
                <PlusIcon className={`h-8 w-8 text-black`} />
              )}
            </Disclosure.Button>
            <Disclosure.Panel className='px-4 pb-8 text-sm text-black bg-secondaryBlue'>
              <span className='w-full flex px-20'>
                <FormattedMessage
                  id='openHours.FAQ4Text'
                  default='You can def reach out to us. We’d be excited to see if your expertise and knowledge can be of impact to our community. Send us an e-mail with your portfolio or profile to: hallo@bravespaces.org'
                />
              </span>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </>
  )
}

export default FAQ
