import team1 from '../../../assets/images/meettheteam1.jpg'
import team2 from '../../../assets/images/meettheteam2.jpg'
import { FormattedMessage } from 'react-intl'
import { ReactComponent as StarSymbol } from '../../../assets/elements/star.svg'
import { ReactComponent as LogoCircular } from '../../../assets/logos/logoCircular.svg'

const Team = () => {
  return (
    <section id='team' className=' bg-primaryLime overflow-hidden'>
      <figure className='flex flex-col-reverse lg:flex-row mx-6 lg:mx-26 py-10'>
        <div className='w-full lg:max-w-md p-8 lg:p-4 lg:pr-20 flex flex-col justify-center items-start flex-grow '>
          <h2>
            <FormattedMessage
              id='app.teamTitle'
              defaultMessage='Meet the Team'
            />
          </h2>
          <p>
            <FormattedMessage
              id='app.teamText'
              defaultMessage='Passionate advocates for inclusive entrepreneurship, rewriting narratives, and centering marginalized voices. Together, we are creating a brave and empowering community.'
            />
          </p>
        </div>
        <div className='mx-auto max-w-xl lg:w-full grid lg:mx-8 grid-cols-3'>
          <div className='relative col-start-1 col-span-2 w-full border border-black'>
            <img
              src={team1}
              alt='group of women'
              className='w-full h-full object-cover'
            />
            <StarSymbol className='absolute -bottom-10 -left-10 lg:-left-20 w-20 h-20 lg:w-32 lg:h-32 rotate-45 fill-primaryBlue  stroke-black' />
          </div>
          <div className='relative col-start-2 -mt-10  col-span-2 w-full border border-black'>
            <img
              src={team2}
              alt='group of women'
              className='w-full h-full object-cover'
            />
            <LogoCircular className='absolute -top-20 lg:-top-26 right-12 sm:right-20 md:right-32 lg:-right-4 xl:right-10 w-26 h-26 lg:w-32 lg:h-32 fill-black animate-rotate' />
          </div>
        </div>
      </figure>
    </section>
  )
}

export default Team
