import { useState, useCallback, useContext, useEffect } from 'react'
import { useDropzone } from 'react-dropzone'
import axios from 'axios'
import AdminContext from '../../../contexts/Admin'
import { uploadImageUrl } from '../Config/APIurls'
import { useFormikContext } from 'formik'

const Dropzone = ({ type, classes, folder, required, edit }) => {
  const [previewSource, setPreviewSource] = useState(null)
  const [loading, setLoading] = useState(false)
  const { getUuid, config, displayMainMessage } = useContext(AdminContext)
  const regex = new RegExp('(?:Cover)', 'g')
  const { values, setFieldValue } = useFormikContext()

  // CLOUDINARY
  const onDrop = useCallback((acceptedFiles) => {
    const reader = new FileReader()
    reader.readAsDataURL(acceptedFiles[0])
    reader.onerror = () => console.log('file reading has failed')
    reader.onloadend = () => {
      const binaryStr = reader.result
      setPreviewSource(binaryStr)
      uploadImage(binaryStr)
    }
  }, [])

  const { getRootProps, getInputProps } = useDropzone({ onDrop })
  const uploadImage = async (base64EncodedImage) => {
    setLoading(true)
    try {
      const result = await axios.post(
        uploadImageUrl,
        {
          data: base64EncodedImage,
          public_id: `${type}`,
          folder: `${folder}/${getUuid()}`,
        },
        config
      )
      if (result.data?.public_id) {
        displayMainMessage(
          {
            success: 1,
            message: 'Successfully uploaded',
            show: true,
          },
          5000
        )
        setFieldValue(`${type}`, {
          public_id: result.data.public_id,
          url: result.data.url,
        })
        setLoading(false)
      }
    } catch (e) {
      displayMainMessage(
        {
          error: 1,
          message: 'Error uploading the file',
          show: true,
        },
        5000
      )
      setLoading(false)
      setPreviewSource(null)
    }
  }
  useEffect(() => {
    if (values[type]?.public_id) {
    }
  }, [values[type]])

  const nestedProperty = type.split('.').reduce((obj, prop) => {
    if (obj && obj[prop]) {
      return obj[prop]
    } else {
      // Handle cases where the property doesn't exist
      return undefined
    }
  }, values)

  return (
    <>
      <div {...getRootProps()} className={`${classes} `}>
        <input {...getInputProps()} />
        {loading && (
          <div className='flex justify-center'>
            <span
              style={{ borderTopColor: 'transparent' }}
              className='w-16 h-16 border-8 border-black border-dotted rounded-full animate-spin'
            ></span>
          </div>
        )}
        {!loading && previewSource && (
          <img
            src={previewSource}
            alt='chosen'
            className={`w-full p-2 object-cover object-${
              !type.includes('.') ? values[type].focus : 'center'
            }`}
          />
        )}

        {!loading &&
          edit &&
          !previewSource &&
          (values[type]?.public_id || nestedProperty?.public_id) && (
            <>
              <img
                src={values[type]?.url || nestedProperty?.url}
                alt='chosen'
                className={`w-full p-2 object-cover object-${
                  !type.includes('.') ? values[type].focus : 'center'
                }`}
              />
              <span className='absolute top-0 right-0'>Current {type}</span>
            </>
          )}

        <div
          className={`block uppercase text-center tracking-wide text-gray-600 text-xs font-bold `}
        >
          <p className='text-sm'>
            Drag and drop your file here, or click to select it
          </p>
          {type.match(regex) ? (
            <p className='text-xs'>(Desired ratio: 1:1)</p>
          ) : (
            ''
          )}
        </div>
      </div>
    </>
  )
}

export default Dropzone
