import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'

const PageNotFound = () => {
  return (
    <>
      <div className='max-w-screen-xl flex flex-grow mx-auto mt-10 md:mt-16'>
        <div className='w-full flex flex-col items-center justify-center h-screen'>
          <h1 className='text-4xl font-bold mb-4'>
            <FormattedMessage id='app.notFoundTitle' defaultMessage='Oh No!' />
          </h1>
          <p className='text-gray-500 text-center mb-8'>
            <FormattedMessage
              id='app.notFound'
              defaultMessage="We couldn't find the page you're looking for."
            />
          </p>
          <Link
            to={'/'}
            className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded'
          >
            <FormattedMessage id='app.back' defaultMessage="Let's go home" />
          </Link>
        </div>
      </div>
    </>
  )
}

export default PageNotFound
