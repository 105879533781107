import React, { useContext, useEffect, useState } from 'react'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import Dropzone from '../Widgets/DropZone'
import AdminContext from '../../../contexts/Admin'
import Tags from '../Widgets/Tags'
import axios from 'axios'
import {
  createResourceUrl,
  getSuggestionsApi,
  updateResourceUrl,
} from '../Config/APIurls'

const defaultSuggestionsTags = [
  'health',
  'food',
  'education',
  'finance',
  'sport',
  'conference',
  'fundraiser',
  'masterclass',
]
const defaultSuggestionsTypes = ['community', 'ngo', 'event']
const defaultSuggestionsAudiences = ['Black Women', 'PoC', 'LGBTQIA+']
const defaultSuggestionsIndustries = [
  'Health',
  'Food',
  'Education',
  'Finance',
  'Sport',
  'Conference',
  'Fundraiser',
  'Masterclass',
]
const defaultSuggestionsLanguages = [
  'EN',
  'DE',
  'FR',
  'SP',
  'IT',
  'RU',
  'AR',
  'TR',
  'PT',
]
const defaultSuggestionsStates = [
  'Bradenburg',
  'Berlin',
  'Hamburg',
  'Hessen',
  'Niedersachsen',
  'Nordrhein-Westfalen',
  'Rheinland-Pfalz',
  'Saarland',
  'Sachsen',
  'Sachsen-Anhalt',
  'Schleswig-Holstein',
  'Thüringen',
]
const defaultSuggestionsOffers = [
  'Mentoring',
  'Coaching',
  'Consulting',
  'Training',
  'Networking',
  'Funding',
  'Volunteering',
  'Job',
  'Internship',
  'Scholarship',
  'Fellowship',
  'Incubator',
  'Accelerator',
  'Hackathon',
  'Conference',
  'Masterclass',
  'Workshop',
  'Event',
  'Community',
]
const ResourceFormSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  type: Yup.array().of(Yup.string()).min(1, 'One type is required'),
  image: Yup.object()
    .shape(
      {
        public_id: Yup.string(),
        url: Yup.string(),
      },
      'Please reupload a valid image'
    )
    .nullable(),
  description: Yup.string().required('Description is required'),
  offers: Yup.array().of(Yup.string()).min(1, 'One offer is required'),
  link: Yup.string()
    .url('Link must be a valid URL')
    .required('Link is required for online resources'),
  tags: Yup.array().of(Yup.string()).nullable(),
  audiences: Yup.array().of(Yup.string()).min(1, 'One audience is required'),
  notAudiences: Yup.array().of(Yup.string()).nullable(),
  industries: Yup.array().of(Yup.string()).min(1, 'One Industry is required'),
  languages: Yup.array().of(Yup.string()).min(1, 'One language is required'),
  states: Yup.array().of(Yup.string()).nullable(),
  location: Yup.string(),
})

const AddResource = ({ resource, setEdit }) => {
  const {
    navigate,
    config,
    displayMainMessage,
    reload,
    setReload,
    checkAuthToken,
  } = useContext(AdminContext)
  const [suggestions, setSuggestions] = useState({
    audiences: defaultSuggestionsAudiences,
    notAudiences: defaultSuggestionsAudiences,
    industries: defaultSuggestionsIndustries,
    languages: defaultSuggestionsLanguages,
    offers: defaultSuggestionsOffers,
    states: defaultSuggestionsStates,
    tags: defaultSuggestionsTags,
    types: defaultSuggestionsTypes,
  })
  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      if (await !checkAuthToken()) return navigate('/login')
      let response
      if (values.id) {
        response = await axios.patch(
          updateResourceUrl + values.id,
          values,
          config
        )
      } else {
        response = await axios.post(createResourceUrl, values, config)
      }

      if (response.status === 201 || 200) {
        displayMainMessage(
          {
            success: 1,
            message: 'Resource Saved',
            show: true,
          },
          3000
        )
        setSubmitting(false)
        setReload(reload + 1)
        navigate(`/admin/resources/${response.data.id}`)
      } else {
        displayMainMessage(
          {
            success: 0,
            message: 'Something went wrong',
            show: true,
          },
          4000
        )
        setSubmitting(false)
      }
    } catch (error) {
      displayMainMessage(
        {
          error: 1,
          message: 'Something went wrong',
          show: true,
        },
        4000
      )
    }
  }

  // Get Suggestions from existing resources in the database
  useEffect(() => {
    const fetchSuggestions = async () => {
      try {
        const response = await axios.get(getSuggestionsApi, config)
        if (response.status === 200) {
          setSuggestions(response.data)
        }
      } catch (error) {
        console.log(error)
      }
    }
    fetchSuggestions()
  }, [])

  return (
    <div className='w-full flex-col px-4 md:px-12'>
      <h2 className='text-3xl font-bold mb-4 uppercase'>Add new resource</h2>
      <Formik
        initialValues={
          resource || {
            id: null,
            name: '',
            type: [],
            image: null,
            description: '',
            offers: [],
            link: '',
            tags: [],
            audiences: [],
            notAudiences: [],
            industries: [],
            languages: [],
            states: [],
            location: '',
          }
        }
        validationSchema={ResourceFormSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, values, errors, setValues }) => {
          return (
            <Form>
              <div className='form-section'>
                <div className='col-span-2'>
                  <label htmlFor='name' className='block font-bold mb-2'>
                    Name
                  </label>
                  <Field
                    type='text'
                    name='name'
                    id='name'
                    className='w-full border-gray-400 border-2 rounded-md p-2'
                  />
                  <ErrorMessage
                    name='name'
                    component='div'
                    className='text-red-500 text-sm mt-1'
                  />
                </div>
              </div>
              <div className='form-section'>
                <div className='form-item'>
                  <label htmlFor='type' className='block font-bold mb-2'>
                    Type
                  </label>
                  <Tags
                    fieldName={'type'}
                    defaultSuggestions={suggestions.types}
                  />
                  <ErrorMessage
                    name='type'
                    component='div'
                    className='text-red-500 text-sm mt-1'
                  />
                </div>
                <div className='form-item'>
                  <label htmlFor='industries' className='block font-bold mb-2'>
                    Fields/Industries
                  </label>
                  <Tags
                    fieldName={'industries'}
                    defaultSuggestions={suggestions.industries}
                  />
                  <ErrorMessage
                    name='industries'
                    component='div'
                    className='text-red-500 text-sm mt-1'
                  />
                </div>
              </div>
              <div className='form-section'>
                <div className='form-item'>
                  <label htmlFor='languages' className='block font-bold mb-2'>
                    Languages
                  </label>
                  <Tags
                    fieldName={'languages'}
                    defaultSuggestions={suggestions.languages}
                  />
                  <ErrorMessage
                    name='languages'
                    component='div'
                    className='text-red-500 text-sm mt-1'
                  />
                </div>
                <div className='form-item'>
                  <label htmlFor='states' className='block font-bold mb-2'>
                    States
                  </label>
                  <Tags
                    fieldName={'states'}
                    defaultSuggestions={suggestions.states}
                  />
                  <ErrorMessage
                    name='states'
                    component='div'
                    className='text-red-500 text-sm mt-1'
                  />
                </div>
              </div>
              <div className='form-section'>
                <div className='col-span-2'>
                  <label htmlFor='offers' className='block font-bold mb-2'>
                    Offers
                  </label>
                  <Tags
                    fieldName={'offers'}
                    defaultSuggestions={suggestions.offers}
                  />
                  <ErrorMessage
                    name='offers'
                    component='div'
                    className='text-red-500 text-sm mt-1'
                  />
                </div>
              </div>
              <div className='form-section'>
                <div className='form-item'>
                  <label htmlFor='audiences' className='block font-bold mb-2'>
                    Audiences
                  </label>
                  <Tags
                    fieldName={'audiences'}
                    defaultSuggestions={suggestions.audiences}
                  />
                  <ErrorMessage
                    name='audiences'
                    component='div'
                    className='text-red-500 text-sm mt-1'
                  />
                </div>
                <div className='form-item'>
                  <label
                    htmlFor='notAudiences'
                    className='block font-bold mb-2'
                  >
                    Not Audiences
                  </label>
                  <Tags
                    fieldName={'notAudiences'}
                    defaultSuggestions={suggestions.notAudiences}
                  />
                  <ErrorMessage
                    name='notAudiences'
                    component='div'
                    className='text-red-500 text-sm mt-1'
                  />
                </div>
              </div>
              <div className='form-section'>
                <div className='col-span-2'>
                  <label htmlFor='description' className='block font-bold mb-2'>
                    Description
                  </label>
                  <Field
                    component='textarea'
                    name='description'
                    id='description'
                    className='w-full border-gray-400 border-2 rounded-md p-2'
                  />
                  <ErrorMessage
                    name='description'
                    component='div'
                    className='text-red-500 text-sm mt-1'
                  />
                </div>
              </div>
              <div className='form-section'>
                <div className='col-span-2'>
                  <label htmlFor='location' className='block font-bold mb-2'>
                    Location
                  </label>
                  <Field
                    type='text'
                    name='location'
                    id='location'
                    className='w-full border-gray-400 border-2 rounded-md p-2'
                  />
                  <ErrorMessage
                    name='location'
                    component='div'
                    className='text-red-500 text-sm mt-1'
                  />
                </div>
              </div>
              <div className='form-section'>
                <div className='col-span-2'>
                  <label htmlFor='link' className='block font-bold mb-2'>
                    Link
                  </label>
                  <Field
                    type='text'
                    name='link'
                    id='link'
                    className='w-full border-gray-400 border-2 rounded-md p-2'
                  />
                  <ErrorMessage
                    name='link'
                    component='div'
                    className='text-red-500 text-sm mt-1'
                  />
                </div>
              </div>
              <div className='form-section'>
                <div className='form-item'>
                  <label className='block uppercase tracking-wide text-xs font-bold mb-2'>
                    Logo/Picture/Photo
                  </label>
                  <Dropzone
                    classes={`appearance-none outline-none outline-none block w-full border-2 border-gray-300 border-black border-dotted  py-3 px-4 mb-3`}
                    type='image'
                    folder='resources'
                  />
                  <ErrorMessage
                    name='image'
                    component='div'
                    className='text-red-500 text-sm mt-1'
                  />
                </div>
                <div className='form-item'>
                  <label className='block uppercase tracking-wide text-xs font-bold mb-2'>
                    Tags
                  </label>
                  <Tags
                    fieldName={'tags'}
                    defaultSuggestions={suggestions.tags}
                  />
                </div>
              </div>
              <div className='form-section flex-col-reverse'>
                <button
                  type='button'
                  disabled={isSubmitting}
                  className='button-secondary'
                  onClick={() => {
                    values.id ? setEdit(false) : navigate(-1)
                  }}
                >
                  cancel
                </button>
                <button
                  type='submit'
                  disabled={isSubmitting}
                  className='button-primary'
                >
                  {isSubmitting ? 'Submitting...' : 'Submit'}
                </button>
              </div>
            </Form>
          )
        }}
      </Formik>
    </div>
  )
}

export default AddResource
