import PostCard from './PostCard'
import Pagination from '../components/Pagination'

const PostsList = ({ posts, totalPages, currentPage, setCurrentPage }) => {
  return (
    <>
      <div
        className={`mx-10 lg:mx-20 grid grid-cols-1 lg:grid-cols-2 ${
          posts.results.length > 2
            ? 'grid-rows-4 lg:grid-rows-2'
            : posts.results.length === 2
            ? 'lg:grid-rows-1 grid-rows-2'
            : posts.results.length === 1
            ? 'lg:grid-rows-1 grid-rows-1'
            : 'lg:grid-rows-2 grid-rows-' + posts.results.length
        } justify-between items-center gap-8 ${
          totalPages > 1 ? 'pb-0' : 'pb-12'
        }`}
      >
        {posts.results.map((post) => (
          <PostCard key={post.id} id={post.id} post={post} />
        ))}
        {totalPages > 1 && (
          <div className='col-span-2 justify-center pb-8'>
            <Pagination
              currentPage={currentPage}
              setPage={setCurrentPage}
              pageCount={totalPages}
            />
          </div>
        )}
      </div>
    </>
  )
}

export default PostsList
