import { MapPinIcon } from '@heroicons/react/24/solid'
import moment from 'moment'
import { Link } from 'react-router-dom'

const PastEventCard = ({ event }) => {
  return (
    <Link
      to='https://www.eventbrite.de/o/brave-spaces-20-64624123293'
      target='_blank'
      className='w-96 border border-black flex-shrink-0 bg-white overflow-hidden flex'
    >
      <img
        src={event.eventCover.url}
        alt='Event Poster'
        className='w-40 h-auto object-cover border-r border-black'
      />
      <div className='px-4 py-2 flex-grow flex flex-col'>
        <span className='text-gray-600 text-xs font-bold text-right'>
          {moment(event.dateStart).format('DD/MM/YYYY')}
        </span>
        <span className='flex-grow text-sm font-semibold my-2'>
          {event.title}
        </span>
        <div className='w-full py-2 flex items-center space-x-1'>
          <MapPinIcon className='w-4 h-4 fill-secondaryPink' />
          <span className='text-xs overflow-ellipsis'>{event.location}</span>
        </div>
      </div>
    </Link>
  )
}

export default PastEventCard
