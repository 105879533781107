import { FormattedMessage } from 'react-intl'
import { ReactComponent as InstagramLogo } from '../../../assets/logos/instagramLogo.svg'

const ThankYou = () => {
  return (
    <div className='flex flex-col justify-center items-center lg:min-h-[800px] col-span-6 lg:col-span-8 col-start-3 lg:col-start-2'>
      <h1 className='text-4xl font-bold text-center'>Hurray! 🎉 </h1>
      <h2 className='text-2xl font-bold text-center'>
        <FormattedMessage
          id='signup.thanks'
          defaultMessage='Thank you for signing up!'
        />
      </h2>
      <p className='text-center'>
        <FormattedMessage
          id='signup.success'
          defaultMessage='You are successfully signed up. Check your email for more news and information.'
        />
      </p>
      <p className='text-center'>
        <FormattedMessage
          id='signup.followcta'
          defaultMessage='In the meantime, do you want to check out our instagram page for useful tips?'
        />
      </p>
      <button className='group flex justify-center items-center space-x-2 button instagram relative group w-full mt-10'>
        <span>
          <FormattedMessage id='app.followus' defaultMessage='Follow Us' />
        </span>
        <InstagramLogo className='w-8 h-8 fill-white group-hover:fill-black  transition-all duration-300 ease-in-out' />
      </button>
    </div>
  )
}

export default ThankYou
