import { useContext } from 'react'
import MainContext from '../../../contexts/Main'
import SignUpProvider from '../../../contexts/SignUpProvider'
import { ReactComponent as LogoCircular } from '../../../assets/logos/logoCircular.svg'
import { ReactComponent as StarSymbol } from '../../../assets/elements/star.svg'
import join from '../../../assets/images/join.jpg'

const Form = () => {
  const { formElement } = useContext(MainContext)

  return (
    <section ref={formElement}>
      <figure className=' flex flex-col-reverse lg:flex-row'>
        <div className=' flex justify-center lg:justify-end items-center w-full lg:w-1/3 bg-primaryBlue border-t lg:border-t-0 lg:border-r border-black'>
          <div className='relative w-64 sm:w-80 -mt-10 mb-20 lg:mb-0 lg:-mr-12 lg:w-full'>
            <img
              src={join}
              className='w-full border border-black'
              alt='Group of Women'
            />
            <StarSymbol className='absolute -top-4 -left-12  lg:hidden w-20 h-20 rotate-45 fill-primaryLime stroke-black' />
            <LogoCircular className='absolute mt-20 -bottom-14 -right-14 lg:mt-32 lg:-bottom-40 lg:left-10 w-32 h-32 lg:w-52 lg:h-52 fill-white animate-rotate' />
          </div>
        </div>
        <div className='w-full lg:w-2/3 py-20' id='join'>
          <SignUpProvider />
        </div>
      </figure>
    </section>
  )
}

export default Form
