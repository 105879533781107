import { FaceFrownIcon } from '@heroicons/react/24/outline'
import TableHeader from './TableHeader'
import TableRow from './TableRow'
import TablePagination from './TablePagination'

const Table = ({
  title,
  data,
  headers,
  link,
  pageCount,
  currentPage,
  setCurrentPage,
}) => {
  return (
    <>
      {title && <h1 className='text-mono py-2 text-sm'>{title}</h1>}
      <div className='w-full rounded mt-2'>
        <div className='w-full'>
          <TableHeader headers={headers} />
        </div>
        <div className='bg-white'>
          {data.length ? (
            data.map((row, i) => (
              <TableRow key={row.id} item={row} headers={headers} link={link} />
            ))
          ) : (
            <div className='border-b border-gray-200 hover:bg-gray-100 w-full'>
              <div colSpan={headers.length} className='py-3 px-5 text-left'>
                <div className='flex items-center'>
                  <span className='font-medium flex space-x-4 items-center'>
                    <FaceFrownIcon className='h-6 w-6' />
                    <p>Nothing to display</p>
                  </span>
                </div>
              </div>
            </div>
          )}
        </div>
        {pageCount > 1 && (
          <div className='border-b min-w-max w-full border-gray-200 bg-gray-300'>
            <div className='flex items-center justify-center'>
              <TablePagination
                setPage={setCurrentPage}
                currentPage={currentPage}
                pageCount={pageCount}
              />
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export default Table
