import axios from 'axios'
import { useContext, useEffect, useState } from 'react'
import AdminContext from '../../../contexts/Admin'
import Loading from '../../Common/Widgets/Loading'
import { Link } from 'react-router-dom'
import { GlobeEuropeAfricaIcon, PlusIcon } from '@heroicons/react/24/outline'
import { getEventsUrl } from '../Config/APIurls'
import TablePagination from '../Widgets/TablePagination'
import moment from 'moment'
import { MapPinIcon } from '@heroicons/react/24/solid'

const EventsList = () => {
  const { config, displayMainMessage, checkAuthToken, navigate } =
    useContext(AdminContext)
  const [events, setEvents] = useState([])
  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [loading, setLoading] = useState(true)

  function handlePageChange(newPage) {
    setPage(newPage)
  }
  useEffect(() => {
    const fetchEvents = async () => {
      try {
        if (await !checkAuthToken()) return navigate('/login')
        const { data } = await axios.get(
          getEventsUrl + '?sortBy=dateStart:desc&limit=6&page=' + page,
          config
        )
        data.results.sort(function (a, b) {
          return new Date(b.dateStart) - new Date(a.dateStart)
        })
        if (data.results) {
          setEvents(data.results)
          setTotalPages(data.totalPages)
          setPage(data.page)
        }
        setLoading(false)
      } catch (err) {
        displayMainMessage(
          {
            error: 1,
            message: err.status
              ? err.response.message
              : 'Something went wrong while getting the data - please refresh the page',
            show: true,
          },
          5000
        )
        setLoading(false)
      }
    }

    fetchEvents()
  }, [page])

  return (
    <>
      {loading ? (
        <div className='w-full flex justify-center items-center'>
          <Loading />
        </div>
      ) : (
        <>
          <button
            className='button-primary mr-auto mb-4'
            onClick={() => navigate('/admin/events/add')}
          >
            <PlusIcon className='h-5 w-5' />
            <span className='text-mono text-sm'>New Event</span>
          </button>
          <div className='w-full flex flex-col justify-center md:grid md:grid-cols-2 xl:grid-cols-3 pb-16 overflow-scroll gap-4'>
            {events.length ? (
              events.map((event) => (
                <div
                  key={event.id}
                  className='relative bg-white w-full md:max-w-lg h-80 overflow-hidden border border-black cursor-pointer hover:shadow-lg transition-all duration-300 ease-in-out'
                  onClick={() => navigate(`/admin/events/${event.id}`)}
                >
                  <img
                    src={event.eventCover.url}
                    alt={event.title}
                    className='w-full h-full object-cover object-top'
                  />
                  {event.isCanceled && (
                    <div className='absolute top-5 left-5 border-2 border-invalid text-invalid bg-white opacity-80 rounded-md p-4'>
                      Event Canceled
                    </div>
                  )}
                  <div className='absolute inset-y-1/2 bg-white opacity-90 w-full h-40 flex flex-col gap-2 p-2 '>
                    <span className='text-lg font-bold'>{event.title}</span>
                    <span className='flex-grow text-sm whitespace-pre-line truncate'>
                      {event.description}
                    </span>
                    <div className='flex justify-between items-center'>
                      <span className=' text-xs font-bold'>
                        {moment(event.dateStart).format('DD/MM/YYYY')}
                      </span>
                      {event.location ? (
                        <div className='flex justify-end gap-1 items-center'>
                          <MapPinIcon className='h-4 w-4 text-secondaryPink' />
                          <div className='font-bold text-xs md:text-sm'>
                            {event.location}
                          </div>
                        </div>
                      ) : (
                        <div className='flex justify-end gap-1 items-center'>
                          <GlobeEuropeAfricaIcon className='h-4 w-4 text-secondaryPink' />{' '}
                          <div className=' text-sm'>Online event</div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className='text-center'>No events found</div>
            )}
          </div>
          {totalPages > 1 && (
            <div className='fixed bottom-0 bg-white border-t min-w-max w-full border-gray-200'>
              <div className='flex items-center justify-center'>
                <TablePagination
                  setPage={setPage}
                  currentPage={page}
                  pageCount={totalPages}
                />
              </div>
            </div>
          )}
        </>
      )}
    </>
  )
}

export default EventsList
