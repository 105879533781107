import {
  ShieldCheckIcon,
  ShieldExclamationIcon,
  ExclamationTriangleIcon,
  LockOpenIcon,
  LockClosedIcon,
  CheckCircleIcon,
} from '@heroicons/react/24/outline'
import { useContext, useEffect, useState } from 'react'
import axios from 'axios'
import AdminContext from '../../../contexts/Admin'
import Loading from '../../Common/Widgets/Loading'
import { getUsersUrl, sendVerification } from '../Config/APIurls'
import OptionList from '../Widgets/OptionList'

const roles = [
  { name: 'admin', value: 'admin', color: 'secondaryMagenta' },
  { name: 'user', value: 'user', color: 'secondaryLilac' },
]

const Profile = () => {
  const {
    config,
    selectedTab,
    reload,
    user,
    currentParams,
    avatarInitials,
    navigate,
    displayMainMessage,
    checkAuthToken,
    setReload,
  } = useContext(AdminContext)
  const { id } = currentParams
  const [profile, setProfile] = useState({
    name: ' ',
    email: ' ',
    isEmailVerified: false,
    isLocked: false,
    password: '',
    confirmPassword: '',
    role: 'admin',
  })
  const [loading, setLoading] = useState(false)
  const [saving, setSaving] = useState(false)
  const [notifying, setNotifying] = useState(false)
  const [locking, setLocking] = useState(false)
  const [notified, setNotified] = useState(false)
  const [update, setUpdate] = useState(false)

  //PROFILE API CALLS
  useEffect(() => {
    const loadProfile = async () => {
      try {
        if (id !== 'new') {
          setLoading(true)
          if (await !checkAuthToken()) return navigate('/login')
          const getUser = await axios.get(
            id ? getUsersUrl + id : getUsersUrl + user.id,
            config
          )
          if (getUser.data) {
            setProfile(getUser.data)
            setLoading(false)
            setUpdate(false)
          } else {
            await Promise.reject(new Error('error_getting_profile'))
          }
          setUpdate(false)
        }
      } catch (e) {
        setLoading(false)
        displayMainMessage(
          {
            error: 1,
            message: 'Sorry, an error occurred while loading profile',
            show: true,
          },
          5000
        )
      }
    }
    loadProfile()
    return () => {
      setProfile({
        name: '',
        email: '',
        isEmailVerified: false,
        isLocked: false,
        password: '',
        confirmPassword: '',
        role: 'admin',
      })
    }
  }, [selectedTab, id, reload])

  const save = async () => {
    setSaving(true)
    if (await !checkAuthToken()) return navigate('/login')
    try {
      if (
        !id &&
        profile.password &&
        (profile.password !== profile.confirmPassword ||
          !/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/.test(profile.password))
      ) {
        await Promise.reject(new Error('Password does not match requirement'))
      } else {
        let result = null
        if (profile.id) {
          const data = {
            name: profile.name,
            role: profile.role,
            password: profile.password,
          }
          result = await axios.patch(getUsersUrl + profile.id, data, config)
        } else {
          const data = {
            name: profile.name,
            email: profile.email,
            role: profile.role,
          }
          if (Object.values(data).every((value) => value.length > 0)) {
            result = await axios.post(getUsersUrl, data, config)
          } else {
            await Promise.reject(new Error('All fields are required'))
          }
        }
        if (result) {
          setSaving(false)
          displayMainMessage(
            {
              success: 1,
              message: !id
                ? 'User profile updated'
                : id !== 'new'
                ? 'User profile updated! Redirecting..'
                : 'User saved and notified! Redirecting..',
              show: true,
            },
            5000
          )
          setTimeout(() => {
            setReload(reload + 1)
            if (id) navigate(-1)
          }, 1000)
        }
      }
    } catch (e) {
      setSaving(false)
      displayMainMessage({ error: 1, message: e.message, show: true }, 5000)
    }
  }

  const notify = async () => {
    setNotifying(true)
    if (await !checkAuthToken()) return navigate('/login')
    try {
      const notified = await axios.post(
        sendVerification,
        { user: profile },
        config
      )
      if (notified) {
        setNotified(true)
        setNotifying(false)
        setTimeout(() => {
          navigate(-1)
        }, 4000)
      }
    } catch (e) {
      setNotifying(false)
      displayMainMessage(
        { error: 1, message: 'Error notifying user!', show: true },
        5000
      )
    }
  }

  const lock = async () => {
    setLocking(true)
    try {
      if (await !checkAuthToken()) return navigate('/login')
      const locked = await axios.patch(
        getUsersUrl + id,
        { isLocked: !profile.isLocked },
        config
      )
      if (locked) {
        setProfile((prev) => ({ ...prev, isLocked: !prev.isLocked }))
        setLocking(false)
        displayMainMessage(
          {
            success: 1,
            message: `User ${profile.isLocked ? 'unlocked' : 'locked'}`,
            show: true,
          },
          5000
        )
      }
    } catch (e) {
      setLocking(false)
      displayMainMessage(
        {
          error: 1,
          message: `Error while ${profile.isLocked ? 'unlocking' : 'locking'}`,
          show: true,
        },
        5000
      )
    }
  }

  //HELPER FUNCTIONS
  const handleChange = (v, target) => {
    setUpdate(true)
    if (target) setProfile((prev) => ({ ...prev, [target]: v }))
    else setProfile((prev) => ({ ...prev, role: v }))
  }

  const checkPassword = () => {
    if (profile.password) {
      return /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/.test(
        profile.password
      )
        ? 'border-l-4 border-green-500'
        : 'border-l-4 border-red-500'
    } else {
      return 'border-l-4 border-gray'
    }
  }
  const checkConfirmPassword = () => {
    if (profile.password) {
      return profile.password === profile.confirmPassword
        ? 'border-l-4 border-green-500'
        : 'border-l-4 border-red-500'
    } else {
      return 'border-l-4 border-gray'
    }
  }
  const checkEmail = (email) => {
    return /[^@ \t\r\n]+@[^@ \t\r\n]+\.[^@ \t\r\n]+/.test(email)
  }

  //COMPONENT RENDER
  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div className='h-full bg-white flex flex-col items-center justify-start w-full max-w-xl mx-auto'>
          <div className='flex flex-col w-full gap-4'>
            <div className='w-full pt-4 flex items-center justify-center'>
              <div
                className={`rounded-full w-20 h-20 border-2 border-${
                  roles.filter((role) => role.value === profile.role)[0]
                    .color || 'secondaryBlue'
                } flex justify-center items-center`}
              >
                <p className='text-4xl text-roboto'>
                  {avatarInitials(profile?.name || ' ')}
                </p>
              </div>
            </div>
            <div className='md:flex'>
              <div className='w-full px-3 mb-6 md:mb-0'>
                <label
                  className='block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2'
                  htmlFor='name'
                >
                  Name
                </label>
                <input
                  className='appearance-none block w-full bg-grey-lighter text-grey-darker border border-red py-3 px-4 mb-3'
                  id='name'
                  disabled={profile.isLocked}
                  type='text'
                  value={profile.name}
                  onChange={(e) => handleChange(e.target.value, 'name')}
                />
              </div>
            </div>
            <div className='md:flex'>
              {id !== 'new' ? (
                <div className='relative md:w-full px-3'>
                  <label
                    className='block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2'
                    htmlFor='email'
                  >
                    Email
                  </label>
                  <input
                    className='appearance-none block w-full bg-grey-lighter text-grey-darker border py-3 px-4'
                    id='email'
                    type='text'
                    value={profile.email}
                    disabled
                  />
                  {profile.isEmailVerified ? (
                    <ShieldCheckIcon className='absolute top-8 right-10 w-8 h-8 text-green-500' />
                  ) : (
                    <ShieldExclamationIcon className='absolute top-8 right-10 w-8 h-8 text-red-500' />
                  )}
                </div>
              ) : (
                <div className='md:w-full px-3 mb-2'>
                  <label className='block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2'>
                    Email
                  </label>
                  <input
                    className={`${
                      selectedTab === 0
                        ? ''
                        : !checkEmail(profile.email)
                        ? 'border-l-4 border-red-500'
                        : 'border-l-4 border-green-500'
                    } appearance-none outline-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter py-3 px-4 mb-3`}
                    type='text'
                    onChange={(e) => {
                      handleChange(e.target.value, 'email')
                    }}
                    value={profile.email}
                  />
                </div>
              )}
            </div>
            {!id ? (
              <div className='relative md:w-full px-3'>
                <div className='md:flex'>
                  <label
                    className='block uppercase tracking-wide text-grey-darker text-lg font-bold mb-2'
                    htmlFor='password'
                  >
                    Set a new password
                  </label>
                </div>
                <div className='md:flex'>
                  <div className='md:w-full'>
                    <label
                      className='block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2'
                      htmlFor='password'
                    >
                      Password
                    </label>
                    <input
                      className={`${checkPassword()} appearance-none outline-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter py-3 px-4 mb-3`}
                      id='password'
                      type='password'
                      autoComplete='new-password'
                      onChange={(e) => handleChange(e.target.value, 'password')}
                      placeholder='******************'
                    />
                  </div>
                  <div className='md:w-full px-3'>
                    <label
                      className='block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2'
                      htmlFor='password'
                    >
                      Confirm Password
                    </label>
                    <input
                      className={`${checkConfirmPassword()} appearance-none outline-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter py-3 px-4 mb-3`}
                      id='confirmpassword'
                      type='password'
                      autoComplete='new-password'
                      onChange={(e) =>
                        handleChange(e.target.value, 'confirmPassword')
                      }
                      placeholder='******************'
                    />
                  </div>
                </div>
                <div className='text-xs'>
                  *Minimum eight characters, at least one upper case letter, one
                  lower case letter and one number
                </div>
              </div>
            ) : profile.isLocked ? (
              <div className=' flex justify-center items-center space-x-4 w-full'>
                <ExclamationTriangleIcon className='w-8 h-8 text-red-500' />
                <p className=' '>Access has been locked for this account</p>
              </div>
            ) : (
              <div className='md:flex'>
                <div className='md:w-full px-3'>
                  <label className='block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2'>
                    Role
                  </label>
                  <div className='w-full'>
                    <OptionList
                      options={roles}
                      choice={profile.role}
                      setChoice={handleChange}
                    />
                  </div>
                </div>
              </div>
            )}
            <div className='py-4 flex flex-col-reverse sm:flex-row items-center justify-around '>
              {!profile.isEmailVerified && id !== 'new' && !update ? (
                <button
                  className='button-secondary'
                  onClick={() => notify()}
                  disabled={notified}
                >
                  {notifying ? (
                    <div className='flex justify-center'>
                      <div
                        style={{ borderTopColor: 'transparent' }}
                        className='w-6 h-6 border-4 border-white border-dotted rounded-full animate-spin'
                      ></div>
                    </div>
                  ) : notified ? (
                    <>
                      <ShieldCheckIcon className='w-6 h-6' />
                      <span>User notified</span>
                    </>
                  ) : (
                    <>
                      <ShieldCheckIcon className='w-6 h-6' />
                      <span>Verify Email</span>
                    </>
                  )}
                </button>
              ) : id === 'new' ? (
                <button
                  className='button-secondary'
                  onClick={() => {
                    navigate(-1)
                  }}
                >
                  Cancel
                </button>
              ) : (
                ''
              )}
              {id !== 'new' && id && !update ? (
                <button className='button-secondary' onClick={() => lock()}>
                  {locking ? (
                    <div className='flex justify-center'>
                      <div
                        style={{ borderTopColor: 'transparent' }}
                        className='w-6 h-6 border-4 border-white border-dotted rounded-full animate-spin'
                      ></div>
                    </div>
                  ) : profile.isLocked ? (
                    <>
                      <LockOpenIcon className='w-6 h-6' />
                      <span>Unlock Access</span>
                    </>
                  ) : (
                    <>
                      <LockClosedIcon className='w-6 h-6' />
                      <span>Lock Access</span>
                    </>
                  )}
                </button>
              ) : (
                ''
              )}
              {!profile.isLocked && update && (
                <button className='button-primary' onClick={() => save()}>
                  {saving ? (
                    <div className='flex justify-center'>
                      <div
                        style={{ borderTopColor: 'transparent' }}
                        className='w-6 h-6 border-4 border-white border-dotted rounded-full animate-spin'
                      ></div>
                    </div>
                  ) : id === 'new' ? (
                    <>
                      <CheckCircleIcon className='w-6 h-6' />
                      <span>Save</span>
                    </>
                  ) : (
                    <>
                      <CheckCircleIcon className='w-6 h-6' />
                      <span>Update</span>
                    </>
                  )}
                </button>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default Profile
