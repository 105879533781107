import axios from 'axios'
import { useContext, useEffect, useState } from 'react'
import AdminContext from '../../../contexts/Admin'
import { getResourcesUrl, downloadResourcesUrl } from '../Config/APIurls'
import Loading from '../../Common/Widgets/Loading'
import Table from '../Widgets/Table'
import { PlusIcon } from '@heroicons/react/24/outline'
import Resource from './Resource'
import AddResource from './AddResource'
import SearchBar from '../Widgets/SearchBar'
import SearchList from '../Widgets/SearchList'

const resourceHeaders = [
  {
    title: 'Name',
    key: 'name',
    style: 'w-3/6 md:w-2/6 text-center',
    rstyle:
      'w-full truncate whitespace-nowrap mx-auto py-1 px-4 text-sm text-center capitalize',
    type: 'text',
  },
  {
    title: 'Type',
    key: 'type',
    style: 'w-2/6 md:w-1/6 flex flex-wrap items-center overflow-hidden',
    rstyle:
      'max-w-fit max-h-6 mr-1 whitespace-nowrap px-2 py-0.5 rounded-sm text-xs border justify-center items-center flex bg-secondaryBlue border-sky-300 text-primaryBlue',
    type: 'array',
  },
  {
    title: 'Industry',
    key: 'industries',
    style: 'w-1/6 hidden md:flex flex-wrap items-center overflow-hidden',
    rstyle:
      'max-w-fit max-h-6 mr-1 whitespace-nowrap px-2 py-0.5 rounded-sm text-xs border justify-center items-center flex bg-secondaryPink border-pink-400 text-secondaryMagenta',
    type: 'array',
  },
  {
    title: 'Language',
    key: 'languages',
    style: 'w-1/6  hidden md:flex items-center overflow-hidden px-2',
    rstyle:
      'max-w-fit max-h-6 mr-1 whitespace-nowrap px-2 py-0.5 rounded-sm text-xs border justify-center items-center flex bg-gray-200 border-gray-400',
    type: 'array',
  },
  {
    title: '',
    key: '$edit',
    style: 'w-1/6 text-center',
    rstyle:
      'mx-auto py-1 px-2 text-sm lg:text-base text-center w-max flex justify-center items-center capitalize',
    type: 'text',
  },
]

const defaultFilters = {
  audiences: {
    name: 'Audiences',
    search: [],
    show: false,
  },
  offers: {
    name: 'Offers',
    search: [],
    show: false,
  },
  states: {
    name: 'States',
    search: [],
    show: false,
  },
  industries: {
    name: 'Industry',
    search: [],
    show: false,
  },
}

const audiencesTerms = [
  {
    term: 'Black',
    values: [
      'Black entrepreneurs',
      'Black people',
      'Black womxn',
      'Black FLINTA',
      'Black investor',
    ],
  },

  { term: 'Muslim', values: 'Muslim FLINTA' },
  { term: 'PoC', values: ['PoC', 'Latinx', 'PoC womxn', 'FLINTA LATINX'] },
  {
    term: 'Queer / LGBTQIA+',
    values: ['LGBTQIA+', 'LGBTQIA+ YOUTH', 'Non-binary', 'Trans & inter'],
  },
  {
    term: 'Latin/a/o/x',
    values: ['Latinx', 'Latinx womxn', 'FLINTA LATINX', 'Spanish Speakers'],
  },
  {
    term: 'Migrant',
    values: [
      'Migrant organisations',
      'Migrant organisations by women',
      'Migrant womxn',
      'Migrants',
    ],
  },
  {
    term: 'Rom:nja und Sinti:zze',
    values: ['Rom:nja', 'Rom:nja und Sinti:zze'],
  },
  { term: 'People w/ Disability', values: ['People w/ Disability'] },
  { term: 'Undocumented Refugees', values: ['Undocumented refugees'] },
  { term: 'CIS-Women', values: ['Women', 'Women entrepreneurs'] },
  {
    term: 'TIN + all women',
    values: ['FLINTA', 'Non-binary', 'Trans & inter'],
  },
]
const industriesTerms = [
  { term: 'Creative', values: ['creativity', 'Creative industries'] },
  { term: 'Social', values: ['Social Business', 'Social Entrepreneurship'] },
  { term: 'Tech', values: ['Tech', 'Tech Entrepreneurship'] },
]

const Resources = () => {
  const {
    config,
    reload,
    checkAuthToken,
    navigate,
    currentParams,
    displayMainMessage,
  } = useContext(AdminContext)
  const [loading, setLoading] = useState(true)
  const [currentPage, setCurrentPage] = useState(1)
  const [data, setData] = useState({ results: [], totalPages: 0 })
  const [downloading, setDownloading] = useState(false)
  const [search, setSearch] = useState('')
  const [filters, setFilters] = useState(defaultFilters)

  const { id } = currentParams

  //clear filters
  const reset = () => {
    setSearch('')
    setFilters(defaultFilters)
  }
  // QUERY CONSTRUCT
  const constructApiQuery = (searchTerm) => {
    try {
      let query = ''
      // Check if the searchTerm is provided and append it to the query string

      // Initialize an empty array to store sanitized query parameters
      const sanitizedQueryParams = []
      // Go through filters
      for (const key of Object.keys(filters)) {
        if (filters[key].search.length > 0) {
          if (key === 'audiences') {
            // Audiences and Industries are a special case, as they are stored in a different format
            const audiencesQuery = audiencesTerms
              .filter((audienceTerm) =>
                filters.audiences.search.includes(audienceTerm.term)
              )
              .map((audienceTerm) => audienceTerm.values.toString())
              .join(',')

            if (audiencesQuery.length > 0) {
              sanitizedQueryParams.push(
                `audiences=${encodeURIComponent(audiencesQuery)}`
              )
            }
          } else if (key === 'industries') {
            const industriesQuery = industriesTerms
              .filter((industryTerm) =>
                filters.industries.search.includes(industryTerm.term)
              )
              .map((industryTerm) => industryTerm.values.toString())
              .join(',')

            if (industriesQuery.length > 0) {
              sanitizedQueryParams.push(
                `industries=${encodeURIComponent(industriesQuery)}`
              )
            }
          } else {
            sanitizedQueryParams.push(
              `${key}=${encodeURIComponent(filters[key].search.toString())}`
            )
          }
        }
      }
      if (searchTerm) {
        sanitizedQueryParams.push(
          `&description=${encodeURIComponent(
            searchTerm
          )}&name=${encodeURIComponent(searchTerm)}`
        )
      }
      query += sanitizedQueryParams.join('&')
      return query
    } catch (err) {
      console.log(err)
    }
  }

  //Get all resources and set the result as data
  useEffect(() => {
    const fetchData = async () => {
      try {
        if (await !checkAuthToken()) return navigate('/login')
        const query = constructApiQuery(search)
          ? '?sortBy=name&page=' + currentPage + '&' + constructApiQuery(search)
          : '?sortBy=name&page=' + currentPage
        const response = await axios.get(getResourcesUrl + query, config)
        setData(response.data)
        setCurrentPage(response.data.page)
        setLoading(false)
      } catch (err) {
        displayMainMessage(
          {
            error: 1,
            message: err.status
              ? err.response.message
              : 'Something went wrong getting the data - please try again',
            show: true,
          },
          5000
        )
      }
    }
    fetchData()
  }, [reload, currentPage, search])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const query = constructApiQuery(search)
          ? '?sortBy=name&page=1&' + constructApiQuery(search)
          : '?sortBy=name&page=1'
        const response = await axios.get(getResourcesUrl + query, config)
        setData(response.data)
        setCurrentPage(response.data.page)
        setLoading(false)
      } catch (err) {
        displayMainMessage(
          {
            error: 1,
            message: err.status
              ? err.response.message
              : 'Something went wrong getting the data - please try again',
            show: true,
          },
          5000
        )
      }
    }
    fetchData()
  }, [filters])

  const downloadCSV = (apiResponse, filename) => {
    const headers = Object.keys(apiResponse[0])

    const csvData =
      'data:text/csv;charset=utf-8,' +
      headers.join(',') +
      '\n' +
      apiResponse
        .map((row) =>
          headers
            .map((header) =>
              typeof row[header] === 'string' && row[header].includes(',')
                ? `"${row[header].replace(/"/g, '""')}"`
                : row[header]
            )
            .join(',')
        )
        .join('\n')
    // Add current date and time to filename
    const currentDate = new Date()
      .toLocaleDateString('en-GB', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
      })
      .slice(0, 10)
    const currentTime = new Date().toLocaleTimeString().replace(/:/g, '-')
    filename += `_${currentDate}-${currentTime}`
    const encodedUri = encodeURI(csvData)
    const link = document.createElement('a')
    link.setAttribute('href', encodedUri)
    link.setAttribute('download', filename + '.csv')
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
    setDownloading(false)
  }
  const handleDownloadClick = async () => {
    setDownloading(true)
    if (await !checkAuthToken()) return navigate('/login')
    const apiResponse = await axios.get(downloadResourcesUrl, config)
    try {
      if (apiResponse.data.length) {
        downloadCSV(apiResponse.data, 'members')
      } else {
        setDownloading(false)
        displayMainMessage(
          {
            error: 1,
            message: 'No data to download',
            show: true,
          },
          5000
        )
      }
    } catch (err) {
      setDownloading(false)
      displayMainMessage(
        {
          error: 1,
          message: err.status
            ? err.response.message
            : 'Something went wrong while downloading the data - please try again',
          show: true,
        },
        5000
      )
    }
  }

  return (
    <div className='relative h-full w-full flex px-4 md:px-8 items-center flex-col bg-white outline-none overflow-y-scroll'>
      {loading ? (
        <div className='w-full flex justify-center items-center'>
          <Loading />
        </div>
      ) : id ? (
        <>{id === 'new' ? <AddResource /> : <Resource />}</>
      ) : (
        <div className='w-full flex flex-col gap-2'>
          <div className='w-full flex flex-col items-start gap-2 sm:flex-row'>
            <button
              className='button-primary'
              onClick={() => navigate('resources/new')}
            >
              <PlusIcon className='h-5 w-5' />
              <span className='text-mono text-sm'>New Resource</span>
            </button>
            <button
              disabled={downloading}
              className='group relative button-primary'
              onClick={handleDownloadClick}
            >
              <div
                role='status'
                className={`absolute inset-y-2.5 left-1 ${
                  downloading ? 'inline-block' : 'hidden'
                }`}
              >
                <svg
                  aria-hidden='true'
                  className='w-4 h-4 mr-2 text-black group-hover:text-white animate-spin fill-secondaryPink group-hover:fill-secondaryMagenta'
                  viewBox='0 0 100 101'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z'
                    fill='currentColor'
                  />
                  <path
                    d='M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z'
                    fill='currentFill'
                  />
                </svg>
                <span className='sr-only'>Loading...</span>
              </div>
              <span className='ml-2'>Download CSV</span>
            </button>
          </div>
          <div className='w-full'>
            <SearchBar
              search={search}
              setSearch={setSearch}
              placeholder={'Search by name or keyword in description'}
            />
          </div>
          <div className='w-full'>
            <SearchList
              reset={reset}
              filters={filters}
              setFilters={setFilters}
              location={'resources'}
            />
          </div>
          <Table
            title=''
            data={data.results}
            headers={resourceHeaders}
            pageCount={data.totalPages}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            link={'/admin/resources/'}
          />
        </div>
      )}
    </div>
  )
}

export default Resources
