import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/24/outline'
import { useField, useFormikContext } from 'formik'
import { Fragment } from 'react'

const OptionList = ({ label, dataList, name }) => {
  const [field, meta] = useField({ label, name })

  const {
    setFieldValue,
    touched: { [name]: touched },
    errors: { [name]: error },
  } = useFormikContext()

  const changeValue = (value) => {
    setFieldValue(name, value, true)
  }

  return (
    <>
      <Listbox name={name} onChange={changeValue}>
        <div className={'relative w-full bg-white'}>
          <Listbox.Button
            className={`${
              !touched && meta.value === ''
                ? 'border border-black text-lightgray'
                : error
                ? 'border border-invalid text-lightgray'
                : meta.value !== '' && meta.value
                ? 'border border-valid'
                : 'border border-black'
            } relative w-full h-12 py-3 px-4 pr-10 text-left bg-white-500 cursor-default focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-white focus-visible:ring-offset-orange-300 focus-visible:ring-offset-2 focus-visible:border-indigo-500`}
            {...field}
          >
            <span className='flex-none truncate text-lg'>
              {dataList.filter((item) => item.value === meta.value)[0]?.label}
            </span>
            <span className='absolute inset-y-0 right-0 flex items-center justify-end pr-2 pointer-events-none w-full text-2xl appearance-none bg-grey-50 text-grey-500 border p-3'>
              <ChevronDownIcon
                className='w-5 h-5 text-gray-600 '
                aria-hidden='true'
              />
            </span>
          </Listbox.Button>
          <Transition
            as={Fragment}
            leave='transition ease-in duration-100'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <Listbox.Options className='absolute z-40 w-full py-1 mt-1 overflow-auto text-base bg-white shadow-lg max-h-40 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm'>
              {dataList.map((choice, index) => (
                <Listbox.Option
                  key={index + choice.value}
                  className={({ active }) =>
                    `${
                      active
                        ? "text-primaryBlue bg-primaryBlue bg-opacity-20 '"
                        : 'text-gray-900 w-full'
                    }
              cursor-default select-none relative py-2 pl-10 pr-4`
                  }
                  value={choice.value}
                >
                  {({ selected, active }) => (
                    <>
                      <div
                        className={`${
                          selected ? 'text-md' : 'font-normal'
                        } flex justify-between truncate`}
                      >
                        <span>{choice.label}</span>
                      </div>
                      {selected ? (
                        <span className='text-black absolute inset-y-0 left-0 flex items-center ml-3'>
                          <CheckIcon className='w-5 h-5' aria-hidden='true' />
                        </span>
                      ) : null}
                    </>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
      {touched && error ? (
        <p className='text-red-500 text-sm mt-1'>{meta.error}</p>
      ) : null}
    </>
  )
}

export default OptionList
