import { useContext, useState } from 'react'
import MainContext from '../../../contexts/Main'
import { Link } from 'react-router-dom'

const CookieNotice = () => {
  const { cookies, setCookie } = useContext(MainContext)
  const [show, setShow] = useState(true)

  const acceptCookies = () => {
    setCookie('privacyConsent', true, { path: '/' })
    setCookie('privacyScreened', true, { path: '/' })
    setShow(false)
  }
  const declineCookies = () => {
    setCookie('privacyConsent', false, { path: '/' })
    setCookie('privacyScreened', true, { path: '/' })
    setShow(false)
  }

  if (!show || cookies.privacyScreened) return null
  return (
    <div className='w-full fixed bottom-0 flex justify-between gap-4 items-center bg-white border-t border-black px-8 py-2 z-50'>
      <span className='flex-grow text-sm'>
        This website uses cookies to ensure you get the best experience.{' '}
        <Link to='/privacy-policy' className='underline'>
          Learn more
        </Link>
      </span>
      <button
        className='button primary'
        onClick={() => {
          acceptCookies()
        }}
      >
        Accept
      </button>
      <button
        className='button secondary'
        onClick={() => {
          declineCookies()
        }}
      >
        Decline
      </button>
    </div>
  )
}

export default CookieNotice
