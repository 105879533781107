import axios from 'axios'
import { useContext, useEffect, useState } from 'react'
import { getResourceUrl } from '../../Config/APIurls'
import MainContext from '../../../../contexts/Main'
import { MapPinIcon, XMarkIcon } from '@heroicons/react/24/solid'
import { ReactComponent as StarSymbol } from '../../../../assets/elements/star.svg'
import Loading from '../../../Common/Widgets/Loading'
import Tag from './Tag'
import { FormattedMessage } from 'react-intl'

const styles = {
  industries: {
    tag: 'bg-secondaryPink border-pink-400',
    text: 'text-secondaryMagenta',
  },
  states: {
    tag: 'bg-secondaryLilac border-violet-400',
    text: 'text-primaryBlue',
  },
  type: {
    tag: 'bg-secondaryBlue border-sky-300',
    text: 'text-primaryBlue',
  },
  offers: {
    tag: 'bg-primaryBlue border-primaryBlue',
    text: 'text-white',
  },
  audiences: {
    tag: 'bg-primaryBlue border-primaryBlue',
    text: 'text-white',
  },
}

const ResourceCard = ({ id, display, setDisplay }) => {
  const { config } = useContext(MainContext)
  const [loading, setLoading] = useState(true)
  const [resource, setResource] = useState([])
  const [error, setError] = useState(null)
  useEffect(() => {
    const getResource = async () => {
      try {
        const { data } = await axios.get(getResourceUrl + id, config)
        if (!data) {
          // if the data is empty
          setError('No data found')
          setLoading(false)
          return
        }
        setResource(data)
        setLoading(false)
      } catch (err) {
        setError(
          'Error getting the data, please refresh the page and try again'
        )
      }
    }
    setLoading(true)
    if (id) getResource()
  }, [id])

  return (
    <div className='relative w-full h-full p-6 lg:p-10 bg-stone-50 border border-black flex-col justify-start items-start gap-6 inline-flex oveflow-y-scroll'>
      <XMarkIcon
        className='absolute top-4 right-4 h-6 w-6 hover:text-primaryBlue cursor-pointer'
        onClick={() => {
          setDisplay(false)
        }}
      />
      {loading ? (
        <Loading />
      ) : (
        <>
          <div className='w-full relative justify-between items-start inline-flex'>
            <div className='w-full flex-col justify-start items-start gap-6 inline-flex'>
              <div className='flex flex-col justify-start items-start gap-2'>
                <div className='text-black text-2xl font-bold'>
                  {resource.name}
                </div>
                {resource?.location && (
                  <div className='justify-center items-center gap-1 inline-flex'>
                    <MapPinIcon className='w-6 h-6 relative' />
                    <div className='text-stone-900 text-base font-normal leading-normal'>
                      {resource?.location}
                    </div>
                  </div>
                )}
              </div>
              <div className='flex flex-col justify-start items-start gap-2'>
                <div className='flex justify-start gap-2'>
                  <span className='w-26 text-neutral-500 text-sm font-normal leading-tight'>
                    <FormattedMessage
                      id='resources.states'
                      defaultMessage='States'
                    />
                  </span>
                  <div className='flex flex-wrap gap-1'>
                    {resource.states?.map((state) => (
                      <Tag tag={state} style={styles.states} />
                    ))}
                  </div>
                </div>
                <div className='flex justify-start gap-2'>
                  <span className='w-26 text-neutral-500 text-sm font-normal leading-tight'>
                    <FormattedMessage
                      id='resources.types'
                      defaultMessage='Types'
                    />
                  </span>
                  <div className='flex flex-wrap gap-1'>
                    {resource.type?.map((type) => (
                      <Tag tag={type} style={styles.type} />
                    ))}
                  </div>
                </div>
                <div className='flex justify-start gap-2'>
                  <span className='w-26 text-neutral-500 text-sm font-normal leading-tight'>
                    <FormattedMessage
                      id='resources.industries'
                      defaultMessage='Industries'
                    />
                  </span>
                  <div className='flex flex-wrap gap-1'>
                    {resource.industries?.map((industry) => (
                      <Tag tag={industry} style={styles.industries} />
                    ))}
                  </div>
                </div>
              </div>
              <div className='flex flex-col justify-start items-start gap-2'>
                <div className='flex justify-start gap-2'>
                  <span className='w-28 text-black text-base font-medium leading-tight'>
                    <FormattedMessage
                      id='resources.offers'
                      defaultMessage='Offers'
                    />
                  </span>
                  <div className='flex flex-wrap gap-1'>
                    {resource.offers?.map((offer) => (
                      <Tag tag={offer} style={styles.offers} />
                    ))}
                  </div>
                </div>
                <div className='flex justify-start gap-2'>
                  <span className='w-28 text-black text-base font-medium leading-tight'>
                    <FormattedMessage
                      id='resources.audiences'
                      defaultMessage='Audiences'
                    />
                  </span>
                  <div className='flex flex-wrap gap-1'>
                    {resource.audiences.map((audience) => (
                      <Tag tag={audience} style={styles.audiences} />
                    ))}
                  </div>
                </div>
                {/* {resource.notAudiences.length > 0 && (
                  <div className='flex justify-start gap-2'>
                    <span className='w-fit text-black text-base font-medium leading-tight'>
                      <FormattedMessage
                        id='resources.notAudiences'
                        defaultMessage='Not Audiences'
                      />
                    </span>
                    <div className='flex flex-wrap gap-1'>
                      {resource.notAudiences.map((audience) => (
                        <Tag tag={audience} style={styles.notAudiences} />
                      ))}
                    </div>
                  </div>
                )} */}
              </div>
            </div>
            <div className='w-20 h-20 hidden sm:flex absolute top-2 right-2 '>
              {resource?.image?.url ? (
                <img
                  className='w-32 absolute text-center text-black text-base font-normal leading-normal'
                  src={resource?.image?.url}
                  alt='logo'
                />
              ) : (
                <StarSymbol className='w-12 h-12 md:w-20 md:h-20 rotate-45 fill-primaryBlue  stroke-black' />
              )}
            </div>
          </div>
          <div className='flex-col justify-start items-start gap-4 flex'>
            <div className='w-full text-black text-base font-medium'>
              <FormattedMessage id='resources.about' defaultMessage='About' />
            </div>
            <div className='max-w-lg'>
              <span className='text-black text-sm font-normal leading-tight'>
                {resource.description}
              </span>
            </div>
          </div>
          <div className='w-full justify-center items-center gap-8 inline-flex'>
            <a
              className='w-72 h-8 px-4 py-2 bg-stone-900 rounded-full justify-center items-center gap-2 flex'
              href={resource.link}
              target='_blank'
              rel='noreferrer'
            >
              <div className='text-center text-stone-50 text-sm font-normal leading-tight'>
                <FormattedMessage
                  id='resources.visitCTA'
                  defaultMessage='Visit their website'
                />
              </div>
            </a>
          </div>
        </>
      )}
    </div>
  )
}

export default ResourceCard
