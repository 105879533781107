import Row from './Row'
import Pagination from './Pagination'
import { FormattedMessage } from 'react-intl'

const Table = ({
  data,
  headers,
  link,
  pageCount,
  currentPage,
  setCurrentPage,
}) => {
  return (
    <div className='w-full flex flex-col'>
      <div className='flex-grow w-full'>
        <div className='flex flex-col w-full h-full bg-white border border-black '>
          {data.length ? (
            data.map((row, i) => (
              <Row key={row.id} item={row} headers={headers} link={link} />
            ))
          ) : (
            <div
              colSpan={headers.length}
              className='flex-grow py-3 px-5 text-left'
            >
              <div className='flex flex-col h-full items-center justify-center'>
                <p className='w-full text-center px-8 mt-4 lg:w-1/2'>
                  <FormattedMessage
                    id='resources.notFound1'
                    defaultMessage="I'm sorry, but I couldn't find anything under the keywords you
                  provided. However, we are continuously adding to our database,
                  so please try again later. 😊"
                  />
                </p>
                <p className='w-full text-center px-8 mt-4 lg:w-1/2'>
                  <FormattedMessage
                    id='resources.notFound2'
                    defaultMessage='If you know of any resources that might be helpful for these
                  keywords, please share them with us and we will add them to
                  the database.'
                  />
                </p>
                <a
                  href='mailto:hallo@bravespaces.de'
                  className='font-bold underline'
                >
                  hallo@bravespaces.de
                </a>
              </div>
            </div>
          )}
        </div>
      </div>
      {pageCount > 1 && (
        <div className='flex mt-10 items-center justify-center'>
          <Pagination
            setPage={setCurrentPage}
            currentPage={currentPage}
            pageCount={pageCount}
          />
        </div>
      )}
    </div>
  )
}

export default Table
