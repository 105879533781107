import { useContext, useEffect, useState } from 'react'
import { TrashIcon, PencilIcon } from '@heroicons/react/24/outline'
import moment from 'moment'
import axios from 'axios'
import AdminContext from '../../../contexts/Admin'
import AddPost from './AddPost'
import 'suneditor/dist/css/suneditor.min.css'
import Loading from '../../Common/Widgets/Loading'
import { getPostUrl } from '../Config/APIurls'
import EditorView from './components/EditorView'
import { EyeIcon } from '@heroicons/react/24/solid'
import ComponentModal from '../Widgets/ComponentModal'
import DeletePost from './DeletePost'

const Post = () => {
  const { config, reload, user, currentParams, setShowModal } =
    useContext(AdminContext)
  const [data, setData] = useState({})
  const [edit, setEdit] = useState(false)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const { id } = currentParams
  //GET DATA FROM DB WITH articleId FROM URL
  useEffect(() => {
    axios
      .get(getPostUrl + id, config)
      .then((res) => {
        if (res.data) {
          setData(res.data)
          setLoading(false)
        } else {
          setError('No ressource found with this ID')
        }
      })
      .catch((err) => {})
  }, [id, reload])
  return (
    <section className='w-full flex justify-center'>
      {loading ? (
        <Loading />
      ) : error ? (
        error
      ) : edit ? (
        <AddPost post={data} edit={edit} setEdit={setEdit} />
      ) : (
        <>
          <ComponentModal>
            <DeletePost post={data} />
          </ComponentModal>
          <div className=' w-full xl:w-5/6 2xl:w-4/6 shadow-lg border-0 p-4'>
            <img
              className='w-full h-96 object-cover'
              src={data.image ? data.image.url : '/images/placeholder.png'}
              alt='cover'
            />
            <div className='w-full flex items-center justify-between bg-black text-white'>
              <span className='flex-grow text-4xl font-semibold leading-tight p-4'>
                {data.title}
              </span>
              <div className='flex items-center space-x-2 p-4'>
                <EyeIcon className='h-5 w-5 text-white' />
                <span className='text-white text-sm font-semibold'>
                  {data.views}
                </span>
              </div>
            </div>
            <div className='w-full mx-auto p-2 flex justify-center items-center'>
              <EditorView content={data.content} disable={true} />
            </div>
            <div className='w-full mx-auto p-2 flex flex-col justify-start items-center'>
              <div className='w-full flex justify-between items-center'>
                <div className='flex space-x-2 items-end'>
                  {data.author.image && (
                    <img
                      src={data.author.image.url}
                      alt='author'
                      className='w-14 h-14 flex items-center justify-center bg-blue-500 rounded-full text-white'
                    />
                  )}
                  <span className='text-sm'>Written by {data.author.name}</span>
                  {data.author.pronouns && (
                    <span className='text-xs'>{data.author.pronouns}</span>
                  )}
                </div>

                {data.author.link && (
                  <div className=''>
                    <a
                      href={data.author.link}
                      target='_blank'
                      rel='noreferrer'
                      className='text-secondaryMagenta text-sm cursor-pointer'
                    >
                      Follow {data.author.name}
                    </a>
                  </div>
                )}
              </div>

              {data.author.bio && (
                <div className='flex justify-start w-1/2 mr-auto text-xs text-justify mt-2'>
                  {data.author.bio}
                </div>
              )}
            </div>
            <div className='w-full flex justify-center items-center my-8'>
              <div className='w-full flex justify-between items-center'>
                {data.tags.map((tag, index) => (
                  <div
                    key={tag + index}
                    className='w-fit pl-1.5 pr-2 py-0.5 bg-purple-300 rounded-sm border border-violet-400 justify-center items-center gap-1.5 flex'
                  >
                    <div className='text-center text-indigo-500 text-xs font-medium capitalize leading-none'>
                      {tag}
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className='max-w-3xl mx-auto border-t-2 border-gray-200'>
              <p className='text-center text-xs py-2'>
                Last updated -{' '}
                {moment(data.articleLastUpdated).format('DD MMMM YYYY')}
              </p>
            </div>
            {user.role.includes('admin') && (
              <div className='px-4 pt-6 flex flex-col sm:flex-row-reverse items-center justify-around '>
                <button
                  className='w-40 flex space-x-4 justify-center items-center button-secondary'
                  onClick={() => {
                    setEdit(true)
                  }}
                >
                  <PencilIcon className='h-5 w-5' />
                  <span>Edit</span>
                </button>
                <button
                  className='w-40 flex space-x-4 justify-center items-center button-primary'
                  onClick={() => {
                    setShowModal(true)
                  }}
                >
                  <TrashIcon className='h-5 w-5' />
                  <span>Delete</span>
                </button>
              </div>
            )}
          </div>
        </>
      )}
    </section>
  )
}

export default Post
