import React, { useContext } from 'react'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import MapDisplay from '../../Common/Widgets/MapDisplay'
import Dropzone from '../Widgets/DropZone'
import AdminContext from '../../../contexts/Admin'
import Tags from '../Widgets/Tags'
import Places from './Places'
import axios from 'axios'
import { createEventUrl, updateEventUrl } from '../Config/APIurls'
import moment from 'moment'
const defaultSuggestionsTags = [
  'health',
  'food',
  'education',
  'finance',
  'sport',
  'conference',
  'fundraiser',
  'masterclass',
]
const EventFormSchema = Yup.object().shape({
  title: Yup.string().required('Title is required'),
  description: Yup.string().required('Description is required'),
  eventCover: Yup.object()
    .shape({
      public_id: Yup.string(),
      url: Yup.string(),
    })
    .required('Cover picture is required'),
  dateStart: Yup.date().required('Start date is required'),
  dateEnd: Yup.date()
    .required('End date is required')
    .min(Yup.ref('dateStart'), 'End date must be after start date'),
  location: Yup.string().when('type', {
    is: (type) => type === 'public',
    then: () =>
      Yup.string().required('A Location name is required for public events'),
  }),
  geoLocation: Yup.object().shape({ lat: Yup.number(), lng: Yup.number() }),
  address: Yup.string().when('type', {
    is: (type) => type === 'public',
    then: () => Yup.string().required('Address is required for public events'),
  }),
  city: Yup.string().when('type', {
    is: (type) => type === 'public',
    then: () => Yup.string().required('City is required for public events'),
  }),
  type: Yup.string()
    .oneOf(['online', 'public', 'private'])
    .required('Type is required'),
  link: Yup.string().url('Link must be a valid URL'),
  tags: Yup.array().of(Yup.string()).nullable(),
  extLink: Yup.string()
    .url('Link must be a valid URL')
    .required('External Link is required to manage the invites'),
})

const AddEvent = ({ event, setEdit }) => {
  const { navigate, config, displayMainMessage, checkAuthToken } =
    useContext(AdminContext)
  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      if (await !checkAuthToken()) return navigate('/login')
      let response
      if (values.id) {
        response = await axios.patch(updateEventUrl + values.id, values, config)
      } else {
        response = await axios.post(createEventUrl, values, config)
      }
      if (response.status === 201 || response.status === 200) {
        displayMainMessage(
          {
            success: 1,
            message: 'Event Saved',
            show: true,
          },
          5000
        )
        setSubmitting(false)
        if (setEdit) {
          setEdit(false)
        } else {
          navigate(`/admin/events/${response.data.id}`)
        }
      } else {
        throw new Error('Something went wrong')
      }
    } catch (error) {
      console.log(error)
      displayMainMessage(
        {
          error: 1,
          message: 'Something went wrong',
          show: true,
        },
        5000
      )
    }
  }

  return (
    <div className='w-full flex-col px-4 md:px-12'>
      <h2 className='text-3xl font-bold mb-4 uppercase'>
        {event ? 'Edit' : 'Add new'} event
      </h2>
      <Formik
        initialValues={
          event || {
            title: '',
            description: '',
            eventCover: null,
            dateStart: '',
            dateEnd: '',
            location: '',
            geoLocation: { lat: 52.5170365, lng: 13.3888599 },
            zoom: 15,
            address: '',
            city: '',
            type: 'online',
            link: '',
            tags: [],
            extLink: '',
          }
        }
        validationSchema={EventFormSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, values, setValues }) => (
          <Form>
            <div className='form-section'>
              <div className='col-span-2'>
                <label htmlFor='title' className='block font-bold mb-2'>
                  Title
                </label>
                <Field
                  type='text'
                  name='title'
                  id='title'
                  className='w-full border-gray-400 border-2 rounded-md p-2'
                />
                <ErrorMessage
                  name='title'
                  component='div'
                  className='text-red-500 text-sm mt-1'
                />
              </div>
            </div>
            <div className='form-section'>
              <div className='form-item'>
                <label htmlFor='dateStart' className='block font-bold mb-2'>
                  Start
                  <span className='ml-2 text-xs'>
                    * Time is CET (Central European Time)
                  </span>
                </label>
                <Field
                  type='datetime-local'
                  name='dateStart'
                  id='dateStart'
                  value={moment(values.dateStart).format('yyyy-MM-DDTHH:mm')}
                  className='w-full border-gray-400 border-2 rounded-md p-2'
                />
                <ErrorMessage
                  name='dateStart'
                  component='div'
                  className='text-red-500 text-sm mt-1'
                />
              </div>
              <div className='form-item'>
                <label htmlFor='dateEnd' className='block font-bold mb-2'>
                  End
                </label>
                <Field
                  type='datetime-local'
                  name='dateEnd'
                  id='dateEnd'
                  value={moment(values.dateEnd).format('yyyy-MM-DDTHH:mm')}
                  className='w-full border-gray-400 border-2 rounded-md p-2'
                />
                <ErrorMessage
                  name='dateEnd'
                  component='div'
                  className='text-red-500 text-sm mt-1'
                />
              </div>
            </div>
            <div className='form-section'>
              <div className='col-span-2'>
                <label htmlFor='description' className='block font-bold mb-2'>
                  Description
                </label>
                <Field
                  component='textarea'
                  name='description'
                  id='description'
                  className='w-full border-gray-400 border-2 rounded-md p-2'
                />
                <ErrorMessage
                  name='description'
                  component='div'
                  className='text-red-500 text-sm mt-1'
                />
              </div>
            </div>
            <div className='form-section'>
              <div className='form-item'>
                <label className='block uppercase tracking-wide text-xs font-bold mb-2'>
                  Cover Photo
                </label>
                <Dropzone
                  classes={`appearance-none outline-none outline-none block w-full border-2 border-gray-300 border-black border-dotted  py-3 px-4 mb-3`}
                  data={values}
                  setData={setValues}
                  type='eventCover'
                  folder='events'
                  edit={event ? true : false}
                />
                <ErrorMessage
                  name='eventCover'
                  component='div'
                  className='text-red-500 text-sm mt-1'
                />
              </div>
              <div className='form-item'>
                <label className='block uppercase tracking-wide text-xs font-bold mb-2'>
                  Tags
                </label>
                <Tags
                  fieldName={'tags'}
                  defaultSuggestions={defaultSuggestionsTags}
                />
              </div>
            </div>
            <div className='form-section'>
              <div
                className={`${
                  values.type !== 'online' ? 'col-span-2' : 'form-item'
                }`}
              >
                <label htmlFor='type' className='block font-bold mb-2'>
                  Type
                </label>
                <Field
                  as='select'
                  name='type'
                  id='type'
                  className='w-full border-gray-400 border-2 rounded-md p-2'
                >
                  <option value='online'>Online</option>
                  <option value='public'>Public</option>
                </Field>
                <ErrorMessage
                  name='type'
                  component='div'
                  className='text-red-500 text-sm mt-1'
                />
              </div>
              {values.type === 'online' && (
                <div className='form-item'>
                  <label htmlFor='link' className='block font-bold mb-2'>
                    Link
                  </label>
                  <Field
                    type='text'
                    name='link'
                    id='link'
                    className='w-full border-gray-400 border-2 rounded-md p-2'
                  />
                  <ErrorMessage
                    name='link'
                    component='div'
                    className='text-red-500 text-sm mt-1'
                  />
                </div>
              )}
            </div>
            {values.type !== 'online' && (
              <div className='form-section'>
                <div className='grid grid-cols-1'>
                  <div className='mb-2 px-2'>
                    <label htmlFor='location' className='block font-bold mb-2'>
                      Location
                    </label>
                    <Field
                      type='text'
                      name='location'
                      id='location'
                      className='w-full border-gray-400 border-2 rounded-md p-2'
                    />
                    <ErrorMessage
                      name='location'
                      component='div'
                      className='text-red-500 text-sm mt-1'
                    />
                  </div>
                  <div className='relative mb-2 px-2'>
                    <label htmlFor='address' className='block font-bold mb-2'>
                      Address
                    </label>
                    <Places />
                    <ErrorMessage
                      name='address'
                      component='div'
                      className='text-red-500 text-sm mt-1'
                    />
                  </div>
                  <div className='mb-2 px-2'>
                    <label htmlFor='city' className='block font-bold mb-2'>
                      City
                    </label>
                    <Field
                      type='text'
                      name='city'
                      id='city'
                      className='w-full border-gray-400 border-2 rounded-md p-2'
                    />
                    <ErrorMessage
                      name='city'
                      component='div'
                      className='text-red-500 text-sm mt-1'
                    />
                  </div>
                </div>
                <div className='hidden sm:block px-3 mb-2'>
                  <MapDisplay
                    location={values.geoLocation}
                    zoom={values.zoom}
                  />
                </div>
              </div>
            )}
            <div className='form-section'>
              <div className='col-span-2'>
                <label htmlFor='dateEnd' className='block font-bold mb-2'>
                  External Link (ie. Eventbrite)
                </label>
                <Field
                  type='text'
                  name='extLink'
                  id='extLink'
                  className='w-full border-gray-400 border-2 rounded-md p-2'
                />
                <ErrorMessage
                  name='extLink'
                  component='div'
                  className='text-red-500 text-sm mt-1'
                />
              </div>
            </div>
            <div className='form-section flex-col-reverse'>
              <button
                type='button'
                disabled={isSubmitting}
                className='button-secondary'
                onClick={() => {
                  navigate(-1)
                }}
              >
                cancel
              </button>
              <button
                type='submit'
                disabled={isSubmitting}
                className='button-primary'
              >
                {isSubmitting ? 'Submitting...' : 'Submit'}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default AddEvent
