import { FormattedMessage } from 'react-intl'
import { ReactComponent as StarSymbol } from '../../../../assets/elements/star.svg'
import { ReactComponent as Instagram } from '../../../../assets/logos/instagramLogoCC.svg'
import { ReactComponent as LinkedIn } from '../../../../assets/logos/linkedin.svg'
import { ReactComponent as Website } from '../../../../assets/logos/website.svg'
import { MapPinIcon } from '@heroicons/react/24/solid'
import { InformationCircleIcon } from '@heroicons/react/24/outline'

const ServiceProviderCard = ({ serviceProvider, noBooking }) => {
  return (
    <div className='w-fit lg:h-[450px] p-6 border border-black flex-col justify-start items-start gap-2 inline-flex'>
      <div className=' w-full lg:h-44 flex flex-col sm:flex-row sm:space-x-2 md:space-x-0 md:flex-col lg:flex-row lg:space-x-2'>
        <div className='relative w-44'>
          {serviceProvider.headshot?.url ? (
            <>
              <img
                className='w-44 aspect-square object-cover border border-black'
                src={serviceProvider.headshot?.url}
                alt='headshot'
              />
              <StarSymbol className='absolute w-12 h-12 lg:w-16 lg:h-16 -bottom-4 -right-4 rotate-[-15.78deg] justify-center items-center gap-2.5 inline-flex fill-secondaryLilac stroke-black' />
            </>
          ) : (
            <div className='w-44 h-44 flex justify-center items-center'>
              <StarSymbol className='w-40 h-40 -rotate-45 fill-secondaryLilac stroke-black ' />
            </div>
          )}
        </div>
        <div className='flex-grow flex-col justify-start items-start flex mt-4 sm:mt-0 md:mt-4 lg:mt-0'>
          <h3 className='m-0'>{serviceProvider.name}</h3>
          <span className='text-indigo-500 text-md font-medium'>
            {serviceProvider.pronouns}
          </span>
          <div className='flex justify-start gap-x-2 my-2'>
            {serviceProvider.companyLink && (
              <a
                href={serviceProvider.companyLink}
                target='_blank'
                rel='noopener noreferrer'
              >
                <Website className='w-6 h-6 lg:w-8 lg:h-8 stroke-black' />
              </a>
            )}

            {serviceProvider.linkedin && (
              <a
                href={serviceProvider.linkedin}
                target='_blank'
                rel='noopener noreferrer'
              >
                <LinkedIn className='w-6 h-6 lg:w-8 lg:h-8' />
              </a>
            )}
            {serviceProvider.instagram && (
              <a
                href={serviceProvider.instagram}
                target='_blank'
                rel='noopener noreferrer'
              >
                <Instagram className='w-6 h-6 lg:w-8 lg:h-8 stroke-black' />
              </a>
            )}
            {serviceProvider.otherSocial && (
              <a
                href={serviceProvider.otherSocial}
                target='_blank'
                rel='noopener noreferrer'
              >
                <Website className='w-6 h-6 lg:w-8 lg:h-8 stroke-black' />
              </a>
            )}
          </div>
          {serviceProvider.location && (
            <div className='w-full flex items-center my-2 gap-x-1'>
              <MapPinIcon className='w-4 h-4' />
              <span className='text-sm'>{serviceProvider.location}</span>
            </div>
          )}
        </div>
      </div>
      <h4 className='mt-0 sm:mt-4 md:mt-0 lg:mt-4 mb-0'>
        {serviceProvider.title}
      </h4>

      <div className='h-44 flex-col justify-start items-center gap-4 flex overflow-y-scroll scrollbar'>
        <div className='flex-col justify-start items-start flex'>
          <p className='m-0 mt-2'>{serviceProvider.bio}</p>
        </div>
        <div className='w-full'>
          <ul className='list-disc list-inside'>
            {serviceProvider.services.map((service) => (
              <li className='list-inside'>
                <span>{service}</span>
              </li>
            ))}
          </ul>
        </div>
        {serviceProvider.disclaimer && (
          <div className='w-full'>
            <span className='flex gap-2 items-center text-sm text-gray-600'>
              <InformationCircleIcon className='h-6 w-6' />
              {serviceProvider.disclaimer}
            </span>
          </div>
        )}
        {!noBooking && (
          <div className='w-full flex flex-grow justify-center items-end'>
            <a
              href={`${
                serviceProvider.bookingType === 'Email' ? 'mailto:' : ''
              }${serviceProvider.bookingLink}`}
              className='button primary small mx-auto px-8 cursor-pointer'
              target='_blank'
              rel='noopener noreferrer'
            >
              <FormattedMessage id='serviceProviders.button' default='Book' />
            </a>
          </div>
        )}
      </div>
    </div>
  )
}

export default ServiceProviderCard
