// API URLS

//EVENTS
export const getEventsUrl = '/api/v1/events/'
export const getEventUrl = '/api/v1/events/id/'

//Resources
export const getResourcesUrl = '/api/v1/resources/'
export const getResourceUrl = '/api/v1/resources/id/'
export const getListsApi = '/api/v1/resources/lists'

//BLOG
export const getBlogPostsUrl = '/api/v1/posts/'
export const getBlogPostUrl = '/api/v1/posts/id/'
export const saveViewUrl = '/api/v1/posts/save-view/'

//OPEN HOURS
export const getServiceProvidersUrl =
  '/api/v1/service-providers/service-providers/'
export const getServiceProviderListsApi = '/api/v1/service-providers/lists'

//UPLOAD
export const uploadImageUrl = '/api/v1/upload/image'
