import { Formik } from 'formik'
import { useContext, useEffect, useState } from 'react'
import * as yup from 'yup'
import axios from 'axios'
import MainContext from '../../../contexts/Main'
import FormTemplate from './FormTemplate'

const formFields = [
  'firstName',
  'lastName',
  'email',
  'pronouns',
  'socials',
  'identifyAs',
  'furtherIdentifyAs',
  'industry',
  'link',
  'location',
]
const identifyAsList = [
  { name: 'someone with an idea', value: 'option0' },
  { name: 'solopreneur', value: 'option1' },
  { name: 'self-employed / freelancer', value: 'option2' },
  { name: '(startup) founder', value: 'option3' },
]
const furtherIdentifyAsList = [
  {
    name: 'Women Of Color (we include Black women, Indigenous women, Women of Color)',
    value: 'option0',
  },
  { name: 'Trans Person of Color', value: 'option1' },
  { name: 'Inter Person of Color', value: 'option2' },
  { name: 'Non-binary Person of Color', value: 'option3' },
  { name: 'Gender-non conforming', value: 'option4' },
  { name: 'Prefer not to say', value: 'option5' },
  { name: 'Other', value: 'option6' },
]
const locationList = [
  { name: 'Outside Germany', value: 'option0' },
  { name: 'Baden-Württemberg', value: 'option1' },
  { name: 'Bayern (Bavaria)', value: 'option2' },
  { name: 'Berlin', value: 'option3' },
  { name: 'Brandenburg', value: 'option4' },
  { name: 'Bremen', value: 'option5' },
  { name: 'Hamburg', value: 'option6' },
  { name: 'Hessen', value: 'option7' },
  { name: 'Nordrhein-Westfalen (North Rhine-Westphalia)', value: 'option8' },
  { name: 'Mecklenburg-Vorpommern', value: 'option9' },
  { name: 'Niedersachsen (Lower Saxony)', value: 'option10' },
  { name: 'Rheinland-Pfalz (Rhineland-Palatinate)', value: 'option11' },
  { name: 'Saarland', value: 'option12' },
  { name: 'Sachsen (Saxony)', value: 'option13' },
  { name: 'Sachsen-Anhalt (Saxony-Anhalt)', value: 'option14' },
  { name: 'Schleswig-Holstein', value: 'option15' },
  { name: 'Thüringen (Thuringia)', value: 'option16' },
]
const signUpURL = '/api/v1/members/'

const SignUpForm = () => {
  const { locale, formElement, scrollToElement } = useContext(MainContext)

  const [success, setSuccess] = useState(false)
  const [error, setError] = useState(null)
  const initialValues = {
    firstName: '',
    lastName: '',
    email: '',
    socials: '',
    pronouns: '',
    identifyAs: '',
    furtherIdentifyAs: '',
    industry: '',
    link: '',
    location: '',
    selectedLocale: locale,
  }
  const validationSchema = yup.object().shape({
    firstName: yup.string().required('requirederror'),
    lastName: yup.string().required('requirederror'),
    email: yup.string().email('invalidemailerror').required('requirederror'),
    pronouns: yup.string().nullable(),
    socials: yup.string(),
    identifyAs: yup.string().oneOf(
      identifyAsList.map((option) => option.value),
      'optionerror'
    ),
    furtherIdentifyAs: yup.string().oneOf(
      furtherIdentifyAsList.map((option) => option.value),
      'optionerror'
    ),
    industry: yup.string().nullable(),
    link: yup.string().nullable(),
    location: yup
      .string()
      .oneOf(
        locationList.map((option) => option.value),
        'optionerror'
      )
      .required('requirederror'),
  })

  const submitForm = async (values, actions) => {
    try {
      let submittedValues = { ...values }
      //Set the option values to the actual values
      const selectedOptionIdentify = identifyAsList.find(
        (option) => option.value === values.identifyAs
      )
      submittedValues.identifyAs =
        selectedOptionIdentify?.name || values.identifyAs
      const selectedOptionFurtherIdentify = furtherIdentifyAsList.find(
        (option) => option.value === values.furtherIdentifyAs
      )
      submittedValues.furtherIdentifyAs =
        selectedOptionFurtherIdentify?.name || values.furtherIdentifyAs
      const selectedOptionLocation = locationList.find(
        (option) => option.value === values.location
      )
      submittedValues.location = selectedOptionLocation?.name || values.location

      const config = {
        header: {
          'Content-Type': 'application/json',
        },
      }

      const response = await axios.post(signUpURL, submittedValues, config)
      const data = response.data

      if (data.id) {
        actions.setSubmitting(false)
        setSuccess(true)
      } else {
        actions.setSubmitting(false)
      }
    } catch (err) {
      actions.setSubmitting(false)
      if (err.code === 'ERR_BAD_REQUEST') {
        if (err.response.data.message === 'Email already taken') {
          setError('registeredemailerror')
        } else {
          setError('validationerror')
        }
      } else {
        setError('error')
      }
    }
  }

  useEffect(() => {
    if (success) {
      scrollToElement(formElement)
    }
  }, [success])

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnChange={false}
        enableReinitialize={true}
        initialTouched={{}}
        initialErrors={{}}
        validateOnMount={true}
        onSubmit={submitForm}
      >
        {({ isSubmitting }) => (
          <FormTemplate
            formFields={formFields}
            identifyAsList={identifyAsList}
            furtherIdentifyAsList={furtherIdentifyAsList}
            locationList={locationList}
            error={error}
            isSubmitting={isSubmitting}
            success={success}
          />
        )}
      </Formik>
    </>
  )
}

export default SignUpForm
