import { BrowserRouter, Route, Routes } from 'react-router-dom'
import AdminProvider from './contexts/AdminProvider'
import MainProvider from './contexts/MainProvider'
import Forgot from './components/Admin/Settings/Forgot'

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/*' element={<MainProvider />} />
        <Route path='/admin/*' element={<AdminProvider />} />
        <Route path='/admin/verify/:token?' element={<Forgot />} />
      </Routes>
    </BrowserRouter>
  )
}

export default App
