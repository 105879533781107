import openHours from '../../../assets/images/open-hours2.jpg'
import { ReactComponent as StarSymbol } from '../../../assets/elements/star.svg'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'

const OpenHours = () => {
  return (
    <section id='openHours' className=''>
      <figure className='flex flex-col lg:grid lg:grid-cols-12 gap-5 lg:mx-26 lg:min-h-[500px]'>
        <div className='lg:col-span-6 lg:row-start-1 lg:row-span-2 bg-primaryLime border-b lg:border-b-0 lg:border-r border-black flex justify-center lg:justify-end items-center lg:-ml-26 lg:mr-32 h-72 lg:h-full'>
          <div className=' mt-0 -mb-20 lg:-mr-10 lg:mb-0'>
            <div className='relative w-1/2 sm:w-5/12 mx-auto lg:w-96  border border-black'>
              <img
                src={openHours}
                alt='group of women'
                className='w-full h-full'
              />
              <StarSymbol className='absolute inset-y-2/3 -left-10 sm:-left-8 lg:inset-x-3/4 w-20 h-20 lg:w-32 lg:h-32 rotate-45 fill-primaryBlue stroke-black' />
            </div>{' '}
          </div>
        </div>
        <div className='lg:col-span-6 lg:row-start-1 lg:row-span-2 mt-8 lg:mt-0 px-8 py-12'>
          <h1 className='mb-12'>
            <FormattedMessage
              id='app.openHoursTitle'
              defaultMessage='Open Hours'
            />
          </h1>
          <p className='text-inter mb-12'>
            <FormattedMessage
              id='app.openHoursDescription1'
              defaultMessage='Open hours description'
            />
          </p>
          <p className='text-inter mb-12'>
            <FormattedMessage
              id='app.openHoursDescription2'
              defaultMessage='Open hours description'
            />
          </p>
          <Link
            className='button primary w-full sm:w-80 mx-auto h-14 flex justify-center items-center py-4 my-5 uppercase'
            to='/open-hours'
          >
            <FormattedMessage
              id='app.openHoursCTA'
              defaultMessage='Apply for Open Hours'
            />
          </Link>
        </div>
      </figure>
    </section>
  )
}

export default OpenHours
