import { FormattedMessage } from 'react-intl'
import { ReactComponent as StarSymbol } from '../../../assets/elements/star.svg'
import resources from '../../../assets/images/resources.jpg'
import events from '../../../assets/images/events.jpg'
import blog from '../../../assets/images/blog.jpg'
import openHours from '../../../assets/images/open-hours.jpg'
import serviceProviders from '../../../assets/images/service-providers.jpg'

const styles = {
  resources: {
    bg: 'bg-primaryBlue',
    bar: 'bg-secondaryBlue',
    bar2: 'bg-secondaryLilac',
    main: 'bg-primaryLime',
    secondary: 'bg-secondaryLilac',
    tertiary: 'bg-secondaryBlue',
    star: 'fill-secondaryMagenta',
  },
  blog: {
    bg: 'bg-secondaryBlue',
    bar: 'bg-primaryLime',
    bar2: 'bg-secondaryLilac',
    main: 'bg-primaryBlue text-white',
    secondary: 'bg-secondaryLilac',
    tertiary: 'bg-primaryLime',
    star: 'fill-secondaryMagenta',
  },
  events: {
    bg: 'bg-secondaryLilac',
    bar: 'bg-secondaryBlue',
    bar2: 'bg-secondaryLilac',
    main: 'bg-primaryLime',
    secondary: 'bg-secondaryLilac',
    tertiary: 'bg-secondaryBlue',
    star: 'fill-secondaryMagenta',
  },
  openHours: {
    bg: 'bg-secondaryPink',
    bar: 'bg-secondaryMagenta',
    bar2: 'bg-primaryLime',
    main: 'bg-secondaryLilac',
    secondary: 'bg-secondaryMagenta',
    tertiary: 'bg-primaryLime',
    star: 'fill-secondaryBlue',
  },
  serviceProviders: {
    bg: 'bg-secondaryPink',
    bar: 'bg-secondaryMagenta',
    bar2: 'bg-primaryLime',
    main: 'bg-secondaryLilac',
    secondary: 'bg-secondaryMagenta',
    tertiary: 'bg-primaryLime',
    star: 'fill-secondaryBlue',
  },
}

const images = {
  resources: resources,
  blog: blog,
  events: events,
  openHours: openHours,
  serviceProviders: serviceProviders,
}

const Banner = ({ section }) => {
  return (
    <section id='headline'>
      <figure className='hidden lg:flex'>
        <div
          className={`relative w-0 md:w-1/3 h-auto z-0 border-r-0 md:border-r border-black ${styles[section].bg}`}
        >
          <span className='absolute bottom-2 -right-16 md:-right-24'>
            <StarSymbol
              className={`w-20 h-20 md:w-22 md:h-22 rotate-90 ${styles[section].star} stroke-black`}
            />
          </span>
          <img src={images[section]} alt='placeholder' />
        </div>
        <div
          className={`w-5 h-auto ${styles[section].bar} border-r border-black`}
        >
          &nbsp;
        </div>
        <div
          className={`w-10 h-auto ${styles[section].bar2} border-r border-black`}
        >
          &nbsp;
        </div>
        <div
          className={`min-h-fit flex flex-col justify-center items-start flex-grow px-8 ${styles[section].main}`}
        >
          <div className='flex flex-col pr-8'>
            <span className='text-4.5xl tracking-normal font-bold font-poppins uppercase m-0 text-stroke'>
              <FormattedMessage
                id={`${section}.title`}
                defaultMessage={section}
              />
            </span>
            <span className='w-full text-sm my-8 pr-8'>
              <FormattedMessage
                id={`${section}.subtitle`}
                defaultMessage={section}
              />
            </span>
          </div>
        </div>
      </figure>
      <figure className={`flex flex-col lg:hidden ${styles[section].bg}`}>
        <div className='relative flex justify-center items-center w-full py-8 px-8 sm:px-4'>
          <div className='relative flex w-52 h-64 sm:w-72 sm:h-72'>
            <div
              className={`absolute flex justify-center items-center top-0 left-0 w-52 h-48 sm:w-72 sm:h-60 ${styles[section].main} border border-black z-20`}
            >
              <span className='text-2xl sm:text-3.5xl tracking-normal font-bold font-poppins uppercase m-0 text-stroke'>
                <FormattedMessage
                  id={`app.${section}`}
                  defaultMessage={section}
                />
              </span>
            </div>
            <div
              className={`absolute top-4 left-4 w-52 h-48 sm:w-72 sm:h-60 ${styles[section].secondary} border border-black z-10`}
            >
              &nbsp;
            </div>
            <div
              className={`absolute top-8 left-8 w-52 h-48 sm:w-72 sm:h-60 ${styles[section].tertiary} border border-black z-0`}
            >
              &nbsp;
            </div>
            <StarSymbol
              className={`absolute bottom-8 left-4 sm:bottom-4 rotate-90 w-12 h-12 sm:w-16 sm:h-16 lg:w-20 lg:h-20 ${styles[section].star} stroke-black z-30`}
            />
          </div>
        </div>
      </figure>
    </section>
  )
}

export default Banner
