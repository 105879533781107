import moment from 'moment'
import { useContext } from 'react'
import MainContext from '../../../contexts/Main'

const PostCard = ({ post }) => {
  const { navigate } = useContext(MainContext)

  return (
    <div
      className='w-full h-full lg:min-h-[420px] p-4 bg-stone-50 border border-black flex flex-col justify-start items-start gap-8 cursor-pointer '
      onClick={() => {
        navigate('/blog/' + post.id)
      }}
    >
      <img
        className={`w-full h-40 sm:h-48 lg:h-60 object-${post.image.focus} object-cover border border-black`}
        src={post.image ? post.image.url : '/images/placeholder.png'}
        alt='cover'
      />
      <div className='w-full flex flex-col flex-grow lg:h-28 justify-start items-start gap-4'>
        <div className='w-full text-left text-black text-xl font-bold truncate overflow-clip'>
          {post.title}
        </div>
        <div className='w-full lg:h-14 flex-col justify-start items-start gap-2 flex'>
          <div className='w-full justify-between items-start sm:gap-2 flex flex-col sm:flex-row'>
            <div className='text-left text-black text-base font-normal leading-loose'>
              By {post.author.name}
            </div>
            <div className='text-center text-black text-xs font-normal leading-loose'>
              {moment(post.createdAt).format('MMM DD, YYYY')}
            </div>
          </div>
          <div className='justify-start items-start gap-2 inline-flex flex-wrap'>
            {post.tags.map((tag, i) => (
              <div
                key={tag + i}
                className='w-fit pl-1.5 pr-2 py-0.5 bg-purple-300 rounded-sm border border-violet-400 justify-center items-center gap-1.5 flex'
              >
                <div className='text-center text-indigo-500 text-xs font-medium capitalize leading-none'>
                  {tag}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default PostCard
