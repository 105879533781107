import { useContext } from 'react'
import AdminContext from '../../../contexts/Admin'
import ServiceProvidersList from './ServiceProvidersList'
import ServiceProvider from './ServiceProvider'
import AddServiceProvider from './AddServiceProvider'

const ServiceProviders = () => {
  const { currentParams } = useContext(AdminContext)
  const { id } = currentParams
  return id === 'new' ? (
    <AddServiceProvider />
  ) : id ? (
    <ServiceProvider />
  ) : (
    <ServiceProvidersList />
  )
}

export default ServiceProviders
