import { PencilIcon, TrashIcon } from '@heroicons/react/24/outline'
import { useContext, useEffect, useState } from 'react'
import AdminContext from '../../../contexts/Admin'
import LinkPreview from '../Widgets/LinkPreview'
import Loading from '../../Common/Widgets/Loading'
import axios from 'axios'
import { getServiceProviderUrl } from '../Config/APIurls'
import AddServiceProvider from './AddServiceProvider'
import ComponentModal from '../Widgets/ComponentModal'
import DeleteServiceProvider from './DeleteServiceProvider'
import Tag from '../../Platform/Resources/Components/Tag'
import { MapPinIcon } from '@heroicons/react/24/solid'

const styles = {
  languages: {
    tag: 'bg-secondaryLilac border-violet-400',
    text: 'text-primaryBlue',
  },
  services: {
    tag: 'bg-secondaryBlue border-sky-300',
    text: 'text-primaryBlue',
  },
  skills: {
    tag: 'bg-primaryBlue border-primaryBlue',
    text: 'text-white',
  },
}

const ServiceProvider = () => {
  const [edit, setEdit] = useState(false)
  const [action, setAction] = useState(null)
  const {
    currentParams,
    displayMainMessage,
    config,
    user,
    setShowModal,
    reload,
  } = useContext(AdminContext)
  const [serviceProvider, setServiceProvider] = useState(null)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const { id } = currentParams

  useEffect(() => {
    const getServiceProvider = async () => {
      try {
        const { data } = await axios.get(getServiceProviderUrl + id, config)
        if (!data) {
          // if the data is empty
          setError('No data found')
          setLoading(false)
          return
        }
        setServiceProvider(data)
        setLoading(false)
      } catch (err) {
        setError(
          'Error getting the data, please refresh the page and try again'
        )
        displayMainMessage(
          {
            error: 1,
            message: err.status
              ? err.response.message
              : 'Something went wrong while loading the widgets data',
            show: true,
          },
          5000
        )
      }
    }
    setLoading(true)
    getServiceProvider()
  }, [id, reload])

  return loading ? (
    <Loading />
  ) : serviceProvider && !edit ? (
    <>
      <ComponentModal>
        <DeleteServiceProvider serviceProvider={serviceProvider} />
      </ComponentModal>
      <div className='relative self-center flex flex-col w-full xl:w-5/6 2xl:w-4/6 pb-6 shadow-lg border-0'>
        <div className='flex flex-col md:flex-row px-4 pt-2 space-x-4 w-full justify-between'>
          {serviceProvider.headshot?.url && (
            <img
              src={serviceProvider.headshot.url}
              alt='profile'
              className='w-24 h-24 object-cover'
            />
          )}
          <div className='flex-grow w-full mb-2'>
            <p className='text-xl xl:text-2xl 2xl:text-3xl text-mono tracking-wider self-center font-bold uppercase'>
              {serviceProvider.name}
            </p>
            <span className=' text-mono tracking-wider self-center font-bold uppercase'>
              {serviceProvider.pronouns}
            </span>
          </div>
          <div className='flex flex-shrink justify-center items-center'>
            <span
              className={`${
                serviceProvider.available ? 'text-valid' : 'text-invalid'
              } px-3 bg-white text-base md:text-lg text-mono w-full ease-linear transition-all duration-150 text-justify`}
            >
              {serviceProvider.available ? 'Available' : 'Not Listed'}
            </span>
          </div>
        </div>

        <hr className={`mt-6 border-b-1 border-gray-400`} />

        <div className='w-full flex flex-col px-4 py-2'>
          <p className=' text-mono tracking-wider self-center font-bold uppercase'>
            {serviceProvider.email}
          </p>
          {serviceProvider.title && (
            <>
              <h6 className='text-gray-400 text-sm mt-3 font-bold uppercase'>
                Title
              </h6>
              <div className='px-3 bg-white text-base md:text-lg text-mono w-full ease-linear transition-all duration-150 text-justify'>
                {serviceProvider.title}
              </div>
            </>
          )}
          {serviceProvider.company && (
            <>
              <h6 className='text-gray-400 text-sm mt-3 font-bold uppercase'>
                Company
              </h6>
              <div className='px-3 bg-white text-base md:text-lg text-mono w-full ease-linear transition-all duration-150 text-justify'>
                {serviceProvider.company}
              </div>
            </>
          )}
          {serviceProvider.companyLink && (
            <>
              <h6 className='text-gray-400 text-sm mt-3 font-bold uppercase'>
                Company Link
              </h6>
              <div className='px-3 bg-white text-base md:text-lg text-mono w-full ease-linear transition-all duration-150 text-justify'>
                <LinkPreview url={serviceProvider.companyLink} />
              </div>
            </>
          )}
          <h6 className='text-gray-400 text-sm mt-3 font-bold uppercase'>
            Industry
          </h6>
          <div className='px-3 bg-white text-base md:text-lg text-mono w-full ease-linear transition-all duration-150 text-justify'>
            <div className='flex items-center py-1 space-x-2 overflow-x-scroll scrollbar scrollbar-thin scrollbar-thumb-secondaryPink scrollbar-track-green-100'>
              {serviceProvider.industry}
            </div>
          </div>
          {serviceProvider.instagram && (
            <>
              <h6 className='text-gray-400 text-sm mt-3 font-bold uppercase'>
                Instagram
              </h6>
              <div className='px-3 bg-white text-base md:text-lg text-mono w-full ease-linear transition-all duration-150 text-justify'>
                <LinkPreview url={serviceProvider.instagram} />
              </div>
            </>
          )}
          {serviceProvider.linkedin && (
            <>
              <h6 className='text-gray-400 text-sm mt-3 font-bold uppercase'>
                Linkedin
              </h6>
              <div className='px-3 bg-white text-base md:text-lg text-mono w-full ease-linear transition-all duration-150 text-justify'>
                <LinkPreview url={serviceProvider.linkedin} />
              </div>
            </>
          )}
          {serviceProvider.otherSocial && (
            <>
              <h6 className='text-gray-400 text-sm mt-3 font-bold uppercase'>
                Other Social
              </h6>
              <div className='px-3 bg-white text-base md:text-lg text-mono w-full ease-linear transition-all duration-150 text-justify'>
                <LinkPreview url={serviceProvider.otherSocial} />
              </div>
            </>
          )}
          <h6 className='text-gray-400 text-sm mt-3 font-bold uppercase'>
            Bio
          </h6>
          <div className='px-3 bg-white text-base md:text-lg text-mono w-full ease-linear transition-all duration-150 text-justify'>
            {serviceProvider.bio}
          </div>
          <h6 className='text-gray-400 text-sm mt-3 font-bold uppercase'>
            Disclaimer
          </h6>
          <div className='px-3 bg-white text-base md:text-lg text-mono w-full ease-linear transition-all duration-150 text-justify'>
            {serviceProvider.disclaimer || ' -'}
          </div>
          <h6 className='text-gray-400 text-sm mt-3 font-bold uppercase'>
            Location
          </h6>
          <div className='flex gap-2 px-3 bg-white text-base md:text-lg text-mono w-full ease-linear transition-all duration-150 text-justify'>
            <MapPinIcon className='h-6 w-6 text-black' />
            {serviceProvider.location || ' -'}
          </div>
          <h6 className='text-gray-400 text-sm mt-3 font-bold uppercase'>
            Services
          </h6>
          <div className='px-3 bg-white text-base md:text-lg text-mono w-full ease-linear transition-all duration-150 text-justify'>
            <div className='flex items-center py-1 space-x-2 overflow-x-scroll scrollbar scrollbar-thin scrollbar-thumb-secondaryPink scrollbar-track-green-100'>
              {serviceProvider.services.length ? (
                serviceProvider.services.map((tag) => (
                  <Tag tag={tag} style={styles.services} />
                ))
              ) : (
                <p className='text-xs ml-2'>No Services</p>
              )}
            </div>
          </div>
          <h6 className='text-gray-400 text-sm mt-3 font-bold uppercase'>
            Skills
          </h6>
          <div className='px-3 bg-white text-base md:text-lg text-mono w-full ease-linear transition-all duration-150 text-justify'>
            <div className='flex items-center py-1 space-x-2 overflow-x-scroll scrollbar scrollbar-thin scrollbar-thumb-secondaryPink scrollbar-track-green-100'>
              {serviceProvider.skills.length ? (
                serviceProvider.skills.map((tag) => (
                  <Tag tag={tag} style={styles.skills} />
                ))
              ) : (
                <p className='text-xs ml-2'>No Skills listed</p>
              )}
            </div>
          </div>
          <h6 className='text-gray-400 text-sm mt-3 font-bold uppercase'>
            Languages
          </h6>
          <div className='px-3 bg-white text-base md:text-lg text-mono w-full ease-linear transition-all duration-150 text-justify'>
            <div className='flex items-center py-1 space-x-2 overflow-x-scroll scrollbar scrollbar-thin scrollbar-thumb-secondaryPink scrollbar-track-green-100'>
              {serviceProvider.languages.length ? (
                serviceProvider.languages.map((tag) => (
                  <Tag tag={tag} style={styles.languages} />
                ))
              ) : (
                <p className='text-xs ml-2'>No languages</p>
              )}
            </div>
          </div>
          <h6 className='text-gray-400 text-sm mt-3 font-bold uppercase'>
            Booking Type
          </h6>
          <div className='px-3 bg-white text-base md:text-lg text-mono w-full ease-linear transition-all duration-150 text-justify'>
            {serviceProvider.bookingType}
          </div>
          <h6 className='text-gray-400 text-sm mt-3 font-bold uppercase'>
            Booking Link
          </h6>
          <div className='px-3 bg-white text-base md:text-lg text-mono w-full ease-linear transition-all duration-150 text-justify'>
            {serviceProvider.bookingType !== 'Email' ? (
              <LinkPreview url={serviceProvider.bookingLink} />
            ) : (
              serviceProvider.bookingLink
            )}
          </div>
        </div>
        <div className=' w-full px-4 my-4 flex flex-col space-y-4 sm:space-y-0 sm:flex-row items-center justify-around '>
          {user.role.includes('admin') && (
            <>
              <button
                className='w-40 flex space-x-4 justify-center items-center button-secondary'
                onClick={() => {
                  setEdit(true)
                }}
              >
                <PencilIcon className='h-5 w-5' />
                <span>Edit</span>
              </button>

              <button
                className='w-40 flex space-x-4 justify-center items-center button-primary'
                onClick={() => {
                  setAction('delete')
                  setShowModal(true)
                }}
              >
                <TrashIcon className='h-5 w-5' />
                <span>Delete</span>
              </button>
            </>
          )}
        </div>
      </div>
    </>
  ) : serviceProvider && edit ? (
    <AddServiceProvider
      serviceProvider={serviceProvider}
      setEdit={setEdit}
      edit={edit}
    />
  ) : (
    <Loading />
  )
}

export default ServiceProvider
