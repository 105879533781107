import { MagnifyingGlassIcon } from '@heroicons/react/24/outline'
import { useIntl } from 'react-intl'

const SearchBar = ({ search, setSearch, location }) => {
  const intl = useIntl()

  return (
    <div className='flex mt-4'>
      <div className='w-full md:max-w-md h-12 flex-col justify-start items-start gap-4 inline-flex'>
        <div className='w-full h-12 px-6 py-2 bg-stone-50 rounded-full border border-stone-900 justify-start items-center gap-2 inline-flex'>
          <div className='w-8 h-8 relative'>
            <MagnifyingGlassIcon className='' />
          </div>
          <input
            className='w-full h-full bg-transparent ring-0 outline-none text-left text-neutral-500 text-base font-normal leading-normal'
            placeholder={intl.formatMessage({
              id: `${location}.search`,
              defaultMessage: 'Search by keyword',
            })}
            id='searchField'
            value={search}
            onChange={(e) => {
              setSearch(e.target.value)
            }}
          ></input>
        </div>
      </div>
    </div>
  )
}

export default SearchBar
