import moment from 'moment'
import { Link } from 'react-router-dom'

const BlogCard = ({ post }) => {
  return (
    <Link
      className='w-60 h-60 flex flex-col flex-shrink-0 overflow-hidden border border-black'
      to={'/blog/' + post.id}
    >
      <img
        src={post.image.url}
        alt={post.title}
        className={`w-full aspect-video object-cover object-${post.image.focus}`}
      />
      <div className='flex flex-col flex-grow px-2 pt-2 space-y-2 whitespace-pre-line'>
        <div className='flex-grow font-bold text-sm'>{post.title}</div>
        <div className=' font-bold text-xs text-right py-2'>
          {moment(post.createdAt).format('MMMM Do, YYYY')}
        </div>
      </div>
    </Link>
  )
}

export default BlogCard
