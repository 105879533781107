import { FormattedMessage } from 'react-intl'

import Marquee from '../components/Marquee'
import sendLogo from '../../../assets/logos/SENDLogo.png'
import googleLogo from '../../../assets/logos/googleLogo.png'

import about from '../../../assets/images/about.jpg'
import { ReactComponent as StarSymbol } from '../../../assets/elements/star.svg'
import Header from './Header'
import Form from './Form'
import Team from './Team'
import OpenHours from './OpenHours'
import Blog from './Blog'

const About = () => {
  return (
    <>
      <Header />
      <Marquee />
      <section id='about'>
        <figure className='flex flex-col-reverse lg:grid lg:grid-cols-12 gap-5 lg:mx-26'>
          <div className='lg:col-span-6 lg:row-start-1 lg:row-span-2 mt-8 lg:mt-0 px-8 py-12'>
            <h1 className='mb-12'>
              <FormattedMessage
                id='app.aboutLong'
                defaultMessage='ABOUT BRAVE SPACES 2.0'
              />
            </h1>
            <p className='text-inter'>
              <FormattedMessage
                id='app.aboutdescription1'
                defaultMessage='This is our empowerment project for and from trans*, inter*, and non-binary BIPOC and BIWOC entrepreneurs, solopreneurs, and freelancers!'
              />
            </p>
            <p>
              <FormattedMessage
                id='app.aboutdescription2'
                defaultMessage="Our voices, experiences, and leadership have been systemically excluded for too long. Braves Spaces 2.0 won't stand by and let that continue."
              />
            </p>
            <p>
              <FormattedMessage
                id='app.aboutdescription3'
                defaultMessage="We believe in entrepreneurship, solopreneurship, and freelancing as tools for social change. That's why we will create spaces that uplift your ideas, innovations, and businesses. Through all the content on our platform as well as our braver spaces events, we uplift each other to write a more inclusive narrative that centers (y)our brilliance."
              />
            </p>
            <p className='font-bold'>
              <FormattedMessage
                id='app.aboutdescription4'
                defaultMessage='Brave Spaces 2.0 is a joint project between BIWOC* Rising and Founderland who are financially supported by SEND e.V. within "emp:our now"'
              />
            </p>
          </div>
          <div className='lg:col-span-6 lg:row-start-1 lg:row-span-2 bg-primaryBlue border-b lg:border-b-0 lg:border-l border-black flex justify-center lg:justify-start items-center lg:-mr-26 lg:ml-32 h-60 lg:h-full'>
            <div className=' mt-0 -mb-20 lg:-ml-10 lg:mb-0'>
              <div className='relative w-2/3 sm:w-1/2 md:w-5/12 mx-auto lg:w-[450px] max-h-48 border border-black'>
                <img
                  src={about}
                  alt='group of women'
                  className='w-full h-full'
                />
                <StarSymbol className='absolute inset-y-2/3 -left-10 sm:-left-8 lg:inset-x-3/4 w-20 h-20 lg:w-32 lg:h-32 rotate-45 fill-primaryLime stroke-black' />
              </div>
            </div>
          </div>
        </figure>
      </section>
      <OpenHours />
      <Blog />
      <section id='nuggets'>
        <figure className='flex flex-col sm:flex-row'>
          <div className='card w-full lg:w-1/3 aspect-square bg-secondaryBlue border-b border-r-0 sm:border-r sm:border-b-0 border-black'>
            <p>
              <FormattedMessage
                id='app.bubble1'
                defaultMessage='find curated resources'
              />
            </p>
          </div>
          <div className='card w-full lg:w-1/3 aspect-square bg-secondaryMagenta border-b border-r-0 sm:border-r sm:border-b-0 border-black'>
            <p>
              <FormattedMessage
                id='app.bubble2'
                defaultMessage='get support for your business'
              />
            </p>
          </div>
          <div className='card w-full lg:w-1/3 aspect-square bg-secondaryLilac '>
            <p>
              <FormattedMessage
                id='app.bubble3'
                defaultMessage='MEET PEERS, NETWORK, AND BUILD RELATIONSHIPS'
              />
            </p>
          </div>
        </figure>
      </section>
      <div className='flex flex-col-reverse lg:flex-col'>
        <Form />
        <Team />
      </div>
      <section id='support'>
        <figure className='flex flex-col justify-center items-center mx-5 lg:mx-26 py-10'>
          <p className='lowercase'>
            <FormattedMessage id='app.support' defaultMessage='Supported By' />
          </p>
          <div className='flex flex-col space-y-10 sm:flex-row sm:space-y-0 sm:space-x-10 my-10'>
            <img
              src={sendLogo}
              className='w-1/2 h-auto sm:w-60 xl:w-80 mx-auto'
              alt='Send Logo'
            />
            <img
              src={googleLogo}
              className='w-1/2 h-auto sm:w-60 xl:w-80 mx-auto'
              alt='Google.org Logo'
            />
          </div>
        </figure>
      </section>
    </>
  )
}

export default About
