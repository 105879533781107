// API URLS

//DASHBOARD
export const checkVersionUrl = '/api/v1/auth/version/'

//MEMBERS MNGT
export const getMembersUrl = '/api/v1/members/all/'
export const getMembersPageUrl = '/api/v1/members/'
export const getMemberUrl = '/api/v1/members/id/'
export const downloadMembersUrl = '/api/v1/members/download/'
//SERVICE PROVIDERS MNGT
export const getServiceProvidersUrl = '/api/v1/service-providers/'
export const getServiceProviderUrl = '/api/v1/service-providers/id/'
export const downloadServiceProvidersUrl = '/api/v1/service-providers/download/'
export const createServiceProviderUrl = '/api/v1/service-providers/'
export const updateServiceProviderUrl = '/api/v1/service-providers/id/'
export const deleteServiceProviderUrl = '/api/v1/service-providers/id/'
//USERS MNGT
export const getUsersUrl = '/api/v1/users/'
//EVENTS MNGT
export const getEventsUrl = '/api/v1/events/'
export const getEventUrl = '/api/v1/events/id/'
export const downloadEventsUrl = '/api/v1/events/download/'
export const createEventUrl = '/api/v1/events/'
export const updateEventUrl = '/api/v1/events/id/'
export const deleteEventUrl = '/api/v1/events/id/'
export const cancelEventUrl = '/api/v1/events/id/'
//RESOURCES MNGT
export const getResourcesUrl = '/api/v1/resources/'
export const getResourceUrl = '/api/v1/resources/id/'
export const downloadResourcesUrl = '/api/v1/resources/download/'
export const createResourceUrl = '/api/v1/resources/'
export const updateResourceUrl = '/api/v1/resources/id/'
export const deleteResourceUrl = '/api/v1/resources/id/'
export const getListsApi = '/api/v1/resources/lists'
export const getSuggestionsApi = '/api/v1/resources/suggestions'

//IMPORTANT LINK MNGT
export const getImportantLinksUrl = '/api/v1/importantlinks/'
export const getImportantLinkUrl = '/api/v1/importantlinks/id/'
export const downloadImportantLinksUrl = '/api/v1/importantlinks/download/'
export const createImportantLinkUrl = '/api/v1/importantlinks/'
export const updateImportantLinkUrl = '/api/v1/importantlinks/id/'
export const deleteImportantLinkUrl = '/api/v1/importantlinks/id/'

//POSTS MNGT
export const getPostsUrl = '/api/v1/posts/'
export const getPostUrl = '/api/v1/posts/id/'
export const createPostUrl = '/api/v1/posts/'
export const updatePostUrl = '/api/v1/posts/id/'
export const deletePostUrl = '/api/v1/posts/id/'

//UPLOAD
export const uploadImageUrl = '/api/v1/upload/image'

//AUTH
export const loginUrl = '/api/v1/auth/login/'
export const forgotPasswordUrl = '/api/v1/auth/forgot-password/'
export const refreshTokenUrl = '/api/v1/auth/refresh-tokens/'
export const sendVerification = '/api/v1/auth/send-verification-email/'
// Path: react-app/src/components/Admin/Config/APIurls.js
