import { Listbox, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/24/outline'
import { Fragment, useContext } from 'react'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'
import MainContext from '../../contexts/Main'

const Menu = () => {
  const { menuToggle, setMenuToggle, languages, locale, selectLanguage } =
    useContext(MainContext)

  return (
    <>
      <div
        className={`${
          menuToggle ? 'block ' : 'hidden'
        } fixed z-40 inset-0 transition-opacity lg:hidden`}
        onClick={() => setMenuToggle(false)}
      ></div>
      <div
        className={`fixed z-50 inset-y-0 left-0 w-48 transition duration-300 transform bg-white border border-black text-black overflow-y-auto ${
          !menuToggle && '-translate-x-full'
        } lg:hidden`}
      >
        <nav>
          <Link
            to={`/#about`}
            onClick={() => setMenuToggle(false)}
            className={`border-b border-black h-20 p-5 w-full transition duration-200 flex items-center justify-center`}
          >
            <span>
              <FormattedMessage id='app.about' defaultMessage='About' />
            </span>
          </Link>
          <Link
            to={`/events`}
            onClick={() => setMenuToggle(false)}
            className={`border-b border-black h-20 p-5 w-full transition duration-200 flex items-center justify-center`}
          >
            <span>
              <FormattedMessage id='app.events' defaultMessage='Events' />
            </span>
          </Link>
          <Link
            to={`/resources`}
            onClick={() => setMenuToggle(false)}
            className={`border-b border-black h-20 p-5 w-full transition duration-200 flex items-center justify-center`}
          >
            <span>
              <FormattedMessage id='app.resources' defaultMessage='Resources' />
            </span>
          </Link>
          <Link
            to={`/open-hours`}
            onClick={() => setMenuToggle(false)}
            className={`border-b border-black h-20 p-5 w-full transition duration-200 flex items-center justify-center`}
          >
            <span>
              <FormattedMessage
                id='app.openHours'
                defaultMessage='Open Hours'
              />
            </span>
          </Link>
          <Link
            to={`/blog`}
            onClick={() => setMenuToggle(false)}
            className={`border-b border-black h-20 p-5 w-full transition duration-200 flex items-center justify-center`}
          >
            <span>
              <FormattedMessage id='app.blog' defaultMessage='Blog' />
            </span>
          </Link>
          <Link
            to='/#footer'
            onClick={() => setMenuToggle(false)}
            className={`border-b border-black h-20 p-5 w-full transition duration-200 flex items-center justify-center`}
          >
            <span>
              <FormattedMessage id='app.findUs' defaultMessage='Find Us' />
            </span>
          </Link>
          <Listbox value={locale} onChange={selectLanguage}>
            <div className='relative h-20 p-5 w-full transition duration-200 flex items-center justify-center'>
              <Listbox.Button className='relative max-w-fit cursor-default text-left py-2.5 pl-4 pr-8 focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm'>
                <span className=' block truncate'>
                  {languages.find((item) => item.value === locale)?.name}
                </span>
                <span className='pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2'>
                  <ChevronDownIcon
                    className='h-5 w-5 text-gray-400'
                    aria-hidden='true'
                  />
                </span>
              </Listbox.Button>
              <Transition
                as={Fragment}
                leave='transition ease-in duration-100'
                leaveFrom='opacity-100'
                leaveTo='opacity-0'
              >
                <Listbox.Options className='absolute top-10 mt-10 max-h-60 w-full overflow-auto bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm'>
                  {languages.map(
                    (language) =>
                      language.available && (
                        <Listbox.Option
                          key={language.id}
                          value={language.value}
                          className={({ active }) =>
                            `relative cursor-default text-center select-none py-2 px-4 ${
                              active
                                ? 'bg-primaryLime text-black'
                                : 'text-gray-900'
                            }`
                          }
                        >
                          {({ selected }) => (
                            <span
                              className={`block truncate ${
                                selected ? 'font-medium' : 'font-normal'
                              }`}
                            >
                              {language.name}
                            </span>
                          )}
                        </Listbox.Option>
                      )
                  )}
                </Listbox.Options>
              </Transition>
            </div>
          </Listbox>
          <Link
            to='/#join'
            onClick={() => {
              setMenuToggle(false)
            }}
            className={`bg-black text-white h-20 p-5 w-full transition duration-200 flex items-center justify-center`}
          >
            <span>
              <FormattedMessage id='app.signup' defaultMessage='Sign Up' />
            </span>
          </Link>
        </nav>
      </div>
    </>
  )
}

export default Menu
