import { useContext } from 'react'
import MainContext from '../../contexts/Main'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'
import { ReactComponent as BSLogo } from '../../assets/logos/BSPrimaryFill.svg'

import { Listbox, Transition } from '@headlessui/react'
import { ChevronDownIcon, GlobeAltIcon } from '@heroicons/react/24/outline'
import { Fragment } from 'react'

const Header = () => {
  const { menuToggle, setMenuToggle } = useContext(MainContext)
  const { locale, selectLanguage, languages } = useContext(MainContext)

  const genericHamburgerLine = `h-1 w-8 my-1 rounded-full bg-white-500 transition ease transform duration-300 z-50`

  const activeMenu = (path) => {
    return (
      (path !== '' && window.location.pathname.includes(path)) ||
      (path === '' && window.location.pathname === '/')
    )
  }

  return (
    <div
      className={`fixed top-0 flex justify-center items-center w-screen h-20 bg-primaryLime  ${
        menuToggle ? 'z-40' : 'z-50'
      }`}
    >
      <header className={`max-w-7xl`}>
        <Link
          to={'/'}
          className='h-20 border-b border-black flex lg:hidden flex-grow items-center justify-start bg-primaryLime'
        >
          <BSLogo className='pl-10 w-60 h-auto' />
        </Link>
        <button
          className='border border-black flex lg:hidden flex-col items-center justify-center align-middle w-20 h-20 bg-black'
          aria-controls='mobile-navigation'
          aria-label='Navigation Menu'
          onClick={() => {
            setMenuToggle(!menuToggle)
          }}
        >
          <div
            className={`${genericHamburgerLine} ${
              menuToggle && 'rotate-45 translate-y-3 bg-black-500'
            }`}
          />
          <div
            className={`${genericHamburgerLine} ${menuToggle && 'opacity-0'}`}
          />
          <div
            className={`${genericHamburgerLine} ${
              menuToggle && '-rotate-45 -translate-y-3 bg-black-500'
            }`}
          />
        </button>
        <nav className='hidden lg:flex lg:flex-grow justify-end items-center h-full'>
          <Link
            to={'/'}
            className='border border-black h-full flex flex-grow items-center justify-center bg-primaryLime'
          >
            <BSLogo className=' w-60 h-auto' />
          </Link>
          <Link
            to={`/#about`}
            className={`relative border-r border-b border-t border-black h-20 w-36 p-5 transition duration-200 flex flex-col items-center justify-center`}
          >
            <span>
              <FormattedMessage id='app.about' defaultMessage='About' />
            </span>
            <hr
              className={`absolute bottom-0 w-full h-1 mx-auto place-items-end ${
                activeMenu('') ? 'bg-black' : ''
              } border-0  `}
            />
          </Link>
          <Link
            to={`/events`}
            onClick={() => setMenuToggle(false)}
            className={`relative border-r border-b border-t border-black h-20 w-36 p-5 transition duration-200 flex flex-col items-center justify-center`}
          >
            <span>
              <FormattedMessage id='app.events' defaultMessage='Events' />
            </span>
            <hr
              className={`absolute bottom-0 w-full h-1 mx-auto place-items-end ${
                activeMenu('events') ? 'bg-black' : ''
              } border-0  `}
            />
          </Link>
          <Link
            to={`/resources`}
            onClick={() => setMenuToggle(false)}
            className={`relative border-r border-b border-t border-black h-20 w-36 p-5 transition duration-200 flex flex-col items-center justify-center`}
          >
            <div
              className={`w-full h-full flex items-center justify-center p-5`}
            >
              <span>
                <FormattedMessage
                  id='app.resources'
                  defaultMessage='Resources'
                />
              </span>
            </div>
            <hr
              className={`absolute bottom-0 w-full h-1 mx-auto place-items-end ${
                activeMenu('resources') ? 'bg-black' : ''
              } border-0  `}
            />
          </Link>
          <Link
            to={`/open-hours`}
            onClick={() => setMenuToggle(false)}
            className={`relative border-r border-b border-t border-black h-20 w-36 p-5 transition duration-200 flex flex-col items-center justify-center`}
          >
            <span>
              <FormattedMessage
                id='app.openHours'
                defaultMessage='Open Hours'
              />
            </span>
            <hr
              className={`absolute bottom-0 w-full h-1 mx-auto place-items-end ${
                activeMenu('open-hours') ? 'bg-black' : ''
              } border-0  `}
            />
          </Link>
          <div className='hidden 2xl:inline-flex'>
            <Listbox value={locale} onChange={selectLanguage}>
              <div className='border-y h-20 border-black bg-white transition duration-200 flex items-center justify-center'>
                <Listbox.Button className='relative max-w-fit cursor-pointer text-left py-2.5 pl-2 pr-8 focus:outline-none '>
                  <span className='relative block truncate'>
                    <GlobeAltIcon className='h-8 w-8 text-gray-600' />
                    <span className='sr-only'>Change language</span>
                  </span>
                  <span className='absolute inset-y-0 right-0 flex items-center pr-2'>
                    <ChevronDownIcon
                      className='h-5 w-5 text-black'
                      aria-hidden='true'
                    />
                  </span>
                </Listbox.Button>
                <Transition
                  as={Fragment}
                  leave='transition ease-in duration-100'
                  leaveFrom='opacity-100'
                  leaveTo='opacity-0'
                >
                  <Listbox.Options className='absolute top-20 w-20 max-h-40 overflow-auto bg-white text-base lg:shadow-lg'>
                    {languages.map(
                      (language) =>
                        language.available && (
                          <Listbox.Option
                            key={language.id}
                            value={language.value}
                            className={({ active }) =>
                              `cursor-default select-none p-2 text-center ${
                                active
                                  ? 'bg-primaryLime text-black'
                                  : 'text-gray-900'
                              }`
                            }
                          >
                            {({ selected }) => (
                              <span
                                className={`block ${
                                  selected ? 'font-medium' : 'font-normal'
                                }`}
                              >
                                {language.name}
                              </span>
                            )}
                          </Listbox.Option>
                        )
                    )}
                  </Listbox.Options>
                </Transition>
              </div>
            </Listbox>
          </div>
          <Link
            to={`/#join`}
            onClick={() => setMenuToggle(false)}
            className={`bg-black text-white h-20 w-36 p-5 transition duration-200 flex items-center justify-center`}
          >
            <span>
              <FormattedMessage id='app.signup' defaultMessage='Sign Up' />
            </span>
          </Link>
        </nav>
      </header>
    </div>
  )
}

export default Header
