/* eslint-disable no-unused-vars */
import {
  NewspaperIcon,
  UserIcon,
  HomeIcon,
  UserGroupIcon,
  AdjustmentsHorizontalIcon,
  RectangleStackIcon,
  CalendarDaysIcon,
  AcademicCapIcon,
  BuildingLibraryIcon,
} from '@heroicons/react/24/outline'

export const views = {
  dashboard: { icon: 'home', name: 'Dashboard', color: 'secondaryMagenta' },
  events: { icon: 'calendar', name: 'Events', color: 'secondaryMagenta' },

  // wiki: { icon: 'library', name: 'Wiki', color: 'secondaryMagenta' },
  resources: {
    icon: 'collection',
    name: 'Resources',
    color: 'secondaryMagenta',
  },
  blog: { icon: 'blog', name: 'Blog', color: 'secondaryMagenta' },
  'service-providers': {
    icon: 'academic',
    name: 'Service Providers',
    color: 'secondaryMagenta',
  },
  members: { icon: 'groupuser', name: 'Members', color: 'secondaryMagenta' },
  settings: { icon: 'set', name: 'Settings', color: 'secondaryMagenta' },
}

export const headerIcons = {
  home: <HomeIcon className='block h-6 w-6 text-secondaryMagenta' />,
  calendar: (
    <CalendarDaysIcon className='block h-6 w-6 text-secondaryMagenta' />
  ),
  blog: <NewspaperIcon className='block h-6 w-6 text-secondaryMagenta' />,
  library: (
    <BuildingLibraryIcon className='block h-6 w-6 text-secondaryMagenta' />
  ),
  collection: (
    <RectangleStackIcon className='block h-6 w-6 text-secondaryMagenta' />
  ),
  academic: <AcademicCapIcon className='block h-6 w-6 text-secondaryMagenta' />,
  groupuser: <UserGroupIcon className='block h-6 w-6 text-secondaryMagenta' />,
  set: <RectangleStackIcon className='block h-6 w-6 text-secondaryMagenta' />,
}

export const menuIcons = {
  home: <HomeIcon className='h-5 w-5 group-hover:text-secondaryMagenta' />,
  calendar: (
    <CalendarDaysIcon className='h-5 w-5 group-hover:text-secondaryMagenta' />
  ),
  blog: <NewspaperIcon className='h-5 w-5 group-hover:text-secondaryMagenta' />,
  library: (
    <BuildingLibraryIcon className='h-5 w-5 group-hover:text-secondaryMagenta' />
  ),
  collection: (
    <RectangleStackIcon className='h-5 w-5 group-hover:text-secondaryMagenta' />
  ),
  academic: (
    <AcademicCapIcon className='h-5 w-5 group-hover:text-secondaryMagenta' />
  ),
  groupuser: (
    <UserGroupIcon className='h-5 w-5 group-hover:text-secondaryMagenta' />
  ),
  set: (
    <AdjustmentsHorizontalIcon className='h-5 w-5 group-hover:text-secondaryMagenta' />
  ),
}
