import { useContext } from 'react'
import AdminContext from '../../../contexts/Admin'
import { useState, useEffect } from 'react'
import axios from 'axios'
import Loading from '../../Common/Widgets/Loading'
import AddEvent from './AddEvent'
import EventCard from './EventCard'
import { getEventUrl } from '../Config/APIurls'

const Event = () => {
  const { currentParams, displayMainMessage, config, reload } =
    useContext(AdminContext)
  const [event, setEvent] = useState(null)
  const [edit, setEdit] = useState(false)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const { id } = currentParams

  useEffect(() => {
    const getEvent = async () => {
      try {
        const { data } = await axios.get(getEventUrl + id, config)
        if (!data) {
          // if the data is empty
          setError('No data found')
          setLoading(false)
          return
        }
        setEvent(data)
        setLoading(false)
      } catch (err) {
        setError(
          'Error getting the data, please refresh the page and try again'
        )
        displayMainMessage(
          {
            error: 1,
            message: err.status
              ? err.response.message
              : 'Something went wrong while loading the widgets data',
            show: true,
          },
          5000
        )
      }
    }
    if (id !== 'add') getEvent()
    setLoading(false)
  }, [id, edit, reload])

  return loading ? (
    <Loading />
  ) : id === 'add' ? (
    <AddEvent />
  ) : edit && id ? (
    <AddEvent event={event} setEdit={setEdit} />
  ) : (
    <EventCard event={event} setEdit={setEdit} />
  )
}

export default Event
