import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'
import { useContext, useEffect, useState } from 'react'
import { getBlogPostsUrl } from '../Config/APIurls'
import axios from 'axios'
import MainContext from '../../../contexts/Main'
import Loading from '../../Common/Widgets/Loading'
import BlogCard from './BlogCard'

const Blog = () => {
  const { config } = useContext(MainContext)
  const [posts, setPosts] = useState({ results: [] })
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          getBlogPostsUrl + '?page=1&limit=6&sortBy=createdAt:desc',
          config
        )
        setPosts(response.data)
        setLoading(false)
      } catch (err) {
        console.log(err)
      }
    }
    setLoading(true)
    fetchData()
  }, [])
  return (
    <section id='blog'>
      <div className='flex flex-col my-2 lg:my-8'>
        <div className='lg:mx-26 p-8'>
          <h1 className='mb-12'>
            <FormattedMessage
              id='blog.title'
              defaultMessage='Explore the Blog'
            />
          </h1>
          <p>
            <FormattedMessage id='blog.subtitle' defaultMessage='' />
          </p>
        </div>
        <div className='w-full flex p-8 space-x-4 overflow-x-scroll '>
          {loading ? (
            <Loading />
          ) : (
            posts.results.map((post) => <BlogCard key={post.id} post={post} />)
          )}
        </div>
        <Link
          className='button primary w-full sm:w-80 mx-auto h-14 flex justify-center items-center py-4 my-8 uppercase'
          to='/blog'
        >
          <FormattedMessage id='app.blogCTA' defaultMessage='Check all posts' />
        </Link>
      </div>
    </section>
  )
}

export default Blog
