import moment from 'moment'
import { Link } from 'react-router-dom'

const BlogCard = ({ post }) => {
  return (
    <Link
      className='relative w-96 h-60 flex flex-col flex-shrink-0 p-0 overflow-hidden border group transition-all ease-in-out duration-100 border-black'
      to={'blog/' + post.id}
    >
      <img
        src={post.image.url}
        alt={post.title}
        className={`w-full h-36 aspect-video object-cover object-${post.image.focus}`}
      />
      <div className='w-full h-24 flex flex-col flex-grow justify-between p-0 whitespace-pre-line'>
        <div className='w-full font-bold text-sm bg-white p-2 text-primaryBlue'>
          {post.title}
        </div>
        <div className='w-full flex justify-between font-bold text-xs p-2'>
          <span className='text-secondaryMagenta'>By {post.author.name}</span>
          <span>{moment(post.createdAt).format('MMMM Do, YYYY')}</span>
        </div>
      </div>
      <span className='object-center object-bottom object-top object-left object-left-bottom object-left-top object-right object-right-bottom object-right-top'></span>
    </Link>
  )
}

export default BlogCard
